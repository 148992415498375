<template>
  <div>
    <button class="btn btn-secondary text-uppercase rounded-pill" @click="$emit('click')">
      <slot></slot>
              </button>
  </div>
</template>
<style lang="scss">
</style>
<script>

export default {
  name: 'secondaryButton',
}
</script>