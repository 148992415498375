<template>
  <div @click="resetExpandedKeys" class="px-4">
    <template v-if="forRoles">
      <template v-if="this.$helper.hasAnyRole(forRoles)">
        <router-link :to="to">
          <div class="d-block w-100 py-2">
            <slot></slot>
            <div v-if="notificationCount > 0" class="notification-bullet">{{ notificationCount }}</div>
          </div>
        </router-link>
      </template>
    </template>
    <template v-else>
      <router-link :to="to">
        <div class="d-block w-100 py-2">
          <slot></slot>
          <div v-if="notificationCount > 0" class="notification-bullet">{{ notificationCount }}</div>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: "SideMenuLink",
  props: {
    to: {
      type: String,
      default: 'user',
    },
    notificationCount: {
      type: Number,
      default: null
    },
    forRoles: {
      type: Array,
      default: null
    }
  },
  methods:{
    resetExpandedKeys() {
      localStorage.setItem('expandedKeys', [])
    },
  }
}
</script>