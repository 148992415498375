<template>
  <PageLayout>
    <template #back-navigation><span></span></template>
    <template #content-header>
      {{ $t('Assessments') }}
    </template>
    <template #content-header-suffix>{{ $t('planned') }}</template>
    <template #content>
      <div class="d-flex flex-column flex-grow-1" v-if="user">
        <div class="container pt-0">
          <div class="p-card container table-container pt-0 pb-0">
            <div class="p-card-body" style="padding:0">
              <DataTable
                  :value="assessments"
                  data-key="id"
                  :rowHover="true"
                  :filters.sync="filters"
                  :loading="loading"
                  v-if="!loading"
                  :expandedRows.sync="expandedRows"
                  :paginator="true"
                  :rows="8"
                  dataKey="id"
              >
                <template #empty>{{ $t('noAssessmentsFound') }}.</template>
                <template #header>
                  <div class="table-header d-flex align-items-center">
            <span class="p-input-icon-left flex-grow-1">
              <IconInput
                  v-model="filters['global'].value" :placeholder="$t('Search')"
                  type="text"
                  icon="pi pi-search"
                  :clear="true"
              />
            </span>
                  </div>
                </template>
                <Column field="name" :header="$t('Assessee')" sortable>
                  <template #body="slotProps">
                    <div class="title-wrapper">
                      <div class="assessment-title"><small>{{ slotProps.data.title }}</small></div>
                    </div>
                    <div @click="assesseeClicked(slotProps.data)" class="user-wrapper">
                      <UserImage :src="slotProps.data.assessee_image" class="user-img-list"></UserImage>
                      <div class="user-name">{{ slotProps.data.name }}</div>


                    </div>

                    <div class="date-wrapper">
                      <div class="date"><small>{{ $moment(slotProps.data.due_date).format('DD.MM.YYYY') }}</small></div>
                    </div>

                  </template>
                </Column>
                <Column field="assessor" :header="$t('Assessor')" sortable>
                  <template #body="slotProps">
                    <div class="user-wrapper" @click="assesseeClicked(slotProps.data)">
                      <UserImage :src="slotProps.data.assessor_image" class="user-img-list"></UserImage>
                      <div class="user-name">{{ slotProps.data.assessor }}</div>
                    </div>
                  </template>
                </Column>
                <Column field="public_progress" :header="$t('progress %')"
                        :headerStyle="{'width':'25em'}">
                  <template #body="slotProps">
                    <div class="w-100 px-4"
                        v-bind:class="{'employee':!$helper.hasAnyRole($roles.EXECUTIVE), 'executive':$helper.hasAnyRole($roles.EXECUTIVE)}">
                      <div class="progress-wrapper" v-bind:class="{'active':slotProps.data.self_progress<100}">
                        <small class="self-assessment-progress-label mr-3">{{ $t('selfImageShort') }}</small>
                        <ProgressBar class="self" :value="JSON.stringify(Math.round(slotProps.data.self_progress))"
                                     :showValue="true"/>
                      </div>
                      <div class="progress-wrapper"
                           v-if="$helper.hasAnyRole($roles.EXECUTIVE) && user.id!=slotProps.data.assessee_id"
                           v-bind:class="{'active':slotProps.data.public_progress<100}">
                        <small class="public-assessment-progress-label mr-3">{{ $t('publicImageShort') }}</small>
                        <ProgressBar class="public" :value="JSON.stringify(Math.round(slotProps.data.public_progress))"
                                     :showValue="true"></ProgressBar>
                      </div>
                      <div class="progress-wrapper"
                           v-if="$helper.hasAnyRole($roles.EXECUTIVE) && slotProps.data.self_progress>=100 && slotProps.data.public_progress >=100"
                           v-bind:class="{'active':slotProps.data.common_progress<100}">
                        <small class="common-assessment-progress-label mr-3">{{ $t('commonImageShort') }}</small>
                        <ProgressBar class="common" :value="JSON.stringify(Math.round(slotProps.data.common_progress))"
                                     :showValue="true"/>
                      </div>
                    </div>


                  </template>
                </Column>
                <Column
                    :headerStyle="{'width': '10em'}"
                    :header="$t('publicImage') + ' ' + $t('selfImage')"
                    bodyStyle="text-align: center; overflow: visible"
                    v-if="$helper.hasAnyRole($roles.EXECUTIVE)"
                >
                  <template #body="slotProps">
                    <div class="text-center">
                      <template v-if="user.id!=slotProps.data.assessee_id">
                        <template v-if="slotProps.data.public_progress>=100">
                          <span class="font-weight-bold text-success text-uppercase">{{ $t('rated') }}</span>
                          <span class="edit-link d-block border-top pt-1 mt-1" style=""
                                @click="publicAssessmentClicked(slotProps.data)">
                        {{ $t('edit') }}
                          </span>
                        </template>
                        <template v-else>
                          <button
                              class="btn btn-primary rounded-pill rate-button "
                              style="min-width:100px"
                              @click="publicAssessmentClicked(slotProps.data)"
                          >{{ $t('rate') }}
                          </button>
                        </template>
                      </template>
                      <template v-else>
                        <button
                            class="btn btn-primary rounded-pill rate-button"
                            v-bind:class="{'disabled':slotProps.data.self_progress>=100}"
                            @click="selfAssessmentClicked(slotProps.data)"
                        >{{ $t('rate') }}
                        </button>
                      </template>
                    </div>
                  </template>
                </Column>
                <Column
                    :header="$t('selfImage')"
                    bodyStyle="text-align: center; overflow: visible"
                    v-if="!$helper.hasAnyRole($roles.EXECUTIVE)"
                >
                  <template #body="slotProps">
                    <div class="text-center">
                      <template v-if="slotProps.data.self_progress>=100">
                        <span class="font-weight-bold text-success text-uppercase">{{ $t('rated') }}</span>
                        <span class="edit-link d-block border-top pt-1 mt-1" style=""
                              @click="selfAssessmentClicked(slotProps.data)">
                        {{ $t('edit') }}
                      </span>
                      </template>
                      <template v-else>
                        <button
                            class="btn btn-primary rounded-pill rate-button"
                            @click="selfAssessmentClicked(slotProps.data)"
                        >{{ $t('rate') }}
                        </button>
                      </template>
                    </div>
                  </template>
                </Column>
                <Column
                    :headerStyle="{'width': '10em'}"
                    :header="$t('assessment')"
                    bodyStyle="text-align: center; overflow: visible"
                    v-if="$helper.hasAnyRole($roles.EXECUTIVE)"
                >
                  <template #body="slotProps">
                    <div class="start-assessment-wrapper">
                      <button v-if="user.id!=slotProps.data.assessee_id"
                              class="start-assessment-button"
                              v-bind:class="{ 'disabled': slotProps.data.public_progress<100||slotProps.data.self_progress<100}"
                              :disabled="slotProps.data.public_progress<100||slotProps.data.self_progress<100"
                              @click="startAssessmentClicked(slotProps.data.id, slotProps.data.assessee_id)"
                      ><i class="pi pi-chevron-right"></i>
                      </button>
                    </div>

                  </template>
                </Column>
                <template #expansion="slotProps">
                  <div class="user-details my-2">
                    <div>
                      <div class="row">
                        <div class="col-sm-6 pb-0 pt-0">
                          {{ $t('firstName') }}:
                          <b>{{ slotProps.data.first_name }}</b>
                        </div>
                        <div class="col-sm-6 pb-0 pt-0">
                          {{ $t('lastName') }}:
                          <b>{{ slotProps.data.last_name }}</b>
                        </div>
                        <div class="col-sm-6 pb-0 pt-0">
                          {{ $t('email') }}:
                          <b>{{ slotProps.data.email }}</b>
                        </div>
                        <div class="col-sm-6 pb-0 pt-0">
                          {{ $t('dateOfBirth') }}:
                          <b>{{ $moment(slotProps.data.birth_date).format('DD.MM.YYYY') }}</b>
                        </div>
                        <div class="col-sm-6 pb-0 pt-0">
                          {{ $t('employeeNumber') }}:
                          <b>{{ slotProps.data.employee_number }}</b>
                        </div>
                        <div class="col-sm-6 pb-0 pt-0" v-if="slotProps.data.job_position">
                          {{ $t('currentJob') }}:
                          <b>{{ slotProps.data.job_position.title }}</b>
                        </div>
                        <div class="col-sm-6 pb-0 pt-0" v-if="slotProps.data.future_job_position">
                          {{ $t('Future job position') }}:
                          <b>{{ slotProps.data.future_job_position.title }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </DataTable>

            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content-footer>
      <div class="d-flex justify-center">
        <router-link v-if="!loading" to="completed-assessment-list">{{ $t('toCompletedAssessments') }}</router-link>
      </div>

    </template>
  </PageLayout>


</template>

<script>
import UserImage from "@/components/UserImage";
import PageLayout from "../backend/PageLayout.vue";
import {FilterMatchMode} from "primevue/api";
import IconInput from "../../components/IconInput.vue";

export default {
  name: 'assessment-list',
  data() {
    return {
      user: this.$store.getters.user,
      assessments: null,
      filters: {
        'global': {value: this.$route.params.searchString, matchMode: FilterMatchMode.CONTAINS}
      },
      expandedRows: [],
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.getMyAssessments();
  },
  methods: {
    getMyAssessments() {
      this.$store
          .dispatch('getMyAssessments')
          .then(res => {
            this.assessments = res.data;
            this.loading = false;
          })
    },
    assesseeClicked(assessee) {
      if (!this.expandedRows.includes(assessee)) {
        this.expandedRows = [];
        this.expandedRows.push(assessee);
      } else {
        this.expandedRows = [];
      }
    },
    publicAssessmentClicked(assessment) {
      this.$router.push({
          name: 'public-assessment-home',
          params: {assessmentId: assessment.id},
        });
    },
    selfAssessmentClicked(assessment) {
      this.$router.push({
          name: 'self-assessment-home',
          params: {assessmentId: assessment.id},
        });
    },
    startAssessmentClicked(assessmentId, assesseeId) {
      this.$router.push({
        name: 'assessment-intro',
        params: {assessmentId, assesseeId},
      });
    },
  },
  components: {
    IconInput,
    PageLayout,
    UserImage,
  },
};
</script>

<style lang="scss">
.edit-link:hover {
  color: $primary;
  text-decoration: underline;
}

body .assessment-list {
  .p-progressbar {
    background-color: $darker !important;
    height: 10px !important;
    margin: 5px;
    width: 100%;

    .p-progressbar-label {
      line-height: 10px;
      font-size: 0.6em;
      color: white;
    }
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: transparent !important;
  }
}

.assessment-list {
  height: 80vh;
}

.list-footer {
  height: 7vh;
}

.title-wrapper {
  text-align: right;
}

.date-wrapper {
  text-align: right;
}

.user-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;


}

.progress-wrapper {
  display: flex;
  align-items: center;
  justify-content: left;

  .p-progressbar {
    width: 100%;
  }
}

.employee {
  .self.p-progressbar .p-progressbar-value {
    background: $light-gray !important;
  }

  .active {
    .self.p-progressbar .p-progressbar-value {
      background: $primary !important;
    }
  }

  .public.p-progressbar .p-progressbar-value {
    background: $light-gray !important;
  }

}

.executive {
  .self.p-progressbar .p-progressbar-value {
    background: $light-gray !important;
  }

  .public.p-progressbar .p-progressbar-value {
    background: $light-gray !important;
  }

  .active {
    .common.p-progressbar .p-progressbar-value {
      background: $primary !important;
    }

    .public.p-progressbar .p-progressbar-value {
      background: $primary !important;
    }
  }


}

.user-img-list {
  display: inline;
  margin-right: 1em;

  img {
    width: 50px !important;
    height: 50px !important;
  }
}

.user-line {
  background: $dark;
}

.public-assessment .btn-primary.rate-button {
  min-width: 0;
}

.start-assessment-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .start-assessment-button {
    color: $primary;
    background: none;
    border: none;
    line-height: 0;

    .pi {
      font-size: 2.5em;
    }
  }
}

.start-assessment-button.disabled {
  color: $light-gray;
  background: none;
  border: none;

  .pi {
    font-size: 2.5em;
  }
}

.table-container {
  background: none !important;
  box-shadow: none !important;
}

.public-assessment-progress-label {
  font-weight: bold;
  color: white;
  float: left;

}

.common-assessment-progress-label {
  font-weight: bold !important;
  color: white;
  text-align: center;
  text-transform: uppercase;
}

.self-assessment-progress-label {
  font-weight: bold;
  color: white;
  float: left;
}

.loading-info {
  color: red;
  font-weight: bold;
}

.name-header {
  display: flex;
  justify-content: center;
  align-content: center;

  span {
    position: absolute;
    margin-top: 1em;
  }

}

</style>
