<template>
  <PageLayout>
    <template #back-navigation><span></span></template>
    <template #content>
       <div class="messages page-content d-flex flex-column flex-grow-1" v-if="messages">
     <h5 class="logo text-white text-center text-uppercase font-weight-bold align-middle page-title-flex">{{$t('messages')}}</h5>
    <div class="container content text-center pl-4 pr-4">



      <div v-for="message in messages" :key="message.id" class="row">
        <router-link :to="'/messages/' + message.id" class="mt-3 message">
        <div v-if="!message.read_date" class="notification-bullet"></div>
          <Card>

            <template slot="title">

              {{ message.title }}
            </template>
            <template slot="content">
              <div class="message-content">
                {{ message.content }}
              </div>
            </template>
            <template slot="footer">
              <small>{{ message.date }}</small>
            </template>
          </Card>
        </router-link>
      </div>
    </div>
  </div>
    </template>
  </PageLayout>


</template>
<style scoped>
.p-card-body{
  position: relative;
}
  .message{
    position: relative;
  }
  .message:hover{
    text-decoration: none;
  }
  .notification-bullet{
    right:-4px;
  }
</style>
<script>
import PageLayout from "../backend/PageLayout.vue";

export default {
  name: 'messages',
  components: {PageLayout},
  data() {
    return {
      messages: this.$store.getters.messages,
    };
  },
  created(){
     this.$store.watch(
      state => state.status,
      newValue => {
        if (newValue === 'message-success') {
          this.messages = this.$store.getters.messages.filter(message => {
            if (!message.read) {
              return message;
            }
          });
        }
      },
    );
  }
};
</script>
