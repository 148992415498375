<template>
  <PageLayout>
    <template #back-navigation>
      <BackLink to="competences" :label="$t('toSummary')"/>
    </template>
    <template #content-header>{{ $t('thankYou') }}</template>
    <template #content-header-suffix>{{ user.first_name }}</template>
    <template #content>
       <template v-if="competences">
    <div v-if="user" class="container">
      <div>{{ $t('selfassessment.completedMsg') }}.</div>
      <div>
        <div class="mt-4">
            <PickerInput id="selected_category"
                         :title="$t('Select additional competence')"
                         :selected="selectedCompetence.title"
                         :placeholder="$t('Select Competence')"
                         v-model="selectedCompetence.id"
                         ref="picker"
                          @dialogOpened="()=>{$router.push({name: 'competences', params:{showAddCompetence:true}})}"
            >
            </PickerInput>
          </div>
      </div>
       <Dialog v-if="selectedCompetence" header="Kompetenz hinzugefügt" :visible.sync="displayModal"
                  :modal="true"
                  v-on:hide="closeModal">
            <span v-html="$t('competences.successfullyAddedMsg', { title: selectedCompetence.title })"></span>
            <template #footer>
              <button class="btn btn-primary btn-block text-uppercase rounded-pill"
                      @click="competenceClicked(selectedCompetence)">{{ $t('rate') }}
              </button>
            </template>
          </Dialog>
    </div>
  </template>
    </template>
    <template #content-footer>
      <PrimaryButton
              @click="endClicked"
          >{{ $t('finish') }}
          </PrimaryButton>
    </template>
  </PageLayout>



</template>
<script>
import PickerInput from '@/components/PickerInput.vue';
import PageLayout from "../backend/PageLayout.vue";
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'home',
  components: {
    BackLink,
    PageLayout,
    PickerInput,
  },
  data() {
    return {
      user: null,
       selectedCompetence: {
        id: null,
        title: null
      },
      competences: [],
      displayModal: false,
    };
  },
  created() {
    this.$store.dispatch('getUser', {}).then((res) => {
      this.user = res.data;
      this.getCompetences();
    });
  },
  methods: {
     getCompetences() {
      this.$http.get('/remaining-competences', {}).then((res) => {
        this.competences = res.data;
      })
    },
    endClicked() {
      this.$router.push({name: 'home'});
      console.log('SelectedCompetence', this.selectedCompetence);
    },
    changeCompetence(competence) {
      if (competence) {
        this.selectedCompetence = competence;
      }
    },
    addCompetenceClicked(competence) {
      this.selectedCompetence = competence
      this.$store.dispatch('addCompetence', competence.id).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Kompetenz hinzugefügt',
          detail: 'Kompetenz erfolgreich hinzugefügt',
          life: 3000,
        });
        this.$refs.picker.closeDialog()
        this.openModal();
      });
    },
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.selectedCompetence = {};
      this.competences = [];
      this.displayModal = false;
      this.getCompetences();
    },
    competenceClicked(competence) {
      this.$router.push({
        name: 'competence-detail',
        params: {'competenceId': competence.id},
      });
    },
  },
};
</script>
