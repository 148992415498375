import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import vueRouter from '../router'
import helper from '../helper'
//import i18n from '@/i18n.js';

Vue.use(Vuex);
let msgInterval;

axios.defaults.baseURL = '/api/v1';

axios.interceptors.request.use(
    (config) => {

        let token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },

    (error) => {
        console.log("Request Error")
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(response => {
    if (!localStorage.getItem('token') && vueRouter.currentRoute.name !== 'login') {
        localStorage.clear()
        vueRouter.push('/login')
        return;
    }

    return response;
}, error => {
    if (error.response) {
        error = error.response;
    }
    if (error.status === 403 || error.status === 401) {
        localStorage.clear()

        if (vueRouter.currentRoute.name !== 'login') {
            helper.showErrorToast(error)
            vueRouter.push('/login')
        }
        return false
    }
    return Promise.reject(error);
});


function getItem(key) {
    if (localStorage.getItem(key) !== 'undefined') {
        return JSON.parse(localStorage.getItem(key))
    }
    return {}
}

function getItems(key) {
    if (localStorage.getItem(key) !== 'undefined') {
        return JSON.parse(localStorage.getItem(key))
    }
    return []
}

export default new Vuex.Store({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        userRoles: getItems('userRoles') || [],
        roles: getItems('roles') || [],
        user: getItem('user') || {},
        assessors: getItem('assessors') || {},
        assessees: getItem('assessees') || {},
        executives: getItem('executives') || {},
        hrManagers: getItem('hrManagers') || {},
        employee: getItem('employee') || {},
        messages: getItems('messages') || [] || [],
        categories: getItems('categories') || [],
        jobRoles: getItems('jobRoles') || [],
        compareJobRole: getItems('compareJobRole') || [],
        jobPositions: getItems('jobPositions') || [],
        competences: getItems('competences') || [],
        tags: getItems('tags') || [],
        functionalGroups: getItems('functionalGroups') || [],
        functionalGroupsWithFreeJobPositions: getItems('functionalGroupsWithFreeJobPositions') || [],
        hrGroups: getItems('hrGroups') || [],
        jobRoleGroups: getItems('jobRoleGroups') || [],
        assessments: getItems('assessments') || [],
        users: getItems('users') || [],
        settings: getItems('settings') || []
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state, result) {
            state.status = 'success';
            state.token = result.token;
        },
        getUser_request(state) {
            state.status = 'loading';
        },
        getUser_success(state, user) {
            state.status = 'success';
            state.user = user;
        },
        getUser_error(state) {
            state.status = 'getUser error';
        },
        getUsers_request(state) {
            state.status = 'loading';
        },
        getUsers_success(state, users) {
            state.status = 'success';
            state.users = users;
        },
        getUsers_error(state) {
            state.status = 'getUser error';
        },
        getAssessors_request(state) {
            state.status = 'loading';
        },
        getAssessors_success(state, assessors) {
            state.status = 'success';
            state.assessors = assessors;
        },
        getAssessors_error(state) {
            state.status = 'getAssessors error';
        },
        getAssessees_request(state) {
            state.status = 'loading';
        },
        getAssessees_success(state, assessees) {
            state.status = 'success';
            state.assessees = assessees;
        },
        getAssessees_error(state) {
            state.status = 'getAssessees error';
        },
        getExecutives_request(state) {
            state.status = 'loading';
        },
        getExecutives_success(state, executives) {
            state.status = 'success';
            state.executives = executives;
        },
        getExecutives_error(state) {
            state.status = 'getExecutives error';
        },
        getHrManagers_request(state) {
            state.status = 'loading';
        },
        getHrManagers_success(state, hrManagers) {
            state.status = 'success';
            state.hrManagers = hrManagers;
        },
        getHrManagers_error(state) {
            state.status = 'gethrManagers error';
        },
        myTeam_request(state) {
            state.status = 'loading';
        },
        myTeam_success(state) {
            state.status = 'success';
        },
        myTeam_error(state) {
            state.status = 'getEmployee error';
        },
        myAssessments_request(state) {
            state.status = 'loading';
        },
        myAssessments_success(state) {
            state.status = 'success';
        },
        myAssessments_error(state) {
            state.status = 'getEmployee error';
        },
        myCompletedAssessments_request(state) {
            state.status = 'loading';
        },
        myCompletedAssessments_success(state) {
            state.status = 'success';
        },
        myCompletedAssessments_error(state) {
            state.status = 'getEmployee error';
        },
        getEmployee_request(state) {
            state.status = 'loading';
        },
        getEmployee_success(state, employee) {
            state.status = 'success';
            state.employee = employee;
        },
        getEmployee_error(state) {
            state.status = 'getEmployee error';
        },
        getAssessment_request(state) {
            state.status = 'loading';
        },
        getAssessment_success(state, employee) {
            state.status = 'success';
            state.employee = employee;
        },
        getAssessment_error(state) {
            state.status = 'getEmployee error';
        },
        getMessages_update(state) {
            state.status = 'message-update';
        },
        getMessages_success(state, messages) {
            state.status = 'message-success';
            state.messages = messages;
        },
        getMessages_error(state) {
            state.status = 'message-error';
        },
        postUser_request(state) {
            state.status = 'loading';
        },
        postUser_success(state) {
            state.status = 'success';
        },
        postUser_error(state) {
            state.status = 'postUser error';
        },
        postCompetence_request(state) {
            state.status = 'loading';
        },
        postCompetence_success(state) {
            state.status = 'success';
        },
        postCompetence_error(state) {
            state.status = 'postCompetence error';
        },
        getCategories_request(state) {
            state.status = 'loading';
        },
        getCategories_success(state, categories) {
            state.status = 'success';
            state.categories = categories;
        },
        getCategories_error(state) {
            state.status = 'getCategories error';
        },
        getJobRoles_request(state) {
            state.status = 'loading';
        },
        getJobRoles_success(state, jobRoles) {
            state.status = 'success';
            state.jobRoles = jobRoles;
        },
        getJobRoles_error(state) {
            state.status = 'getJobRoles error';
        },
        getCompareJobRole_request(state) {
            state.status = 'loading';
        },
        getCompareJobRole_success(state, compareJobRole) {
            state.status = 'success';
            state.compareJobRole = compareJobRole;
        },
        getCompareJobRole_error(state) {
            state.status = 'getJobRoles error';
        },
        getRoles_request(state) {
            state.status = 'loading';
        },
        getRoles_success(state, roles) {
            state.status = 'success';
            state.roles = roles;
        },
        getRoles_error(state) {
            state.status = 'getRoles error';
        },
        getJobPositions_request(state) {
            state.status = 'loading';
        },
        getJobPositions_success(state, jobPositions) {
            state.status = 'success';
            state.jobPositions = jobPositions;
        },
        getJobPositions_error(state) {
            state.status = 'getCategories error';
        },
        getCompetences_request(state) {
            state.status = 'loading';
        },
        getCompetences_success(state, competences) {
            state.status = 'success';
            state.competences = competences;
        },
        getCompetences_error(state) {
            state.status = 'getCategories error';
        },
        getTags_request(state) {
            state.status = 'loading';
        },
        getTags_success(state, tags) {
            state.status = 'success';
            state.tags = tags;
        },
        getTags_error(state) {
            state.status = 'getCategories error';
        },
        getFunctionalGroups_request(state) {
            state.status = 'loading';
        },
        getFunctionalGroups_success(state, functionalGroups) {
            state.status = 'success';
            state.functionalGroups = functionalGroups;
        },
        getFunctionalGroups_error(state) {
            state.status = 'getFunctionalGroup error';
        },
        getFunctionalGroupsWithFreeJobPositions_request(state) {
            state.status = 'loading';
        },
        getFunctionalGroupsWithFreeJobPositions_success(state, functionalGroupsWithFreeJobPositions) {
            state.status = 'success';
            state.functionalGroupsWithFreeJobpositions = functionalGroupsWithFreeJobPositions;
        },
        getFunctionalGroupsWithFreeJobPositions_error(state) {
            state.status = 'getFunctionalGroupWithFreeJobPositions error';
        },
        getHrGroups_request(state) {
            state.status = 'loading';
        },
        getHrGroups_success(state, hrGroups) {
            state.status = 'success';
            state.hrGroups = hrGroups;
        },
        getHrGroups_error(state) {
            state.status = 'getHrGroup error';
        },
        getJobRoleGroups_request(state) {
            state.status = 'loading';
        },
        getJobRoleGroups_success(state, jobRoleGroups) {
            state.status = 'success';
            state.jobRoleGroups = jobRoleGroups;
        },
        getJobRoleGroups_error(state) {
            state.status = 'getHrGroup error';
        },
        getSettings_request(state) {
            state.status = 'loading';
        },
        getSettings_success(state, settings) {
            state.status = 'success';
            state.settings = settings;
        },
        getSettings_error(state) {
            state.status = 'getHrGroup error';
        },
        getAssessments_request(state) {
            state.status = 'loading';
        },
        getAssessments_success(state, assessments) {
            state.status = 'success';
            state.assessments = assessments;
        },
        getAssessments_error(state) {
            state.status = 'getAssessments error';
        },
        postJobCompetence_request(state) {
            state.status = 'loading';
        },
        postJobCompetence_success(state) {
            state.status = 'success';
        },
        postJobCompetence_error(state) {
            state.status = 'postUser error';
        },
        postUserCompetence_request(state) {
            state.status = 'loading';
        },
        postUserCompetence_success(state) {
            state.status = 'success';
        },
        postUserCompetence_error(state) {
            state.status = 'postUserCompetence error';
        },
        postAssessmentCompetence_request(state) {
            state.status = 'loading';
        },
        postAssessmentCompetence_success(state) {
            state.status = 'success';
        },
        postAssessmentCompetence_error(state) {
            state.status = 'postUserCompetence error';
        },
        auth_error(state) {
            state.status = 'error';
        },
        logout(state) {
            state.status = '';
            state.token = '';
            state.roles = [];
        },
        setLoading(state, status) {
            state.status = status;
        },
    },
    actions: {
        async login({commit}, data) {
            await axios.get('/csrf-cookie');
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios
                    .post('/login', {
                        email: data.user.email,
                        password: data.user.password,
                        remember: data.remember
                    })
                    .then(async (resp) => {
                        const token = resp.data.message.token;
                        localStorage.setItem('token', token);

                        commit('auth_success', {
                            token
                        });
                        resolve(resp.data);
                    })
                    .catch((err) => {
                        commit('auth_error');
                        localStorage.removeItem('token');
                        reject(err);
                    });
            });
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout');
                axios
                    .post('logout')
                    .then(() => {
                        localStorage.clear();
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        clearStorage() {
            return new Promise((resolve) => {
                localStorage.clear();
                resolve();
            });
        },
        getUsers({commit}) {
            commit('getUsers_request');
            return new Promise((resolve, reject) => {
                axios
                    .get('/users')
                    .then((resp) => {
                        const users = resp.data;
                        localStorage.setItem('users', JSON.stringify(users));
                        commit('getUsers_success', users);
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('getUsers_error');
                        reject(err);
                    });
            });
        },
        getUser({commit}, {id = null}) {
            commit('getUser_request');
            return new Promise((resolve, reject) => {
                let userId,
                    url = null;
                if (id) {
                    userId = id;
                    url = '/users/' + userId;
                } else {
                    //userId = localStorage.getItem('token');
                    url = 'users/me';
                }
                axios
                    .get(url)
                    .then((resp) => {
                        const user = resp.data;
                        localStorage.setItem('user', JSON.stringify(user));
                        //i18n.locale = user.language;
                        commit('getUser_success', user);
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('getUser_error');
                        localStorage.clear()
                        reject(err);
                    });
            });
        },
        postUser({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('postUser_request');

                axios
                    .post('/users/me', user)
                    .then((resp) => {
                        localStorage.setItem('user', JSON.stringify(user));
                        commit('postUser_success');
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('user error', err);
                        commit('postUser_error');
                        reject(err);
                    });
            });
        },
        addCompetence({commit}, competenceId) {
            return new Promise((resolve, reject) => {
                commit('postCompetence_request');

                axios
                    .post('/user-competence', {id: competenceId})
                    .then((resp) => {
                        localStorage.setItem('user', JSON.stringify(resp.data));
                        commit('postCompetence_success');
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('post competence error', err);

                        commit('postCompetence_error');
                        reject(err);
                    });
            });
        },
        getMyTeam({commit}) {
            commit('myTeam_request');
            return new Promise((resolve, reject) => {
                axios
                    .get('my-team')
                    .then((resp) => {
                        commit('myTeam_success');
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('myTeam_error');
                        reject(err);
                    });
            });
        },
        getMyAssessments({commit}) {
            commit('myAssessments_request');
            return new Promise((resolve) => {
                axios
                    .get('my-assessments')
                    .then((resp) => {
                        commit('myAssessments_success');
                        resolve(resp);
                    })
            })
        },
        getMyCompletedAssessments({commit}) {
            commit('myCompletedAssessments_request');
            return new Promise((resolve, reject) => {
                axios
                    .get('my-completed-assessments')
                    .then((resp) => {
                        commit('myCompletedAssessments_success');
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('myCompletedAssessments_error');
                        reject(err);
                    });
            });
        },
        getEmployee({commit}, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/users/' + id)
                    .then((resp) => {
                        const employee = resp.data;
                        localStorage.setItem('employee', JSON.stringify(employee));
                        commit('getEmployee_success', employee);
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('getUser_error');
                        reject(err);
                    });
            });
        },
        getAssessment({commit}, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/assessments/' + id)
                    .then((resp) => {
                        const assessment = resp.data;
                        localStorage.setItem('assessment', JSON.stringify(assessment));
                        commit('getAssessment_success', assessment);
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('getAssessment_error');
                        reject(err);
                    });
            });
        },
        postJobCompetence({commit}, {user, competenceId, selfClaim = null, selfAbility = null, selfCommon = null}) {
            return new Promise((resolve, reject) => {
                commit('postJobCompetence_request');
                const data = {
                    competenceId: competenceId,
                };
                if (selfClaim) {
                    data.selfClaim = selfClaim;
                }
                if (selfAbility) {
                    data.selfAbility = selfAbility;
                }
                if (selfCommon) {
                    data.selfCommon = selfCommon;
                }
                axios({
                    url: '/job-competences/' + user.jobPosition,
                    data: {
                        competenceId: competenceId,
                        selfClaim,
                        selfAbility,
                        selfCommon,
                    },
                    method: 'POST',
                })
                    .then((resp) => {
                        commit('postJobCompetence_success');
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('postJobCompetence_error');
                        reject(err);
                    });
            });
        },
        postUserCompetence(
            {commit},
            data,
        ) {
            return new Promise((resolve, reject) => {
                commit('postUserCompetence_request');
                axios
                    .post('/user-competences', data)
                    .then((resp) => {
                        this.dispatch('getUser', {});
                        commit('postUserCompetence_success');
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('postUserCompetence_error');
                        reject(err);
                    });
            });
        },
        postAssessmentCompetence(
            {commit},
            data,
        ) {
            return new Promise((resolve, reject) => {
                commit('postAssessmentCompetence_request');
                axios
                    .put('/assessment-competence/', data)
                    .then((resp) => {
                        //this.dispatch('getAssessment', {});
                        commit('postAssessmentCompetence_success');
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('postAssessmentCompetence_error');
                        reject(err);
                    });
            });
        },
        getMessages({commit}) {
            console.log(commit);
            if (!msgInterval) {
                msgInterval = setInterval(() => {
                    return new Promise((resolve, reject) => {
                        axios
                            .get('/messages')
                            .then((resp) => {
                                const messages = resp.data;
                                commit('getMessages_update', messages);
                                if (localStorage.getItem('messages') !== JSON.stringify(messages)) {
                                    console.log('Setting msgs');
                                    localStorage.setItem('messages', JSON.stringify(messages));
                                    console.log('Setting update');
                                    commit('getMessages_success', messages);
                                    // console.log('got messages ', messages);
                                    resolve(resp);
                                }
                            })
                            .catch((err) => {
                                commit('getMessages_error');
                                reject(err);
                            });
                    });
                }, 15000);
            }
        },
        getCategories({commit}) {
            return new Promise((resolve, reject) => {
                commit('getCategories_request');

                axios
                    .get('/competence-categories')
                    .then((resp) => {
                        localStorage.setItem('categories', JSON.stringify(resp.data));
                        commit('getCategories_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('post competence error', err);
                        localStorage.clear()
                        vueRouter.push('/login')
                        commit('getCategories_error');
                        reject(err);
                    });
            });
        },
        getJobRoles({commit}) {
            return new Promise((resolve, reject) => {
                commit('getJobRoles_request');

                axios
                    .get('/job-roles')
                    .then((resp) => {
                        commit('getJobRoles_success', resp.data);
                        localStorage.setItem('jobRoles', JSON.stringify(resp.data));
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get job roles error', err);
                        commit('getJobRoles_error');
                        reject(err);
                    });
            });
        },
        getCompareJobRole({commit}) {
            return new Promise((resolve, reject) => {
                commit('getCompareJobRole_request');
                let compare = JSON.parse(localStorage.getItem('compareJobRole'))
                if (compare) {
                    axios
                        .get('/job-roles/' + compare.id)
                        .then((resp) => {
                            commit('getCompareJobRole_success', resp.data);
                            localStorage.setItem('compareJobRole', JSON.stringify(resp.data));
                            resolve(resp);
                        })
                        .catch((err) => {
                            commit('getCompareJobRole_error');
                            reject(err);
                        });
                }

            });
        },
        getRoles({commit}) {
            return new Promise((resolve, reject) => {
                commit('getRoles_request');

                axios
                    .get('/roles')
                    .then((resp) => {
                        commit('getRoles_success', resp.data);
                        localStorage.setItem('roles', JSON.stringify(resp.data));
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit('getRoles_error');
                        reject(err);
                    });
            });
        },
        getJobPositions({commit}) {
            return new Promise((resolve, reject) => {
                commit('getJobPositions_request');

                axios
                    .get('/job-positions')
                    .then((resp) => {
                        commit('getJobPositions_success', resp.data);
                        console.log("positions ", resp.data)
                        localStorage.setItem('jobPositions', JSON.stringify(resp.data));
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get job positions error', err);
                        commit('getJobPositions_error');
                        reject(err);
                    });
            });
        },
        getCompetences({commit}) {
            return new Promise((resolve, reject) => {
                commit('getCompetences_request');

                axios
                    .get('/competences')
                    .then((resp) => {
                        commit('getCompetences_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get job roles error', err);
                        commit('getCompetences_error');
                        reject(err);
                    });
            });
        },
        getTags({commit}) {
            return new Promise((resolve, reject) => {
                commit('getTags_request');

                axios
                    .get('/tags')
                    .then((resp) => {
                        localStorage.setItem('tags', JSON.stringify(resp.data));
                        commit('getTags_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get tag error', err);
                        commit('getTags_error');
                        reject(err);
                    });
            });
        },
        getFunctionalGroups({commit}) {
            return new Promise((resolve, reject) => {
                commit('getFunctionalGroups_request');

                axios
                    .get('/functional-groups')
                    .then((resp) => {
                        localStorage.setItem('functionalGroups', JSON.stringify(resp.data));
                        commit('getFunctionalGroups_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get functional group error', err);
                        commit('getFunctionalGroups_error');
                        reject(err);
                    });
            });
        },
        getFunctionalGroupsWithFreeJobPositions({commit}) {
            return new Promise((resolve, reject) => {
                commit('getFunctionalGroupsWithFreeJobPositions_request');

                axios
                    .get('/functional-groups-free-job-positions')
                    .then((resp) => {
                        localStorage.setItem('functionalGroupsWithFreeJobPositions', JSON.stringify(resp.data));
                        commit('getFunctionalGroupsWithFreeJobPositions_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get functional group error', err);
                        commit('getFunctionalGroupsWithFreeJobPositions_error');
                        reject(err);
                    });
            });
        },
        getHrGroups({commit}) {
            return new Promise((resolve, reject) => {
                commit('getHrGroups_request');

                axios
                    .get('/hr-groups')
                    .then((resp) => {
                        localStorage.setItem('hrGroups', JSON.stringify(resp.data));
                        commit('getHrGroups_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get hr group error', err);
                        commit('getHrGroups_error');
                        reject(err);
                    });
            });
        },
        getJobRoleGroups({commit}) {
            return new Promise((resolve, reject) => {
                commit('getJobRoleGroups_request');

                axios
                    .get('/job-role-groups')
                    .then((resp) => {
                        localStorage.setItem('jobRoleGroups', JSON.stringify(resp.data));
                        commit('getJobRoleGroups_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get job role group error', err);
                        commit('getJobRoleGroups_error');
                        reject(err);
                    });
            });
        },
        getAssessments({commit}) {
            return new Promise((resolve, reject) => {
                commit('getAssessments_request');

                axios
                    .get('/assessments')
                    .then((resp) => {
                        localStorage.setItem('assessments', JSON.stringify(resp.data));
                        commit('getAssessments_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get assessments error', err);
                        commit('getAssessments_error');
                        reject(err);
                    });
            });
        },
        getAssessors({commit}) {
            return new Promise((resolve, reject) => {
                commit('getAssessors_request');

                axios
                    .get('/assessors')
                    .then((resp) => {
                        localStorage.setItem('assessors', JSON.stringify(resp.data));
                        commit('getAssessors_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get assessors error', err);
                        commit('getAssessors_error');
                        reject(err);
                    });
            });
        },
        getAssessees({commit}) {
            return new Promise((resolve, reject) => {
                commit('getAssessees_request');
                axios
                    .get('/assessees')
                    .then((resp) => {
                        localStorage.setItem('assessees', JSON.stringify(resp.data));
                        commit('getAssessees_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get assessors error', err);
                        commit('getAssessees_error');
                        reject(err);
                    });
            });
        },
        getExecutives({commit}) {
            return new Promise((resolve, reject) => {
                commit('getExecutives_request');

                axios
                    .get('/executives')
                    .then((resp) => {
                        localStorage.setItem('executives', JSON.stringify(resp.data));
                        commit('getExecutives_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get executives error', err);
                        commit('getExecutives_error');
                        reject(err);
                    });
            });
        },
        getHrManagers({commit}) {
            return new Promise((resolve, reject) => {
                commit('getHrManagers_request');

                axios
                    .get('/hr-managers')
                    .then((resp) => {
                        localStorage.setItem('hr-managers', JSON.stringify(resp.data));
                        commit('getHrManagers_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get executives error', err);
                        commit('getHrManagers_error');
                        reject(err);
                    });
            });
        },
        getSettings({commit}) {
            return new Promise((resolve, reject) => {
                commit('getSettings_request');

                axios
                    .get('/settings')
                    .then((resp) => {
                        localStorage.setItem('settings', JSON.stringify(resp.data));
                        commit('getSettings_success', resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        console.log('get settings error', err);
                        commit('getSettings_error');
                        reject(err);
                    });
            });
        },
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        authStatus: (state) => state.status,
        user: (state) => state.user,
        userRoles: (state) => (state.user.roles) ? state.user.roles.map((role) => role.name) : [],
        roles: (state) => state.roles,
        employee: (state) => state.employee,
        messages: (state) => state.messages,
    },
    modules: {},
});
