<template>
  <div class="icon-input" v-bind:class="{ 'animate': animate, 'fixed':animate && input }" ref="iconInput">
    <div class="icon-container" @click="showInput()">
       <i :class="icon"></i>
    </div>

    <div v-if="clear && input.length > 0">
      <div class="clear-container" @click="clearInput()">
        <i class="pi pi-times"></i>
      </div>
    </div>

    <!--<img class="image" :src="require(`@/assets/icons/${icon}.svg`)" />-->
    <div class="input-div" @click="showInput()">
      <input
        class="input"
        :type="type"
        :placeholder="placeholder"
        v-model="input"
        @input="$emit('input', $event.target.value)"
      />
      <span class="bottom"></span>
      <span class="right"></span>
      <span class="top"></span>
      <span class="left"></span>
    </div>

    <div v-if="!animate" class="background"></div>
  </div>
</template>
<script>
export default {
  name: 'IconInput',
  props: {
    icon: {
      type: String,
      default: 'user',
    },
    type: {
      type: String,
      default: 'text',
    },
    prefill:{
        type:String,
      default:''
    },
    placeholder: {
      type: String,
      default: 'placeholder',
    },
     clear: {
       type: Boolean,
        default: true,
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
   data() {
     return {
       input: this.prefill,

     }
   },
   methods: {
    showInput(){
      if(this.animate){
        this.$refs.iconInput.classList.add("hover");
      }
    },
    clearInput(){
    this.input='';
    this.$emit('input', '')
    this.$emit('clearClicked')
      console.log(  this.$refs.iconInput.classlist)
      this.$refs.iconInput.classList.remove("hover");
    }

   }
};
</script>
<style scoped lang="scss">
.p-datatable-thead{
  text-transform:uppercase;
}
.input-div {
  position: relative;
}
.background {
  background-color: $secondary;
  border-radius: 50px;
  height: 40px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.icon-container{
  position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5px;
    left: -10px;
    background:$primary;
    font-size: 2em;
    padding: 0.25em;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 2;
    i {
      font-size: 0.85em;
    }
}
.clear-container{
  position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -4px;
    right: 0px;
    background:transparent;
    font-size: 2em;
    padding: 0.25em;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 2;
  cursor: pointer;
  i {
    font-size: 0.55em;
  }
}
.icon-input {
  position: relative;
  text-align: left;
  margin-top:0.5em;
  margin-bottom: 0.5em;
  background:transparent;
  i{
      color:$text-white;
  }

}
.icon-input.animate.hover{
  input{
      width:100%;
  }
  .clear-container{
    display: flex !important;
  }
}
.icon-input.animate.fixed  .clear-container{
    display: flex !important;
}

input {
  background:$secondary;
  border: 2px solid white;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  height: 40px;
  color: $text-primary;
  text-decoration: none;
  margin-top: 0;
  border-radius: 50px;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 2;
}

.icon-input.animate input{
   width: 0%;
    transition: width 1s;
}
.icon-input.animate.fixed input {
  width: 100%;
}
input:focus {
  outline: none;
}

input::placeholder {
  color: $text-primary;
  opacity: 0.6;
}
.input-div{
  height:40px;
}
</style>
