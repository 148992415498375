<template>
  <div class="w-100">
    <div ref="invalidFields">
      <form>
        <div class="row">
          <div class="col-12 form-group" :class="{ 'form-group--error': $v.category.title.$error }">
            <label for="title">{{ $t('Title') }}</label>
            <InputText id="title" type="text" v-model="category.title"/>
          </div>
          <div class="error" v-if="!$v.category.title.required">{{ $t('Required field') }}</div>
          <div class="error" v-if="!$v.category.title.maxLength">
            {{ this.$t('Only {maxLength} Characters allowed', {maxLength: 255}) }}
          </div>
        </div>

        <div class="row">
          <div class="col-12 form-group">
            <label for="title">{{ $t('Category Color') }}</label>
            <ColorPicker class="d-block w-25" v-model="category.color"></ColorPicker>
          </div>
          <div class="col-12 form-group">
            <label for="parent_category">{{ $t('Category') }}</label>
            <PickerInput id="parent_category" @click="toggle()" @clearClick="clearSelected()"
                         :title="$t('Select parent category')"
                         :selected="selectedParentCategory"
                         :placeholder="$t('Select parent category')"
                         ref="picker">

              <TreeList :nodes="this.categories"
                        @nodeSelected="parentCategorySelected"
              ></TreeList>
            </PickerInput>
          </div>
          <div class="col-12 form-group" v-if="category.children.length > 0">
            <Accordion>
              <AccordionTab :header="category.children.length + ' ' + this.$tc('Subcategory', category.children.length)">
                <div v-for="subcategory in category.children" :key="subcategory.id">
                  <a class="category-title"
                     @click="categorySelected(subcategory)">
                   <span
                       v-if="subcategory.title"
                       class="competence-indicator"
                       :style="'background:#'+subcategory.color"
                   ></span>
                    {{ subcategory.title }}
                  </a>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
          <div class="col-12 form-group" v-if="category.competences.length > 0">
            <Accordion>
              <AccordionTab :header="category.competences.length + ' ' + this.$tc('Competence', category.competences.length)">
                <div v-for="competence in category.competences" :key="competence.id">
                  <a class="category-title"
                     @click="competenceSelected(competence)">
                    {{ competence.title }}
                  </a>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
        <portal to="footer-actions">
          <FormActions>
            <template #left>
              <DangerButton v-if="category.id && category.id !== $store.state.settings.fallback_category_id"
                            @click="confirmDelete"> {{ $t('delete') }}
              </DangerButton>
            </template>
            <PrimaryButton @click="saveCategory">{{ $t('save') }}</PrimaryButton>
          </FormActions>
        </portal>
      </form>
    </div>
    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: category.title}) }}
      </template>
      <div class="confirmation-content">
        <p>{{ $t('Do you really want to delete category {title}?', {title: category.title}) }}
          <i class="pi pi-info" v-tooltip="$t('categoryDeleteDetailInfo')"></i></p>

        <div v-if="category.competences.length >0">
          <p class="mb-0">{{ $t('The following competences will also be deleted:') }}</p>
          <div v-for="competence in category.competences"
               :key="competence.id">
            <a class="category-title" @click="competenceSelected(competence)">{{ competence.title }}</a>
          </div>
        </div>

        <div v-if="category.children.length >0">
          <p class="mb-0">{{ $t('The following subcategories will also be deleted:') }}</p>
          <div v-for="subcategory in this.flatChildren"
               :key="subcategory.id">
            <a class="category-title" @click="categorySelected(subcategory)">{{ subcategory.title }}
              <span v-if="subcategory.competences"> ({{ subcategory.competences.length }} {{
                  $tc('Competence', subcategory.competences.length)
                }})</span></a>
          </div>
        </div>

      </div>
      <template #footer>
        <InputText class="delete-confirmation w-100 mb-3" v-model="deleteConfirmation"
               :placeholder="$t('Enter Category title to confirm')"/>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDelete">
              {{ $t('Cancel') }}
            </SecondaryButton>
          </template>
          <DangerButton @click="deleteCategory"
                        v-if="deleteConfirmation === category.title">
            {{ $t('Yes, i want to delete the category') }}
          </DangerButton>
          <DisabledButton v-if="deleteConfirmation !== category.title">{{
              $t('Yes, i want to delete the category')
            }}
          </DisabledButton>

        </FormActions>
      </template>
    </Dialog>
  </div>
</template>

<style>
.form-content {
  max-height: 80vh;
  overflow: auto;
}

.color-dropdown-icon {
  width: 10px;
  height: 10px;
  float: left;
  margin-right: 1em;
  border-radius: 2px;
}

.delete-confirmation {
  color: white;
  border-bottom: 1px solid white;
}

.delete-confirmation::placeholder {
  color: white;
}
</style>

<script>
import {maxLength, required} from 'vuelidate/lib/validators';
import PickerInput from '@/components/PickerInput.vue';
import TreeList from '@/components/TreeList.vue';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
  name: 'categories',
  data() {
    return {
      selectedParentCategory: null,
      selectedColor: {
        code: '00B0F0'
      },
      category: {
        id: null,
        title: null,
        color: '00B0F0',
        parent_id: null,
        children: [],
        competences: [],
      },
      showConfirmDelete: false,
      deleteSubCategories: false,
      deleteConfirmation: "",
      flatChildren: null
    }
  },
  created() {

    if (localStorage.getItem('selectedCategory') && this.$route.params.id) {
      this.getSettings()
      let selectedCategory = JSON.parse(localStorage.getItem('selectedCategory'))
      this.category.id = selectedCategory.id
      this.category.title = selectedCategory.title
      this.category.color = selectedCategory.color
      this.selectedColor.code = selectedCategory.color
      this.category.parent_id = selectedCategory.parent_id
      this.category.children = selectedCategory.children
      this.category.competences = selectedCategory.competences
      this.getFlatChildren()
      this.$http.get('competence-categories/' + this.$route.params.id)
          .then((res) => {
            this.category = {...res.data}
            if (this.category.parent_id) {
              this.$http.get('competence-categories/' + this.category.parent_id).then(res => {
                this.selectedParentCategory = res.data.title
              })
            }
          }).catch(() => {
        this.$router.push({name: 'backend.competences.category'})
      })


    }
  },
  methods: {
    getSettings() {
      console.log("getting settings")
      this.$store.dispatch('getSettings');
    },
    saveCategory() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          this.$http.put('competence-categories/' + this.$route.params.id, this.category)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Category updated successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getCategories')
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in updating category'),
                  detail: error.message,
                  life: 3000,
                });
              });
        } else {
          this.$http.post('competence-categories', this.category)
              .then((res) => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Category added successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getCategories').then(() => {
                  this.$router.push({name: 'backend.competences.category', params: {id: res.data.id}})
                })
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in creating category'),
                  detail: error.message,
                  life: 3000,
                });
              });
        }
        console.log('pushing ', this.category)
      }
    },
    parentCategorySelected(category) {
      this.category.parent_id = category.id;
      this.selectedParentCategory = category.title;
      this.$refs.picker.closeDialog()
    },
    categorySelected(category) {
      localStorage.setItem('selectedCategory', JSON.stringify(category))
      this.$router.push({name: 'backend.competences.category', params: {id: category.id}});
    },
    competenceSelected(competence) {
      localStorage.setItem('selectedCompetence', JSON.stringify(competence))
      this.$router.push({name: 'backend.competences.competence', params: {id: competence.id}});
    },
    clearSelected() {
      this.selectedParentCategory = null
      this.category.parent_id = null
    },
    changeColor(color) {
      if (color) {
        console.log('color changed ', color)
        this.selectedColor = color
        this.category.color = color.code;
      }
    },
    confirmDelete() {

      this.showConfirmDelete = !this.showConfirmDelete
    },
    deleteCategory() {
      if (this.deleteConfirmation === this.category.title) {
        this.$http.delete('competence-categories/' + this.category.id)
            .then(() => {
              this.showConfirmDelete = false;
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Category successfully deleted'),
                life: 3000,
              });
              this.$router.push('/backend/competences/category')
            })
            .catch((error) => {
              this.showConfirmDelete = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      }
    },
    getFlatChildren() {
      this.$http.get('competence-categories/' + this.category.id + '/children-flat')
          .then((res) => {
            console.log(res.data)
            this.flatChildren = res.data
          })
    }

  },
  validations: {
    category: {
      title: {
        required,
        maxLength: maxLength(255)
      },
    }
  },
  components: {
    PickerInput,
    TreeList,
    Accordion,
    AccordionTab
  },
  computed: {
    categories() {
      return this.$store.state.categories.filter((category) => {
        return this.category.id !== category.id
      })
    }
  }
}
</script>