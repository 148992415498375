<template>
  <div class="col-lg-4" @click="$emit('click')">
    <router-link :to="to">
      <div class="dashboard-link text-center p-3">
          <h1 class="dashboard-link-title">{{ title }}</h1>
          <h2 class="dashboard-link-subtitle" v-if="subtitle">{{ subtitle }}</h2>
      </div>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.dashboard-link-title {
  font-size: 1.5rem;
}
.dashboard-link-subtitle {
  font-size: 0.75rem;
}
.dashboard-link {
  height: 100px;
  border-radius: 5px;
  background: $darker;
  border: 2px solid white;
  color: $text-primary;
}

.dashboard-link:hover {
  background: darken($primary, 5%);
}

a:hover {
  text-decoration: none !important;
}
</style>

<script>
export default {
  name: 'DashboardLink',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null
    }
  },
};
</script>