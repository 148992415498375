<template>
  <PageLayout>
    <template #content-header>

    </template>
    <template #content>
      <div class="flex-grow-1">
        <h4 class="text-white text-center">{{ competence.title }}</h4>
        <div class="pt-0 competence-title-description">= {{ competence.description }}</div>
        <p
            class="question-title text-center mb-6 mt-3"
            v-if="abilityActive"
        >{{ $t('ability') }}</p>

        <div class="Slider container mt-4 pl-5 pr-5 mt-5 mb-5 sliders w-75">
          <div class="prepend-line single"></div>
          <v-slider
              class="custom-slider public-slider-fill"
              v-model="abilityFillValue"
              min="1"
              max="8"
              steps="6"
              color="#dc8e00"
              track-color="#C3C8CC"
          ></v-slider>
          <v-slider
              class="custom-slider public-slider"
              v-model="abilityValue"
              min="0"
              max="8"
              steps="7"
              :ticks="false"
              color="transparent"
              track-color="transparent"
              thumb-size="20"
              thumb-label="always"
          >
            <template v-slot:thumb-label>
              <img class="publicassessment-tooltip-icon" src="@/assets/icons/k.svg"/>
            </template>
            <template v-slot:prepend>
              <div class="prepend-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('novice') }}</span>
              </div>
            </template>
            <template v-slot:append>
              <div class="append-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('expert') }}</span>
              </div>
            </template>
          </v-slider>
        </div>
        <div class="container" v-if="competence.competence_levels">
          <div v-if="active === 'ability'">
        <span v-if="abilityValue <= 0">
          <div class="competence-title-container">
            <span class="competence-title">{{ $t('ability') }}</span>
          </div>
        </span>
            <div class='competence-title-container' v-if="abilityValue>0">
              <span class='competence-title'>{{
                  $t('competences.levelTitles.level' + (abilityValue) + '.title')
                }}</span>
              <span
                  class='competence-title-suffix'> {{
                  $t('competences.levelTitles.level' + (abilityValue) + '.suffix')
                }}</span>
            </div>
          </div>
        </div>
        <div
            class="overflow-auto container text-container competence-level-description pt-0"
            v-if="competence.competence_levels"
        >
      <span
          v-if="abilityValue > 0 && active === 'ability'" v-html="getCompetenceLevelDescription(abilityValue)"
      ></span>
          <span
              v-if="abilityValue <= 0 && active === 'ability'"
          >{{ competence.competence_levels[0].description }}</span>
        </div>

      </div>

    </template>
    <template #content-footer>
      <button
          :class="{'disabled': abilityValue < 1}"
          class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
          @click="saveClicked"
      >{{ $t('save') }}
      </button>
    </template>
  </PageLayout>
</template>

<script>

import PageLayout from "./PageLayout.vue";

export default {
  name: 'job-roles-competence',
  data() {
    return {
      jobRole: {
        id: null,
        title: null,
      },
      competence: {
        id: null,
        title: null,
        description: null,
      },
      abilityValue: 0,
      abilityFillValue: 1,
      active: 'ability',
      abilityActive: true,
    }
  },
  created() {
    if (localStorage.getItem('selectedJobRole')) {
      let selectedJobRole = JSON.parse(localStorage.getItem('selectedJobRole'))
      this.jobRole = {...selectedJobRole}
    }
    this.$http.get('competences/' + this.$route.params.id).then((res) => {
      this.competence = res.data
      this.competence.competence_levels.unshift(
          {
            title: 'Können',
            description: this.$t('Please move the slider to set the requirement of the role for the competence'),
          },
      )
      if (localStorage.getItem('jobRoleAbility')) {
        let competenceValue = JSON.parse(localStorage.getItem('jobRoleAbility'))
        this.abilityValue = competenceValue
        this.abilityFillValue = competenceValue
      }
    })


  },
  methods: {
    saveClicked() {
      if (this.jobRole.competences.filter((competence) => {
        return competence.id === this.competence.id
      }).length > 0) {
        this.$http.put('job-role-competence', {
          'competenceId': this.competence.id,
          'jobRoleId': this.jobRole.id,
          'jobAbility': this.abilityValue,
        }).then(() => {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Competence level successfully updated'),
            life: 3000,
          });
          this.$router.push({
            name: 'backend.job-role-groups.job-role',
            params: {id: this.jobRole.id, compareJobRole: this.$route.params.compareJobRole}
          });
        })
            .catch((error) => {
              this.showConfirmRemove = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      } else {
        this.$http.post('job-role-competence', {
          'competenceId': this.competence.id,
          'jobRoleId': this.jobRole.id,
          'jobAbility': this.abilityValue,
        }).then(() => {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Competence added successfully'),
            life: 3000,
          });
          this.$router.push({
            name: 'backend.job-role-groups.job-role',
            params: {id: this.jobRole.id, compareJobRole: this.$route.params.compareJobRole}
          });
        })
            .catch((error) => {
              this.showConfirmRemove = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      }
    },
    getCompetenceLevelDescription(value) {
      console.log(this.competence.competence_levels)
      let compLevel = this.competence.competence_levels.find((competenceLevel) => {
        return competenceLevel.level === value
      });
      return compLevel.description;
    }
  },
  components: {PageLayout},
  computed: {},
  watch: {
    abilityValue: function () {
      this.abilityFillValue = this.abilityValue
    },
  }
}
</script>