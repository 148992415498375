<template>
  <div>
    <button class="btn-primary disabled rounded-pill btn text-uppercase">
      <slot></slot>
    </button>
  </div>
</template>
<style lang="scss">
</style>
<script>

export default {
  name: 'secondaryButton',
}
</script>