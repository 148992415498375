<template>
  <PageLayout>
    <template #back-navigation>
      <BackLink to="competences" :label="$t('toSummary')"/>
    </template>
    <template #content-header>
      {{ $t('selfImage') }}
    </template>
    <template #content-header-suffix>
      <span class="px-2" :style="{'border-left': '2px solid #'+currentCompetence.competence_category.color}">
      {{ currentCompetence.title }}
      </span>
    </template>
    <template #content>
      <div class="container" v-if="currentCompetence">
        <div class="pt-0 competence-title-description">= {{ currentCompetence.description }}</div>
        <p
            class="question-title text-center mb-5 mt-3 pb-5"
            v-if="abilityActive"
        >{{ $t('ability') }}</p>
        <p
            class="question-title text-center mb-5 mt-3 pb-5"
            v-if="claimActive"
        >{{ $t('desire') }}</p>

        <div class="Slider container px-0 px-sm-5 sliders">
          <div class="prepend-line double"></div>
          <v-slider
              class="custom-slider ability-slider-fill"
              v-model="abilityFillValue"
              min="1"
              max="8"
              steps="6"
              color="#dc8e00"
              track-color="#C3C8CC"
              :readonly=true
          ></v-slider>
          <v-slider
              class="custom-slider ability-slider"
              :class="{ 'disabled': !abilityActive }"
              v-model="abilityValue"
              min="0"
              max="8"
              @start="abilityDragStart"
              @end="abilityDragEnd"
              steps="7"
              :ticks="(user.show_ticks)?'always':false"
              color="transparent"
              track-color="transparent"
              thumb-size="60"
              thumb-label="always"
              thumb-color="transparent"
          >
            <template v-slot:thumb-label>
              <img v-if="abilityActive" class="selfassessment-tooltip-icon" src="@/assets/icons/k.svg"/>
              <img v-if="!abilityActive" class="selfassessment-tooltip-icon" src="@/assets/icons/k-gray.svg"/>
            </template>
            <template v-slot:prepend>
              <div class="prepend-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('novice') }}</span>
              </div>
            </template>
            <template v-slot:append>
              <div class="append-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('expert') }}</span>
              </div>
            </template>
          </v-slider>

          <v-slider
              class="custom-slider claim-slider-fill"
              v-model="claimFillValue"
              min="1"
              max="8"
              steps="6"
              color="#dc8e00"
              track-color="#C3C8CC"
              :readonly=true
          ></v-slider>
          <v-slider
              class="custom-slider claim-slider"
              :class="{ 'disabled': !claimActive }"
              v-model="claimValue"
              min="0"
              max="8"
              @start="claimDragStart"
              @end="claimDragEnd"
              steps="7"
              :ticks="(user.show_ticks)?'always':false"
              color="transparent"
              track-color="transparent"
              thumb-size="60"
              thumb-label="always"
          >
            <template v-slot:thumb-label>
              <img v-if="claimActive" class="selfassessment-tooltip-icon" src="@/assets/icons/w.svg"/>
              <img v-if="!claimActive" class="selfassessment-tooltip-icon" src="@/assets/icons/w-gray.svg"/>
            </template>
          </v-slider>
        </div>
        <div class="container mt-8" v-if="currentCompetence.competence_levels">
          <div v-if="active == 'ability'">
        <span v-if="abilityValue <= 0">
          <div class="competence-title-container">
            <span class="competence-title">{{ $t('ability') }}</span>
             <span
                 class='competence-title-suffix'> {{ $t('Rating') }}</span>
          </div>
        </span>
            <div class='competence-title-container' v-if="abilityValue>0">
              <span class='competence-title'>{{
                  $t('competences.levelTitles.level' + (abilityValue) + '.title')
                }}</span>
              <span
                  class='competence-title-suffix'> {{
                  $t('competences.levelTitles.level' + (abilityValue) + '.suffix')
                }}</span>
            </div>
          </div>
          <div v-if="active == 'claim'">
        <span v-if="claimValue <= 0">
          <div class="competence-title-container">
            <span class="competence-title">{{ $t('desire') }}</span>
             <span
                 class='competence-title-suffix'> {{ $t('Rating') }}</span>
          </div>
        </span>
            <div class='competence-title-container' v-if="claimValue>0">
              <span class='competence-title'>{{ $t('competences.levelTitles.level' + (claimValue) + '.title') }}</span>
              <span
                  class='competence-title-suffix'> {{
                  $t('competences.levelTitles.level' + (claimValue) + '.suffix')
                }}</span>
            </div>
          </div>
        </div>
        <div
            class="overflow-auto container text-container competence-level-description pt-0"
            v-if="currentCompetence.competence_levels"
        >
      <span
          v-if="abilityValue > 0 && active == 'ability'" v-html="getCompetenceLevelDescription(abilityValue)"
      ></span>
          <span
              v-if="abilityValue <= 0 && active == 'ability'"
          >{{ currentCompetence.competence_levels[0].description }}</span>
          <span
              v-if="claimValue > 0 && active == 'claim'" v-html="getCompetenceLevelDescription(claimValue)"
          ></span>
          <span
              v-if="claimValue <= 0 && active == 'claim'"
          >{{ currentCompetence.competence_levels[1].description }}</span>
        </div>
      </div>

    </template>
    <template #content-footer>
      <div>
        <SecondaryButton v-if="currentStep>0"
                         @click="backClicked"
        >{{ $t('back') }}
        </SecondaryButton>
        <RouterLink v-else :to="{name: 'competences'}">
          <SecondaryButton
          >{{ $t('toSummary') }}
          </SecondaryButton>
        </RouterLink>
      </div>
      <div class="flex-grow-1 text-center competence-progress mx-5 my-2">
        <small class="font-weight-lighter">Kompetenz {{ currentStep + 1 }} von {{
            user.competences.length
          }}</small>
        <ProgressBar :value="completedPercent">{{ completedPercent }}%</ProgressBar>
      </div>
      <div>
        <PrimaryButton
            v-if="currentStep < user.competences.length - 1"
            @click="continueClicked"
        >{{ $t('continue') }}
        </PrimaryButton>
        <button
            v-if="currentStep >= user.competences.length - 1"
            class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
            @click="continueClicked"
        >{{ $t('complete') }}
        </button>
      </div>
    </template>
  </PageLayout>


</template>
<style lang="scss">
.question-title {
  font-size: 1.25rem;
}

.v-input.custom-slider.ability-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
  width: 78.7% !important;
  left: 12.3%;
  position: absolute;
  top: 1px;
  z-index: 3;

  .v-slider--horizontal .v-slider__track-container {
    width: 100%;
  }

  .v-input__control {
    height: 2px;
  }

  .v-slider__track-background {
    width: 100% !important;
  }

  .v-slider__thumb-container {
    display: none;
  }
}

.v-input.custom-slider.claim-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
  width: 78.7% !important;
  left: 12.3%;

  position: absolute;
  top: 5px;
  z-index: 3;

  .v-slider--horizontal .v-slider__track-container {
    width: 100%;
  }

  .v-input__control {
    height: 2px;
  }

  .v-slider__track-background {
    width: 100% !important;
  }

  .v-slider__thumb-container {
    display: none;
  }
}

.prepend-indicator {
  width: 25px;
  top: 1.5em;
  left: 11.9%;
  position: absolute;

  .label {
    text-transform: uppercase;
    position: absolute;
    top: 3.5em;
    left: 0;
    color: $gray;
  }
}

.append-indicator {
  width: 25px;
  top: 1.5em;
  position: absolute;
  right: 0;

  .label {
    text-transform: uppercase;
    position: absolute;
    top: 3.5em;
    right: 0;
    color: $gray;
  }
}

.active {
  z-index: 20;
}

.inactive {
  z-index: 10;
}

.p-slider-horizontal,
.p-inputtext {
  width: 100%;
}

.p-slider-vertical {
  height: 14em;
}

.page-counter {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.custom-dot {
  width: 100%;
  height: 100%;
}

.ability-slider .vue-slider-dot {
  top: -2em !important;
}

.claim-slider .vue-slider-dot {
  top: 2em !important;
}

.custom-tooltip {
  z-index: 10 !important;
}

.custom-dot-ability {
  position: relative;

  .indicator {
    color: $gray;
    position: absolute;
    bottom: -1em;
    font-weight: bold;
    left: 0;
    right: 0;
  }
}

.sliders {
  position: relative;
  min-height: 4em;
  width: 90%;

  .custom-slider {
    position: absolute;
    width: 90% !important;
  }

  .claim-slider {
    top: 5px;
  }

  .ability-slider {
    top: 1px;
  }
}

.custom-mark-1 {
  position: absolute;
  top: 10px;
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}

.custom-mark-2 {
  position: absolute;
  top: 10px;
  transform: translateX(-100%);
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}

.description-content {
  max-height: 40vh;
  overflow: auto;
}

@media screen and (max-width: $break-x-large) {
  .v-input.custom-slider.ability-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 13%;
  }
  .v-input.custom-slider.claim-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 13%;
  }
}

@media screen and (max-width: $break-large) {

  .v-input.custom-slider.ability-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 13.6%;
  }
  .v-input.custom-slider.claim-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 13.6%;
  }

}

//Safari Browser Fix
@media not all and (min-resolution: .001dpcm) {

  .v-input.custom-slider.ability-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    top: 17px;
  }
  .v-input.custom-slider.claim-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    top: 20px;
  }
}

@media screen and (max-width: $break-medium-large) {


  .v-input.custom-slider.ability-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 14.6%;
  }
  .v-input.custom-slider.claim-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 14.6%;
  }
}

@media screen and (max-width: $break-medium) {

  .v-input.custom-slider.ability-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 18.2%;
  }
  .v-input.custom-slider.claim-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 18.2%;
  }
}


@media screen and (max-width: $break-small) {
  .v-input.custom-slider.ability-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 11%;
  }
  .v-input.custom-slider.claim-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 11%;
  }
}

@media screen and (max-width: $x-small) {
  .v-input.custom-slider.ability-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 10%;
  }
  .v-input.custom-slider.claim-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 10%;
  }

}


@media screen and (max-width: 540px) {
  .prepend-indicator {
    left: 10.7%;
  }
}
</style>
<script>
import PageLayout from "../backend/PageLayout.vue";
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'competences-process',
  components: {BackLink, PageLayout},
  data() {
    return {
      user: this.$store.getters.user,
      currentCompetence: {},
      currentStep: Number(this.$route.params.stepId),
      competenceId: null,
      data: [],
      abilityValue: 0,
      abilityFillValue: 1,
      claimValue: 0,
      claimFillValue: 1,
      active: 'ability',
      claimActive: false,
      abilityActive: true,
    };
  },
  created: function () {
    this.competenceId = this.$store.getters.user.competences[
        this.currentStep
        ].id;
    console.log(this.competenceId);
    if (this.currentStep < this.user.competences.length) {
      this.getCompetence();
    }
  },
  methods: {
    continueClicked() {
      if (this.currentStep < this.user.competences.length - 1) {
        this.$router.push({
          name: 'competence-process',
          params: {stepId: ++this.currentStep},
        });
      } else {
        this.$router.push({name: 'selfassessment-summary'});
      }
    },
    endClicked() {
      this.$router.push({name: 'home'});
    },
    getCompetence() {
      this.$store.commit('setLoading', 'loading');
      this.$http.get('/competences/' + this.competenceId).then(res => {
        this.currentCompetence = res.data;

        this.data = this.currentCompetence.competence_levels.map(res => {
          return res.level - 1;
        });
        this.data.push(this.data.length);
        this.currentCompetence.competence_levels.unshift(
            {
              title: 'Können',
              description: this.$t('selfassessment.setAbilityInfo'),
            },
            {
              title: 'Wollen',
              description: this.$t('selfassessment.setDesireInfo'),
            },
        );

        this.abilityValue = this.user.competences[this.currentStep].self_ability;
        this.claimValue = this.user.competences[this.currentStep].self_claim;
        this.$store.commit('setLoading', 'success');
      });
    },
    abilityDragStart() {
      this.claimActive = false;
      this.abilityActive = true;
      this.active = 'ability';
    },
    abilityDragEnd() {
      this.user.competences[this.currentStep].self_ability = this.abilityValue;
      console.log("SELF ", this.user.id)
      this.$store
          .dispatch('postUserCompetence',
              {id: this.user.id, competenceId: this.competenceId, selfAbility: this.abilityValue},
          )
          .then(() => {
            console.log('successfully posted ability', this.abilityValue);
            this.$store.dispatch('getUser', {id:null}).then(() => {
              this.user = this.$store.getters.user;
            })
            this.$store.commit('setLoading', 'success');

          })
          .catch(err => {
            console.log('error posting ability ', err);
          });
    },
    claimDragStart() {
      this.claimActive = true;
      this.abilityActive = false;
      this.active = 'claim';
      console.log('claim value ', this.abilityActive);
    },
    claimDragEnd() {
      this.user.competences[this.currentStep].claim_ability = this.claimValue;
      this.$store
          .dispatch('postUserCompetence',
              {id: this.user.id, competenceId: this.competenceId, selfClaim: this.claimValue}
          )
          .then(() => {
            console.log('successfully posted claim');
          })
          .catch(err => {
            console.log('error posting claim ', err);
          });
    },
    abilityChanged() {
      console.log('ability changed ');
    },
    getCompetenceLevelDescription(value) {
      let compLevel = this.currentCompetence.competence_levels.find((competenceLevel) => {
        return competenceLevel.level == value
      });
      return compLevel.description;
    },
    backClicked() {
      let stepId = --this.currentStep
      this.$router.push({
        name: 'competence-process',
        params: {stepId},
      });
    },
  },
  computed: {
    completedPercent() {
      if (this.user.competences.length > 0) {
        return Math.round(this.user.competences.filter((competence) => competence['self_ability'] > 0).length / this.user.competences.length * 100);
      }
      return null;
    },
  },
  watch: {
    abilityValue: function () {
      this.abilityFillValue = this.abilityValue
    },
    claimValue: function () {
      this.claimFillValue = this.claimValue
    },
  }
};
</script>
