<template>
  <PageLayout>
    <template #back-navigation><BackLink to="assessment-list" :label="$t('plannedAssessments')"></BackLink></template>
    <template #content-header>{{ $t('assessment') }}</template>
    <template #content>
      <div class="d-flex flex-column flex-grow-1">
        <div
            class="mt-4 container"
        >{{ $t('commonAssessment.welcomeMsg') }}.
        </div>
      </div>
    </template>
    <template #content-footer>
      <PrimaryButton @click="startAssessmentClicked">{{ $t('startAssessment') }}</PrimaryButton>
    </template>
  </PageLayout>

</template>

<script>

import PageLayout from "../backend/PageLayout.vue";
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'assessment-intro',
  components: {BackLink, PageLayout},
  data() {
    return {
      assessment: null,
      assessee: null,
    };
  },
  methods: {
    startAssessmentClicked() {
      this.$router.push({
        name: 'assessment-home',
        params: {assessmentId: this.$route.params.assessmentId, assesseeId: this.$route.params.assesseeId},
      });
    },
  },
};
</script>
