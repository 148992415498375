<template>
  <PageLayout>
    <template #back-navigation><span></span></template>
    <template #content-header>
      {{ $t('Assessments') }}
    </template>
    <template #content-header-suffix>{{ $t('Administration') }}</template>
    <template #content>
      <div v-if="assessments" class="w-100 container">
        <DataTable :value="assessments"
                   :autoLayout="true"
                   :resizableColumns="true"
                   :filters.sync="filters"
                   @row-click="assessmentSelected"
                   :rowHover="true"
                   :globalFilterFields="['title', 'name', 'email']"
                   :paginator="true"
                   :rows="15"
                   dataKey="id"

        >
          <template #empty>
            {{ $t('No assessments found') }}
          </template>
          <template #header>
            <div class="d-flex flex-column flex-md-row align-items-center">
            <div class="flex-grow-1">
                 <IconInput
                     v-model="filters['global'].value" :placeholder="$t('Search')"
                     type="text"
                     icon="pi pi-search"
                     :clear="true"
                 />
            </div>

              <InputSwitch v-model="showCompleted" class="mx-2" @change="filterChanged()"/>
              <small>{{ $t('Show completed') }}</small>
              <InputSwitch v-model="showMyAssessments" class="mx-2" @change="filterChanged()"/>
              <small>{{ $t('My assessments') }}</small>
            </div>
          </template>
          <Column field="title" :header="$t('Title')" sortable>
          </Column>
          <Column field="name" :header="$t('Assessee')" sortable>
            <template #body="slotProps">
              <div class="user-column">
                <UserImage :src="slotProps.data.assessee_image" class="user-image"></UserImage>
                {{ slotProps.data.name }}
              </div>
            </template>
          </Column>
          <Column field="email" :header="$t('Assessee Email')" sortable>
          </Column>
          <Column field="assessor" :header="$t('Assessor')" sortable>
            <template #body="slotProps">
              <div class="user-column">
                <UserImage :src="slotProps.data.assessor_image" class="user-image"></UserImage>
                {{ slotProps.data.assessor }}
              </div>
            </template>
          </Column>

          <Column field="due_date" :header="$t('Due date')" sortable>
            <template #body="slotProps">
              <div>{{ $helper.formatDate(slotProps.data.due_date) }}</div>
            </template>
          </Column>
          <Column field="completed_date" :header="$t('state')" sortable>
            <template #body="slotProps">
              <div class="text-center">
                <template v-if="slotProps.data.completed_date">
                <span class="rounded-pill px-2 py-1 text-white border border-success font-weight-bold text-success">
                  {{ $t('completed') }}
                </span>
                </template>
                <template v-else>
                  <span class="rounded-pill px-2 py-1 border text-white font-weight-bold">
                    {{ $t('planned') }}
                  </span>
                </template>
              </div>

            </template>
          </Column>
        </DataTable>
      </div>
    </template>
    <template #content-footer>
      <PrimaryButton @click="addAssessment">
        {{ $t('Add assessment') }}
      </PrimaryButton>
    </template>
  </PageLayout>


</template>

<style lang="scss">

.user-column {
  display: flex;
  align-items: center;

  .user-image img {
    width: 50px;
    height: 50px;
    margin: 0 2em;
  }

}
</style>

<script>
import UserImage from "@/components/UserImage";
import PageLayout from "./PageLayout";
import {FilterMatchMode} from "primevue/api";
import IconInput from "../../components/IconInput.vue";

export default {
  name: 'assessmentsEditList',
  data() {
    return {
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      showCompleted: false,
      showMyAssessments: false,
      assessments: null
    }
  },
  created() {
    this.getAssessments();
  },
  methods: {
    addAssessment() {
      if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])) {
        this.$router.push({name: 'backend.assessments.assessment'});
      }
    },
    getAssessments() {
      this.$store.dispatch('getAssessments').then((res) => {
        this.assessments = res.data.filter((assessment) => {
          return assessment.completed_date === null;
        })
      })

    },
    assessmentSelected(assessment) {
      if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])) {
        this.$router.push({name: 'backend.assessments.assessment', params: {id: assessment.data.id}});
      }
    },
    filterChanged() {
      this.assessments = this.$store.state.assessments
      if (this.showCompleted) {
        this.assessments = this.assessments.filter((assessment) => {
          return assessment.completed_date;
        })
      } else {
        this.assessments = this.assessments.filter((assessment) => {
          return assessment.completed_date === null;
        })
      }
      if (this.showMyAssessments) {
        this.assessments = this.assessments.filter((assessment) => {
          return assessment.assessor_id == this.$store.getters.user.id
        })
      }
    },
  },
  components: {
    IconInput,
    PageLayout,
    UserImage,
  }
}
</script>