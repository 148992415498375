<template>
  <div class="login">
    <div class="header row align-items-center mr-0 ml-0 mb-5">
      <div class="col-sm-12">
        <h1 class="logo text-white text-center text-uppercase font-weight-bold align-middle">
          Talent<span>eers</span>
        </h1>
        <div class="container text-center text-white">
          <h4 class="text-uppercase">{{ $t('password') }} <span class="font-weight-light">{{$t('Reset')}}</span></h4>
          <p>{{ $t('Enter your email address and we\'ll send you a link to reset your password.') }}</p>
        </div>

      </div>
    </div>
    <div class="text-center pl-4 pr-4 bottom container">
      <form @submit.prevent="sendResetLink" class="mx-auto">
        <div class="row form-group" :class="{ 'form-group--error': $v.email.$error }">
          <div class="center">
            <IconInput
                v-model="$v.email.$model"
                placeholder="email"
                type="email"
                icon="pi pi-user"
            />
          </div>
        </div>
        <div class="error" v-if="!$v.email.required">{{ $t('requiredField') }}</div>
        <div class="error" v-if="!$v.email.email">{{ $t('error.typeEmail') }}</div>
          <div class="row mt-4">
            <div class="center">
              <button
                  class="btn btn-primary btn-block text-uppercase rounded-pill"
                  :disabled="submitStatus === 'PENDING'"
              >{{ $t('Reset Password') }}
              </button>
            </div>
          </div>
          <div class="row mt-4">
            <div class="center text-right">
              <small>
                <router-link
                    to="login"
                    class="text-white"
                    :disabled="submitStatus === 'PENDING'"
                >{{ $t('backToLogin') }}?
                </router-link>
              </small>
            </div>
          </div>
      </form>
    </div>

  </div>
</template>

<script>
import IconInput from '../components/IconInput.vue';
import {email, required} from 'vuelidate/lib/validators';

export default {
  name: 'forgot-password',
  data() {
    return {
      email: '',
      submitStatus: null,
    };
  },
  created() {
    this.$store.dispatch('clearStorage');
  },
  methods: {
    async sendResetLink() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.submitStatus = 'PENDING';
        let email = this.email;
        this.$http
            .post('/forgot-password', {email})
            .then(() => {
              this.submitStatus = 'OK';
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Password reset requested'),
                detail: this.$t('You will get an E-Mail with a link to reset your password.'),
                life: 3000,
              });
              this.$router.push({path: `/login`});
            })
            .catch((error) => {
              this.submitStatus = 'OK';
              this.$helper.showErrorToast(error, this.$t('Error in resetting password'))
            });
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    IconInput,
  },
};
</script>

<style lang="scss">
.header {
  min-height: 40vh;
}

.center {
  margin: 0 auto;
}
</style>
