<template>
    <SideNavigationLayout>
        <template #sidenav-header>
          <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addTag">
            {{ $t('Tag') }} <span class="font-weight-lighter">{{$t('add')}}</span>
          </button>
      </template>
      <template #sidenav>
        <TreeList class="tags-navigation"
                    :nodes="tags"
                    :column-field="'name'"
                    @nodeSelected="tagSelected"
                    routeName="backend.tags.tag"
          ></TreeList>
      </template>

      <template #content-header>
        <span>
           {{ $t('Tag') }}
        </span>

      </template>
      <template #content-header-suffix>
        <span v-if="$route.params.id">
          {{ $t('edit') }}
        </span>

        <span v-if="!$route.params.id"
        >
          {{ $t('add') }}
        </span>
      </template>
      <template #content>
          <router-view :key="$route.path"/>
      </template>
      <template #content-footer>
        <portal-target name="footer-actions"></portal-target>
      </template>
    </SideNavigationLayout>
</template>
<script>
import TreeList from "@/components/TreeList";
import SideNavigationLayout from "@/views/backend/SideNavigationLayout";

export default {
  name: 'tags-layout',
  created() {
    this.getTags();
  },
  methods: {
    addTag() {
      this.$router.push({name: 'backend.tags.tag'});
    },
    getTags() {
      this.loading = true;
      this.$store.dispatch('getTags')
    },
    tagSelected(tag) {
      localStorage.setItem('selectedTag', JSON.stringify(tag))
      this.$router.push({name: 'backend.tags.tag', params: {id: tag.id}});
    },
  },
  computed: {
    tags() {
      return this.$store.state.tags
    }
  },
  components: {
    TreeList,
    SideNavigationLayout,
  },
}
</script>
