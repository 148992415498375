<template>
  <div class="d-flex ml-0 pl-0 flex-row flex-grow-1 h-100">
    <portal to="backNavigation">
        <slot name="back-navigation"></slot>
    </portal>
    <portal to="appPageTitle">
      <div class="d-flex justify-center align-center">
        <h5 class="text-center font-weight-bolder text-center text-white mb-0">
          <slot name="content-header"></slot>
        </h5>
        <h5 class="font-weight-lighter ml-2 text-white mb-0">
              <slot name="content-header-suffix"></slot>
            </h5>
      </div>

    </portal>
    <div
        class="overflow-auto flex-grow-1 flex-column flex-fill d-flex">
      <div class="content-header-wrapper py-1 px-3 d-flex" v-if="$slots['content-header-right']">
        <div class="d-flex flex-column align-items-center justify-content-center flex-fill page-title-flex">
          <div class="d-flex flex-grow-1"></div>
          <div class="d-flex align-items-center small">
            <slot name="content-header-right"></slot>
          </div>

        </div>
      </div>
      <div class="content p-0 p-md-3 d-flex flex-fill overflow-auto">
        <slot name="content"></slot>
      </div>
      <div class="content-footer d-flex p-4 container flex-column flex-sm-row  align-items-center justify-content-center">
        <slot name="content-footer"></slot>
      </div>
    </div>
  </div>

</template>
<style lang="scss">
.content-header-wrapper {
  border-top-right-radius: 3em;
  border-bottom-right-radius: 3em;
  font-size: x-large;
}

.content-footer {
  .btn-danger {
    float: left;
  }

  .btn {
    float: right;
  }
}
</style>
<script>

export default {
  name: 'PageLayout',
}
</script>