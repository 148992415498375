<template>
  <div>
     <router-link v-if="link" :to="link">
      <img :src="src" alt="profile-pic" class="profile-image"/>
     </router-link>
      <img v-else :src="src" alt="profile-pic" class="profile-image" @click="$emit('click')"/>
  </div>
</template>
<script>
export default {
  name: 'UserImage',
  props: {
    src: {
      type: String,
      default: '',
    },
    link:{
      type:String,
      default:null
    }
  },
};
</script>
<style scoped lang="scss">
  .profile-image{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    border:3px solid $primary;
    border-top:3px solid $secondary;
    border-bottom:3px solid $secondary;
  }
</style>
