<template>
  <div class="w-100">
    <form>
      <div class="row">
        <div class="col-12 form-group" v-if="jobPosition.user"
             :class="{ 'form-group--error': $v.jobPosition.functional_group.name.$error }">
          <label class="pr-1">{{ $t('Currently occupied by') }}</label>
          <router-link :to="'/backend/hr-groups/user/'+jobPosition.user.id">{{ jobPosition.user.first_name }} {{ jobPosition.user.last_name }}
          </router-link>
        </div>

        <div class="col-12 form-group" :class="{ 'form-group--error': $v.jobPosition.title.$error }">
          <label for="title">{{ $t('Title') }}</label>
          <InputText id="title" type="text" v-model="jobPosition.title"/>
        </div>
        <div class="error" v-if="!$v.jobPosition.title.required">Pflichtfeld</div>
        <div class="error" v-if="!$v.jobPosition.title.maxLength">
          {{ this.$t('Only {maxLength} Characters allowed', {maxLength: 255}) }}
        </div>

        <div class="col-12 form-group" v-if="jobPosition.functional_group"
             :class="{ 'form-group--error': $v.jobPosition.functional_group.name.$error }">
          <label>{{ $t('Functional group') }}</label>
          <PickerInput id="functional-group"
                       :title="$t('Functional group')"
                       :placeholder="$t('Select functional group')"
                       :selected="selectedFunctionalGroup"
                       :show-clear="false"
                       ref="functional-group-picker">
            <TreeList :nodes="this.functionalGroups"
                      @nodeSelected="functionalGroupSelected"
                      :column-field="'name'"
            ></TreeList>
          </PickerInput>
        </div>


        <div class="error" v-if="!$v.jobPosition.functional_group.name.required">Pflichtfeld</div>

        <div class="col-12 form-group" v-if="$route.params.id && jobRoles">
          <label>{{ $t('Job roles') }}</label>
          <CustomPickList :data="jobRoles"
                          :column-field="'title'"
                          @move-to-target="addJobRole"
                          @move-to-source="removeJobRole"
                          :prefix-field="'group_name'"
          ></CustomPickList>
        </div>
      </div>

      <portal to="footer-actions">
        <FormActions>
          <template #left>
            <DangerButton @click="confirmDelete" v-if="$route.params.id">{{ $t('delete') }}</DangerButton>
          </template>
          <SecondaryButton @click="confirmDuplicate" v-if="$route.params.id">{{ $t('Duplicate') }}</SecondaryButton>
          <PrimaryButton @click="saveJobPosition" v-if="this.jobPosition.id"> {{ $t('save') }}</PrimaryButton>
          <PrimaryButton @click="saveJobPosition" v-if="!this.jobPosition.id"> {{ $t('continue') }}</PrimaryButton>
        </FormActions>
      </portal>
    </form>

    <div v-if="$route.params.id && jobPosition.competences" class="w-100 mt-4">
          <div v-if="jobPosition.competences.length >0">
            <CompetenceGrid
                            :title="$t('Competences')"
                            :subtitle="jobPosition.title"
                            :competences="jobPosition.competences"
                            :show-current-job="true"
                            :show-job-abilities="true"
                            @competenceSelected="competenceSelected"
            ></CompetenceGrid>
          </div>
          <div class="col-12" v-if="jobPosition.categories.length > 0">
            <div class="row">
              <div class="col-3 d-flex justify-content-center v-center">
                <span>  <img class="" src="@/assets/icons/diamound-green.svg" alt="diamound-green"/></span><small
                  class="mb-0 ml-2 text-uppercase">{{ $t('requirementCurrent') }}</small>
              </div>
            </div>
          </div>
        </div>

    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: jobPosition.title}) }}
      </template>
      <div class="confirmation-content">
        {{ $t('Do you really want to delete jobPosition {title}?', {title: jobPosition.title}) }}
      </div>

      <div v-if="jobPosition.user">
        <p class="mb-0" v-if="jobPosition.user">{{
            $t('The job position can not be deleted, because it is linked to the following user:')
          }}</p>
        <div>
          <a class="category-title" @click="$router.push('/backend/hr-groups/user/' + jobPosition.user.id)">{{
              jobPosition.user.first_name
            }}
            {{ jobPosition.user.last_name }}</a>
        </div>
      </div>
      <template #footer>

        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDelete"> {{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DangerButton v-if="!jobPosition.user" @click="deleteJobPosition">
            {{ $t('delete') }}
          </DangerButton>
        </FormActions>
      </template>
    </Dialog>
    <Dialog :visible.sync="showConfirmDuplicate" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Duplicate {title}', {title: jobPosition.title}) }}
      </template>
      <div class="confirmation-content">
        <form>

          <div class="col-12 form-group" :class="{ 'form-group--error': $v.duplicateTitle.$error }">
            <p>{{ $t('Enter title of duplicated job position:') }}</p>
            <InputText id="duplicated_title" type="text" v-model="duplicateTitle"/>
          </div>
          <div class="error" v-if="!$v.duplicateTitle.required">{{ $t('Required field') }}</div>

        </form>

      </div>

      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDuplicate"> {{ $t('Cancel') }}</SecondaryButton>
          </template>
          <PrimaryButton @click="duplicateJobPosition">{{ $t('Duplicate') }}</PrimaryButton>
        </FormActions>
      </template>
    </Dialog>

    <Dialog :visible.sync="showCompetenceDescription" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ selectedCompetenceTitle }}
      </template>
      <div class="confirmation-content">
        <p>{{ selectedCompetenceDescription }}</p>
        <div class='competence-title-container'>
          <span class='competence-title'>{{
              $t('competences.levelTitles.level' + (selectedCompetenceJobAbility) + '.title')
            }}</span>
          <span
              class='competence-title-suffix'> {{
              $t('competences.levelTitles.level' + (selectedCompetenceJobAbility) + '.suffix')
            }}</span>
        </div>
        <p v-html="selectedCompetenceLevelDescription"/>

      </div>
    </Dialog>
  </div>

</template>

<script>
import {maxLength, required} from 'vuelidate/lib/validators';
import Dialog from 'primevue/dialog';
import PickerInput from '@/components/PickerInput';
import TreeList from '@/components/TreeList';
import CustomPickList from '@/components/CustomPickList';
import CompetenceGrid from '@/components/CompetenceGrid.vue';


export default {
  name: 'jobPositions',
  data() {
    return {
      jobPosition: {
        id: null,
        title: null,
        users: [],
      },
      jobRoles: null,
      selectedCompetenceJobAbility: null,
      selectedCompetenceTitle: null,
      selectedCompetenceDescription: null,
      selectedCompetenceLevelDescription: null,
      selectedFunctionalGroup: null,
      showConfirmDelete: false,
      showCompetenceDescription: false,
      showConfirmDuplicate: false,
      duplicateTitle: null,
      expandedKeys: {}
    }
  },
  created() {
    this.getFunctionalGroups()
    this.getJobPosition()
  },
  methods: {
    getJobPosition() {
      if (this.$route.params.id) {
        this.$http.get('job-positions/' + this.$route.params.id)
            .then((res) => {
              console.log("jobposition ", res.data)
              this.jobPosition = {...res.data}
              this.duplicateTitle = this.jobPosition.title + ' ' + this.$t('Copy')
              this.selectedFunctionalGroup = this.jobPosition.functional_group.name
              this.getJobRoles()
            }).catch(() => {
          this.$router.push({name: 'backend.job-positions.job-position'})
        })
      } else {
        this.selectedFunctionalGroup = this.functionalGroups[0].name
        this.jobPosition.functional_group = this.functionalGroups[0]
      }
    },
    getFunctionalGroups() {
      this.$store.dispatch('getFunctionalGroups');
    },
    getJobRoles() {
      this.$store.dispatch('getJobRoles').then(() => {
        this.jobRoles = [this.$store.state.jobRoles.filter((tag) => {
          return !this.jobPosition.jobRoles.some((jobPositionJobRole) => {
            return tag.id === jobPositionJobRole.id
          })
        }), this.jobPosition.jobRoles]
      })
    },
    saveJobPosition() {
      console.log("saving", this.jobPosition)
      this.$v.jobPosition.$touch();
      if (this.$v.jobPosition.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          this.$http.put('job-positions/' + this.jobPosition.id, this.jobPosition)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Job position successfully updated'),
                  life: 3000,
                });
                this.$store.dispatch('getFunctionalGroups')
              })
              .catch((error) => {
                this.$helper.showErrorToast(error, this.$t('Error in updating'))
              });
        } else {
          console.log("postiong")
          this.$http.post('job-positions', this.jobPosition)
              .then((res) => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Job position added successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getFunctionalGroups')
                this.$router.push('/backend/job-positions/job-position/' + res.data.id)
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in adding jobPosition'),
                  detail: error.message,
                  life: 3000,
                });
              });
        }
      }
    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    confirmDuplicate() {
      this.showConfirmDuplicate = !this.showConfirmDuplicate
    },
    deleteJobPosition() {
      this.$http.delete('job-positions/' + this.jobPosition.id)
          .then((res) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t(res.data.message),
              life: 3000,
            });
            this.$router.push('/backend/job-positions/job-position')
          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    addJobRole(jobRole) {
      for (let item of jobRole.items) {
        this.$http.post('job-position-job-role', {'jobPositionId': this.jobPosition.id, 'jobRoleId': item.id})
            .then(() => {
              this.getJobPosition()
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Job role successfully added'),
                life: 3000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
    },
    removeJobRole(jobRole) {
      console.log("remove")
      for (let item of jobRole.items) {
        this.$http.delete('job-position-job-role/' + this.jobPosition.id + '/' + item.id)
            .then(() => {
              this.getJobPosition()
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Job role successfully removed'),
                life: 3000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
    },
    duplicateJobPosition() {
      this.$v.duplicateTitle.$touch();
      if (this.$v.duplicateTitle.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$http.post('job-positions/' + this.jobPosition.id + '/duplicate', {
          'title': this.duplicateTitle
        })
            .then((res) => {
              this.showConfirmDuplicate = false;
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Job position successfully duplicated'),
                life: 3000,
              });
              this.$router.push('/backend/job-positions/job-position/' + res.data.id)
            })
            .catch((error) => {
              this.showConfirmDuplicate = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      }
    },
    functionalGroupSelected(functionalGroup) {
      this.jobPosition.functional_group = functionalGroup
      this.selectedFunctionalGroup = functionalGroup.name
      this.$refs["functional-group-picker"].closeDialog()
    },
    competenceSelected(competence) {
      this.selectedCompetenceJobAbility = competence.job_ability
      this.$http.get('competences/' + competence.id).then((res) => {
        this.selectedCompetenceTitle = res.data.title;
        this.selectedCompetenceDescription = res.data.description;
        this.selectedCompetenceLevelDescription = res.data.competence_levels[competence.job_ability].description;

        this.showCompetenceDescription = true;
      })

    }
  },
  components: {
    Dialog,
    PickerInput,
    TreeList,
    CustomPickList,
    CompetenceGrid
  },
  validations: {
    jobPosition: {
      title: {
        required,
        maxLength: maxLength(255)
      },
      functional_group: {
        name: {
          required,
        },
      },
    },
    duplicateTitle: {
      required,
    }
  },
  computed: {
    functionalGroups() {
      return this.$store.state.functionalGroups
    }
  }

}
</script>