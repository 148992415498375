<template>
  <div class="d-flex w-100 justify-content-center info-container-wrapper">
    <div class="info-container m-5 p-3">
      <div>{{ $t('Here you can add and edit competences and competence categories') }}</div>
    </div>


  </div>

</template>
<script>

export default {
  name: 'competencesInfo',
}
</script>