<template>
  <PageLayout>
    <template #back-navigation>
      <BackLink to="assessment-list" :label="$t('plannedAssessments')"/>
    </template>
    <template #content-header>
      {{ assessment.title }}
    </template>
    <template #content-header-suffix>
      {{ $moment(assessment.dueDate).format('D.M.Y') }}
    </template>
    <template #content>
      <div class="container" v-if="assessmentCompetences.length > 0">
        <CompetenceGrid
            :competences="assessmentCompetences"
            @competenceSelected="competenceClicked"
            :show-public-ability="true"
            :title="$t('publicImage')"
            :subtitle="assessee.first_name"
            :activeKey="'public_ability'"
            :stateKey="'public-assessment-home-'+assessment.id"
        ></CompetenceGrid>
      </div>
    </template>

    <template #content-footer>
          <div class="d-flex">
            <span>
              <img class src="@/assets/icons/triangle.svg" alt="triangle"/>
            </span>
            <small class="mb-0 ml-2 text-uppercase">{{ $t('abilityPublic') }}</small>
          </div>

    </template>

  </PageLayout>

</template>

<script>
import CompetenceGrid from '@/components/CompetenceGrid.vue';
import PageLayout from "../backend/PageLayout";
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'public-assessment-home',
  data() {
    return {
      assessment: null,
      assessee: null,
      competences: [],
      userCompetences: [],
      assessmentCompetences: [],
      selectedCompetence: null,
      expanded: true,
      expandedInner: true,
      currentJobData: false,
      currentJobTitle: null,
      futureJobData: false,
      futureJobTitle: null,
      loading: true,
      expandedKeys: {},
      jobPositions: [],
      selectedJobPosition: null,
    };
  },
  created() {
    this.getCompetences();
  },
  methods: {
    getCompetences() {
      this.$store.dispatch('getAssessment', {id: this.$route.params.assessmentId})
          .then((res) => {
            this.assessment = res.data;
            this.$store
                .dispatch('getEmployee', {id: this.assessment.assessee.id})
                .then(res => {
                  this.assessee = res.data;
                  this.$http
                      .get(
                          '/assessment-competences-tree/' +
                          this.$route.params.assessmentId
                      )
                      .then(res => {
                        this.assessmentCompetences = res.data;
                        this.loading = false;
                      })
                      .catch(err => {
                        this.$toast.add({
                          severity: 'error',
                          summary: 'Kompetenzen nicht geladen',
                          detail: err,
                          life: 3000,
                        });
                      });

                })
                .catch(err => {
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Mitarbeiter nicht geladen',
                    detail: err,
                    life: 3000,
                  });
                });
          });

    },
    competenceClicked(competence) {
      let stepId = this.assessmentCompetences.findIndex(assessmentCompetence => {
        return assessmentCompetence.id === competence.id;
      });
      if (stepId >= 0) {
        this.$router.push({
          name: 'publicassessment-process',
          params: {stepId, assessmentId: this.$route.params.assessmentId},
        });
      }
    },
    convertHex: function (color, opacity) {
      let r = parseInt(color.substring(0, 2), 16);
      let g = parseInt(color.substring(2, 4), 16);
      let b = parseInt(color.substring(4, 6), 16);
      return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    },
  },
  components: {
    BackLink,
    PageLayout,
    CompetenceGrid,
  },
};
</script>
