<template>
  <div class="login">
    <div class="header row align-items-center mr-0 ml-0 mb-5">
      <div class="col-sm-12">
        <h1 class="logo text-white text-center text-uppercase font-weight-bold align-middle">
          Talent<span>eers</span>
        </h1>
        <div class="container text-center text-white">
          <h4 class="text-uppercase">{{ $t('Account') }} <span class="font-weight-light">{{$t('activate')}}</span></h4>
          <p>{{ $t('Enter your password to activate your Account.') }}</p>
        </div>

      </div>
    </div>
    <div class="bottom container text-center pl-4 pr-4 mt-5 login-container">
         <form @submit.prevent="activate">
        <div class="row form-group" :class="{ 'form-group--error': $v.password.$error }">
          <div class="center">
            <IconInput
              v-model="$v.password.$model"
              :placeholder="$t('password')"
              type="password"
              icon="pi pi-user"
            />
          </div>
        </div>
        <div class="error" v-if="!$v.password.required">{{$t('requiredField')}}</div>
        <div class="error" v-if="!$v.password.minLength">{{$t('error.minPWLengthMsg', { minLength: $v.password.$params.minLength.min }) }}</div>
        <div class="row mt-4 form-group" :class="{ 'form-group--error': $v.password_confirmation.$error }">
          <div class="center">
            <IconInput
              v-model="$v.password_confirmation.$model"
              :placeholder="$t('pwRepeat')"
              type="password"
              icon="pi pi-lock"
            />
          </div>
        </div>
        <div class="error" v-if="!$v.password_confirmation.sameAsPassword">{{$t('error.identicalPW')}}</div>
        <div class="row mt-4">
          <div class="center">
            <button class="btn btn-primary btn-block text-uppercase rounded-pill">{{$t('activate')}}</button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="center text-right">
            <small>
              <router-link to="/login" class="text-white">{{$t('backToLogin')}}?</router-link>
            </small>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import IconInput from '../components/IconInput.vue';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
export default {
  name: 'Activate',
  data() {
    return {
      password: '',
      password_confirmation: '',
      submitStatus: null,
      id: this.$route.params.id,
      hash: this.$route.params.hash,
    };
  },
  created() {
    this.$store.dispatch('clearStorage');
  },
  methods: {
    async activate() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.submitStatus = 'PENDING';
        let hash = this.hash;
        let password = this.password;
        let password_confirmation = this.password_confirmation;
        await this.$http.get('/csrf-cookie');
        this.$http
          .put('/activate/'+this.id, { hash, password, password_confirmation })
          .then(res => {
            if (res.data.success) {
              console.log("success")
              this.submitStatus = 'OK';
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Account activated'),
                detail:
                  this.$t('Your account is now active'),
                life: 3000,
              });
              this.$router.push('/login');
            } else {
              this.submitStatus = 'OK';
              this.$toast.add({
                severity: 'error',
                summary: this.$t('Couldnt activate account'),
                life: 3000,
              });
            }
          })
          .catch(() => {
            this.submitStatus = 'OK';
            this.$toast.add({
                severity: 'error',
                summary: this.$t('Couldnt activate account b'),
                life: 3000,
              });
          });
      }
    },
  },
  validations: {
    password: {
      required,
      minLength: minLength(4),
    },
    password_confirmation: {
      sameAsPassword: sameAs('password'),
    },
  },
  components: {
    IconInput,
  },
};
</script>

<style lang="scss">
.header {
  min-height: 40vh;
}
.center {
  margin: 0 auto;
}
</style>
