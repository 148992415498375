<template>
  <PageLayout>
    <template #content>
      <div class="d-flex flex-column w-100">
        <div class="h-25 d-flex w-100">

        </div>
        <div class="d-flex justify-content-center align-items-start w-100">
          <h1 class="logo text-white text-uppercase font-weight-bold">
            Talent<span>eers</span>
          </h1>
        </div>
      </div>

    </template>
    <template #content-footer>
      <div class="d-flex flex-column w-100 mb-4">
        <form @submit.prevent="login">
          <div class="row form-group pb-1" :class="{ 'form-group--error': $v.email.$error }">
            <div class="center">
              <IconInput
                  v-model="$v.email.$model"
                  :placeholder="$t('email')"
                  type="email"
                  icon="pi pi-user"
              />
            </div>
          </div>
          <div class="error" v-if="!$v.email.required">Pflichtfeld</div>
          <div class="error" v-if="!$v.email.email">Username muss eine e-Mail Adresse sein.</div>
          <div class="row mt-3 form-group" :class="{ 'form-group--error': $v.password.$error }">
            <div class="center">
              <IconInput
                  v-model="$v.password.$model"
                  :placeholder="$t('password')"
                  type="password"
                  icon="pi pi-lock"
              />
            </div>
          </div>
          <div class="error" v-if="!$v.password.required">Pflichtfeld</div>
          <div
              class="error"
              v-if="!$v.password.minLength"
          >{{ $t('error.minPWLengthMsg', {minLength: $v.password.$params.minLength.min}) }}
          </div>
          <div class="d-flex flex-row align-items-center justify-content-center">
            <Checkbox id="remember" v-model="remember" :binary="true"/>
            <span for="remember" class="text-white ml-2">{{ $t('keepLoggedIn') }}</span>
          </div>
          <div class="row mt-3">
            <div class="center">
              <button class="btn btn-primary btn-block text-uppercase rounded-pill">{{ $t('login') }}</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="center text-right">
              <small>
                <router-link to="forgot-password" class="text-white">{{ $t('forgotPW') }}</router-link>
              </small>
            </div>
          </div>
        </form>
      </div>
    </template>
  </PageLayout>

</template>

<script>
import Checkbox from 'primevue/checkbox';
import IconInput from '../components/IconInput.vue';
import {email, minLength, required} from 'vuelidate/lib/validators';
import PageLayout from "./backend/PageLayout.vue";

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      remember: false,
    };
  },
  created() {
      if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE])) {
        this.$router.push({
          name: 'administration.home'
        });
      } else if(this.$helper.hasAnyRole([this.$roles.EMPLOYEE])) {
        this.$router.push({
          name: 'home'
        });
      }
  },
  methods: {
    async login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        let email = this.email;
        let password = this.password;
        console.log("login")
        this.$store
            .dispatch('login', {user: {email, password}, remember: this.remember})
            .then(() => {
              this.$store.dispatch('getUser', {})
                  .then(() => {
                    if (this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE])) {
                      this.$router.push({
                        name: 'administration.home'
                      });
                    } else {
                      this.$router.push({
                        name: 'home'
                      });
                    }
                  })
            })
            .catch(err => {
              let message = null;
              if (err.data) {
                if (err.data.message) {
                  message = err.data.message;
                }
              }

              this.$toast.add({
                severity: 'error',
                summary: this.$t('loginFailed'),
                detail: this.$t(message),
                life: 3000,
              });
            });
        // if (email !== "manager@kms.at") {
        //   this.$store
        //     .dispatch('login1', { email, password })
        //     .then(() => {
        //       this.$router.push('/');
        //     })
        //     .catch(err => console.log(err));
        // } else {
        //   this.$store
        //     .dispatch('login2', { email, password })
        //     .then(() => {
        //       this.$router.push('/');
        //     })
        //     .catch(err => console.log(err));
        // }
      }
    },
  },
  components: {
    PageLayout,
    Checkbox,
    IconInput,
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(4),
    },
  },
};
</script>

<style lang="scss">
.header {
  min-height: 40vh;
}

.center {
  margin: 0 auto;
}

.login-container {
  width: 100% !important;
  max-width: 100% !important;
}
</style>
