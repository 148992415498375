<template>
    <SideNavigationLayout>
      <template #sidenav-header>
        <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addCategory">
          {{ $t('Category') }} <span class="font-weight-lighter">{{$t('add')}}</span>
        </button>
        <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addCompetence">
          {{ $tc('Competence') }} <span class="font-weight-lighter">{{$t('add')}}</span>
        </button>
      </template>
      <template #sidenav>
                <TreeList class="competence-navigation"
                  :nodes="categories"
                  :custom-node-key="'competences'"
                  :show-sub-nodes="true"
                  @nodeSelected="categorySelected"
                  @customNodeSelected="competenceSelected"
                  routeName="backend.competences.category"
                  subRouteName="backend.competences.competence"
        ></TreeList>
      </template>
      <template #content-header>
        <span v-if="$route.name === 'backend.competences.competence'">
          {{ $tc('Competence') }}
        </span>
        <span v-if="$route.name === 'backend.competences.category'">
           {{ $t('Category') }}
        </span>
        <span v-if="$route.name === 'backend.competences.competence.info'">
           {{ $t('Competences') }}
        </span>

      </template>
      <template #content-header-suffix>
        <span v-if="$route.params.id">
          {{ $t('edit') }}
        </span>

        <span v-if="!$route.params.id && $route.name !== 'backend.competences.competence.info'"
        >
          {{ $t('add') }}
        </span>
      </template>
      <template #content-header-right>
         <portal-target name="content-header-right"></portal-target>
      </template>
      <template #content>
        <router-view :key="$route.path" :categories="categories"/>
      </template>
      <template #content-footer>
        <portal-target name="footer-actions"></portal-target>
      </template>
    </SideNavigationLayout>
</template>
<style lang="scss">

.form-content {
  max-height: 80vh;
  overflow: auto;
}
</style>
<script>
import TreeList from "@/components/TreeList";
import SideNavigationLayout from "@/views/backend/SideNavigationLayout";

export default {
  name: 'competences-layout',
  created() {
    this.getCategories();
  },
  methods: {
    addCompetence() {
      this.$router.push({name: 'backend.competences.competence'});
    },
    addCategory() {
      this.$router.push({name: 'backend.competences.category'});
    },
    getCategories() {
      this.loading = true;
      this.$store.dispatch('getCategories')
    },
    categorySelected(category) {
      localStorage.setItem('selectedCategory', JSON.stringify(category))
      this.$router.push({name: 'backend.competences.category', params: {id: category.id}});
    },
    competenceSelected(competence) {
      localStorage.setItem('selectedCompetence', JSON.stringify(competence))
      this.$router.push({name: 'backend.competences.competence', params: {id: competence.id}});
    }
  },
  computed: {
    categories() {
      return this.$store.state.categories
    }
  },
  components: {
    TreeList,
    SideNavigationLayout
  },
}
</script>
