<template>
  <div class="assessment-chart" v-if="!loading">
    <Scatter :chart-data="this.datacollection" :chartOptions="this.options" :style="{'height':this.height + 'px'}"/>
  </div>

</template>

<script>
import diamoundGreen from '@/assets/icons/diamound-green.svg';
import diamoundBlue from '@/assets/icons/diamound-blue.svg';
import circle from '@/assets/icons/circle.svg';
import circleClaim from '@/assets/icons/circle-claim.svg';
import fbGray from '@/assets/icons/triangle.svg';
import sbGray from '@/assets/icons/triangle_ud.svg';
import sb from '@/assets/icons/polygon.svg';
import {Chart as ChartJS, Filler, LinearScale, LineElement, PointElement} from 'chart.js'
import {Scatter} from 'vue-chartjs/legacy'

ChartJS.register(LinearScale, PointElement, LineElement, Filler)

export default {
  name: 'assessment-chart',
  props: {
    showCurrentJob: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFutureJob: {
      type: Boolean,
      required: false,
      default: false,
    },
    competencesProp: {
      type: Array,
      required: true,
    },
    showJobAbilities: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAbility: {
      type: Boolean,
      required: false,
      default: false,
    },
    showClaim: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCommonAbility: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPublicAbility: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {
      datacollection: null,
      competences: null,
      currentJobAbility: null,
      futureJobAbility: null,
      commonAbility: null,
      selfAbility: null,
      publicAbility: null,
      min: null,
      claimAbility: null,
      loading: true,
      hidden: false,
    };
  },
  created() {
    this.getCompetences();
    //this.fillData();
  },
  watch: {
    competencesProp: function () {
      this.getCompetences()
    },
    showCurrentJob: function () {
      this.fillData();
    },
    showFutureJob: function () {
      this.fillData();
    },
  },
  methods: {
    fillData() {
      this.datacollection = {
        datasets: []
      }
      const diamoundG = new Image();
      const diamoundB = new Image();
      const circleP = new Image();
      const circleC = new Image();
      const fbGrayImage = new Image();
      const sbImage = new Image();
      diamoundG.src = diamoundGreen;
      diamoundB.src = diamoundBlue;
      circleP.src = circle;
      circleC.src = circleClaim;
      fbGrayImage.src = fbGray;
      if (this.showPublicAbility) {
        sbImage.src = sbGray;
      } else {
        sbImage.src = sb;
      }
      this.datacollection = {
        datasets: [
          {
            label: 'Job aktuell',
            backgroundColor: 'transparent',
            data: this.currentJobAbility,
            pointStyle: diamoundG,
            borderColor: '#92D050',
            tension: 0,
            borderWidth: 1,
            hidden: !this.showCurrentJob || !this.showJobAbilities,
            showLine: true,
            borderDash: [5, 3],
          },
          {
            label: 'Job Zukunft',
            data: this.futureJobAbility,
            backgroundColor: 'transparent',
            pointStyle: diamoundB,
            borderColor: '#00B0F0',
            tension: 0.2,
            borderWidth: 1,
            showLine: true,
            hidden: !this.showFutureJob || !this.showJobAbilities,
            borderDash: [8, 2],
            borderDashOffset: 3,
          },
          {
            label: 'Abgestimmtes Bild',
            data: this.commonAbility,
            backgroundColor: 'transparent',
            pointStyle: circleP,
            borderColor: '#dc8e00',
            tension: 0,
            borderWidth: 1,
            spanGaps: false,
            showLine: true,
            hidden: !this.showCommonAbility,
          },
          {
            label: 'Koennen FB',
            data: this.publicAbility,
            backgroundColor: 'transparent',
            pointStyle: fbGrayImage,
            borderColor: 'transparent',
            tension: 0,
            borderWidth: 1,
            hidden: !this.showPublicAbility,
            showLine: false,
          },
          {
            label: 'Koennen SB',
            data: this.selfAbility,
            backgroundColor: 'transparent',
            pointStyle: sbImage,
            borderColor: '#dc8e00',
            tension: 0,
            borderWidth: 1,
            hidden: !this.showAbility,
            showLine: !this.showPublicAbility,
            spanGaps: true,
          },
          {
            label: 'min',
            data: this.min,
            backgroundColor: 'rgba(220, 142, 0, 0.2)',
            borderColor: 'transparent',
            pointRadius: 0,
            tension: 0,
            borderWidth: 1,
            showLine: true,
            fill: 'start',
          },
          {
            label: 'Wollen SB',
            data: this.claimAbility,
            backgroundColor: 'rgba(153,161,169, 0.2)',
            borderColor: 'rgba(153,161,169, 0.5)',
            pointStyle: circleC,
            pointRadius: 2,
            tension: 0.2,
            borderWidth: 2,
            hidden: !this.showClaim,
            showLine: true,
            spanGaps: true,
            fill: '-1',
          },
        ],
      };
      this.options = {
        layout: {
          autoPadding: false,
          padding: {
            right: 1
          }
        },
        hover: {mode: null},
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            reverse: true,
            grid: {
              lineWidth: (this.datacollection.datasets[4].data.length === 1) ? 0 : 3,
              color: 'rgba(27, 36, 43, 0.7)',
              offset: true
            },
            min: (this.datacollection.datasets[4].data.length===1)?0:1,
            max:(this.datacollection.datasets[4].data.length===1)?2:null,
            ticks: {
              fontColor: 'transparent',
              stepSize: 1,
              display: false,
              backdropPadding: 0,
              padding: 0,
            },
            offset: true,
            position: {x: null, y: null},
          },
          x: {
            border: {
              display: false,
            },
            min: 0,
            max: 7,
            offset: true,
            grid: {
              lineWidth: 0.5,
              color: 'white',
              offset: true
            },
            position: {x: null, y: null},
            ticks: {
              stepSize: 1,
              display: false,
            },
          },
        },
      };
    },
    getCompetences() {
      this.competences = [];
      this.currentJobAbility = [];
      this.futureJobAbility = [];
      this.selfAbility = [];
      this.publicAbility = [];
      this.commonAbility = [];
      this.claimAbility = [];
      this.min = [];
      for (const [index, competence] of this.competencesProp.entries()) {
        this.competences.push(competence);
         if (competence.job_ability == 0 || !('job_ability' in competence)) {
          this.currentJobAbility.push({
            x: null,
            y: index+1,
          });
        }else{
           this.currentJobAbility.push({
            x: competence.job_ability - 1,
            y: index+1,
          });
         }
        if (competence.future_job_ability == 0 || !('future_job_ability' in competence)) {
          this.futureJobAbility.push({
            x: null,
            y: index+1,
          });
        } else {
          this.futureJobAbility.push({
            x: competence.future_job_ability - 1,
            y: index+1,
          });
        }


        if (competence.self_ability == 0 || !('self_ability' in competence)) {
          this.selfAbility.push({
            x: null,
            y: index+1,
          });
        } else {
          this.selfAbility.push({
            x: competence.self_ability - 1,
            y: index+1,
          });
        }
        if (competence.self_claim == 0 || !('self_claim' in competence)) {
          this.claimAbility.push({
            x: null,
            y: index+1,
          });
        } else {
          this.claimAbility.push({
            x: competence.self_claim - 1,
            y: index+1,
          });
        }


        if (competence.public_ability == 0 || !('public_ability' in competence)) {
          this.publicAbility.push({
            x: null,
            y: index+1,
          });
        } else {
          this.publicAbility.push({
            x: competence.public_ability - 1,
            y: index+1,
          });
        }

        if (competence.common_ability == 0 || !('common_ability' in competence)) {
          this.commonAbility.push({
            x: null,
            y: index+1,
          });
        } else {
          this.commonAbility.push({
            x: competence.common_ability - 1,
            y: index+1,
          });
        }


        // this.min.push({
        //   x: 0,
        //   y: index,
        // });
      }
      this.fillData();
      this.loading = false;
    }
    ,
    competenceClicked(competenceId) {
      this.$router.push({
        name: 'assessment-detail',
        params: {userId: this.$route.params.userId, competenceId},
      });
    }
    ,
    finishClicked() {
      this.$router.push('/');
    }
    ,
    toggle(event, competenceId) {
      this.$refs['op-' + competenceId][0].toggle(event);
    },
  },
  components: {
    Scatter
  }
}
</script>
<style lang="scss">
.assessment-chart {
  background-color: rgba(68, 80, 92, 0.8)
}
</style>
