<template>
  <PageLayout>
    <template #back-navigation>
       <BackLink to="public-assessment-home" :params="{assessmentId:assessment.id}" :label="$t('toSummary')"/>
    </template>
    <template #content-header>
      {{ $t('publicImage') }}
    </template>
    <template #content-header-suffix v-if="currentCompetence">
      <span class="px-2" :style="{'border-left': '2px solid #'+currentCompetence.competence_category.color}">
        {{ currentCompetence.title }}
      </span>
    </template>
    <template #content>
      <div class="container" v-if="assessee">
        <div class="pt-0 competence-title-description">= {{ currentCompetence.description }}</div>
        <p class="question-title text-center mb-5 pb-3 mt-3">{{ $t('ability') }}
          {{ assessee.first_name }}</p>
        <div class="Slider container px-0 px-sm-5 sliders">
          <div class="prepend-line single"></div>
          <v-slider
              class="custom-slider public-slider-fill"
              v-model="abilityFillValue"
              min="1"
              max="8"
              steps="6"
              color="#dc8e00"
              track-color="#C3C8CC"
          ></v-slider>
          <v-slider
              class="custom-slider public-slider"
              v-model="abilityValue"
              min="0"
              max="8"
              @end="abilityDragEnd"
              steps="7"
              :ticks="(user.show_ticks)?'always':false"
              color="transparent"
              track-color="transparent"
              thumb-size="20"
              thumb-label="always"
          >
            <template v-slot:thumb-label>
              <img class="publicassessment-tooltip-icon" src="@/assets/icons/k.svg"/>
            </template>
            <template v-slot:prepend>
              <div class="prepend-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('novice') }}</span>
              </div>
            </template>
            <template v-slot:append>
              <div class="append-indicator">
                <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="50 0, 65 200, 35 200"/>
                </svg>
                <span class="label">{{ $t('expert') }}</span>
              </div>
            </template>
          </v-slider>
        </div>
        <div class="container mt-10" v-if="currentCompetence.competence_levels">
          <span v-if="abilityValue <= 0">
            <div class="competence-title-container">
              <span class="competence-title text-uppercase">{{ $t('ability') }}</span>
               <span
                   class='competence-title-suffix'> {{ $t('Rating') }}</span>
            </div>
          </span>
          <div class='competence-title-container' v-if="abilityValue>0">
            <span class='competence-title'>{{ $t('competences.levelTitles.level' + (abilityValue) + '.title') }}</span>
            <span
                class='competence-title-suffix'> {{
                $t('competences.levelTitles.level' + (abilityValue) + '.suffix')
              }}</span>
          </div>
        </div>
        <div
            class="overflow-auto container text-container competence-level-description"
            v-if="currentCompetence.competence_levels"
        >
          <span
              v-if="abilityValue > 0 && currentCompetence.competence_levels.length > 1"
              v-html="getCompetenceLevelDescription(abilityValue)"
          ></span>
          <span v-if="abilityValue <= 0">{{ currentCompetence.competence_levels[0].description }}</span>
        </div>
      </div>
    </template>
    <template #content-footer>
        <div>
          <SecondaryButton v-if="currentStep>0"
                  @click="backClicked"
          >{{ $t('back') }}
          </SecondaryButton>
          <RouterLink v-else :to="{name: 'public-assessment-home', params:{assessmentId:assessment.id}}">
            <SecondaryButton
          >{{ $t('toSummary') }}
          </SecondaryButton>
          </RouterLink>
        </div>
        <div class="flex-grow-1 text-center competence-progress mx-5 my-2">
          <small class="font-weight-lighter">Kompetenz {{ currentStep + 1 }} von {{ assessment.competences.length }}</small>
          <ProgressBar :value="completedPercent">{{ completedPercent }}%</ProgressBar>
        </div>
        <div>
          <PrimaryButton
              v-if="currentStep < assessment.competences.length - 1"
              @click="continueClicked"
          >{{ $t('continue') }}
          </PrimaryButton>
          <button
              v-if="currentStep >= assessment.competences.length - 1"
              class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
              @click="continueClicked"
          >{{ $t('complete') }}
          </button>
        </div>

    </template>
  </PageLayout>
</template>
<style lang="scss">
.v-input.custom-slider.public-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
  width: 78.7% !important;
  left: 13.3%;
  position: absolute;
  top: 12px;
  z-index: 3;

  .v-slider--horizontal .v-slider__track-container {
    width: 100%;
    height: 8px !important;
  }

  .v-input__control {
    height: 2px;
  }

  .v-slider__track-background {
    width: 100% !important;
  }

  .v-slider__thumb-container {
    display: none;
  }
}

.active {
  z-index: 5;
}

.inactive {
  z-index: 1;
}

.p-slider-horizontal,
.p-inputtext {
  width: 100%;
}

.p-slider-vertical {
  height: 14em;
}

.page-counter {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.custom-dot {
  width: 100%;
  height: 100%;
}

.ability-slider .vue-slider-dot {
  top: -2em !important;
}

.custom-tooltip {
  z-index: 10 !important;
}

.custom-dot-ability {
  position: relative;

  .indicator {
    color: $gray;
    position: absolute;
    bottom: -1em;
    font-weight: bold;
    left: 0;
    right: 0;
  }
}

.sliders {
  position: relative;
  min-height: 4em;
  width: 90%;

  .custom-slider {
    position: absolute;
    width: 90% !important;
  }
}

.custom-mark-1 {
  position: absolute;
  top: 10px;
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}

.custom-mark-2 {
  position: absolute;
  top: 10px;
  transform: translateX(-100%);
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}

.description-content {
  max-height: 40vh;
  overflow: auto;
}

@media screen and (max-width: $break-x-large) {
  .v-input.custom-slider.public-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 13%;
  }
}

@media screen and (max-width: $break-large) {

  .v-input.custom-slider.public-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 13.6%;
  }


}

@media screen and (max-width: $break-medium-large) {


  .v-input.custom-slider.public-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 14.6%;
  }

}

@media screen and (max-width: $break-medium) {

  .v-input.custom-slider.public-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 15.2%;
  }

}


@media screen and (max-width: $break-small) {
  .v-input.custom-slider.public-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 11%;
  }
}

@media screen and (max-width: $x-small) {
  .v-input.custom-slider.public-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider {
    left: 10%;
  }
}

//Safari Browser Fix
@media not all and (min-resolution: .001dpcm) {

  .public-slider-fill .theme--light.v-slider .v-slider__track-fill,
  .v-input.custom-slider.public-slider-fill.v-input--is-label-active.v-input--is-dirty.theme--light.v-input__slider .v-slider__track-background {
    top: 14px;
  }

}
</style>
<script>
import PageLayout from "../backend/PageLayout.vue";
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'publicassessment-process',
  components: {BackLink, PageLayout},
  data() {
    return {
      user: this.$store.getters.user,
      assessee: null,
      currentCompetence: null,
      competenceId: null,
      currentStep: Number(this.$route.params.stepId),
      data: [],
      abilityValue: 0,
      abilityFillValue: 1,
      assessment: null,
    };
  },
  created: function () {
    this.$store.commit('setLoading', 'loading');
    this.getAssessment()
    this.$http.get('users/' + this.assessment.assessee_id)
        .then((res) => {
          this.assessee = res.data
        })
    this.getCompetence();
  },
  methods: {
    getAssessment() {
      this.$http.get('/assessmentCompetences/' + this.$route.params.assessmentId)
          .then((res) => {
            this.assessment = res.data;
            this.assessee = res.data.assessee;
            if (this.currentStep < this.assessment.competences.length) {
              this.getCompetence();
            }
          })
    },
    continueClicked() {
      if (this.currentStep < this.assessment.competences.length - 1) {
        console.log('pushing to ', this.assessment.id);
        this.$router.push({
          name: 'publicassessment-process',
          params: {assessmentId: this.assessment.id, stepId: ++this.currentStep},
        });
      } else {
        this.$router.push({name: 'public-assessment-home', params:{assessmentId:this.assessment.id}});
      }
    },
    backClicked() {
      let stepId = --this.currentStep
      this.$router.push({
        name: 'publicassessment-process',
        params: {stepId},
      });
    },
    endClicked() {
      this.$router.push({name: 'home'});
    },
    getCompetence() {
      this.$store.commit('setLoading', 'loading');
      this.competenceId = this.assessment.competences[
          this.currentStep
          ].id;
      this.$http.get('/competences/' + this.competenceId).then(res => {
        this.currentCompetence = res.data;
        this.data = this.currentCompetence.competence_levels.map(res => {
          return res.level - 1;
        });
        this.data.push(this.data.length);
        this.currentCompetence.competence_levels.unshift({
          description:
              'Bitte verschiebe jetzt den Regler (K) um den Kompetenzlevel des Mitarbeiters einzuschätzen',
        });
        console.log(this.assessment.competences)
        this.abilityValue = this.assessment.competences[
            this.currentStep
            ].public_ability;
        this.$store.commit('setLoading', 'success');
      });
    },
    abilityDragEnd() {
      this.$store
          .dispatch('postAssessmentCompetence', {
            assessmentId: this.assessment.id,
            competenceId: this.competenceId,
            publicAbility: this.abilityValue
          },)
          .then(() => {
            this.assessment.competences[this.currentStep].public_ability = this.abilityValue
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Fremdbild nicht gesetzt',
              life: 3000,
            });
          });
    },
    abilityChanged() {
      console.log('ability changed ');
    },
    getCompetenceLevelDescription(value) {
      console.log("Getting ", this.currentCompetence)
      let compLevel = this.currentCompetence.competence_levels.find((competenceLevel) => {
        return competenceLevel.level == value
      });
      return compLevel.description;
    }
  },
  computed:{
    completedPercent() {
      if (this.assessment.competences.length > 0) {
        return Math.round(this.assessment.competences.filter((competence) => competence['public_ability'] > 0).length / this.assessment.competences.length * 100);
      }
      return null;
    },
  },
  watch: {
    abilityValue: function () {
      this.abilityFillValue = this.abilityValue
    },
  }
};
</script>
