<template>
  <div>
    <div class="picker-input d-flex align-items-center">
      <div class="flex-grow-1 position-relative">
        <i class="pi pi-list picker-icon" @click="openDialog" v-if="enabled"/>
        <i v-if="selected && showClear && enabled" class="pi pi-times picker-clear-icon"
           @click="$emit('clearClick')"/>
        <div class="p-inputtext p-component" v-if="!selected">{{ placeholder }}</div>
        <div class="p-inputtext p-component" v-if="selected" v-bind:class="{'p-disabled':!enabled}">{{ selected }}</div>

      </div>
      <router-link v-if="externalLink" :to="externalLink" class="mx-3" target="_blank" rel="noopener">
        <i class="pi pi-external-link"
        /></router-link>
    </div>
    <portal to="dialog" v-if="displayDialog">
      <Dialog :header="title" :visible.sync="displayDialog"
              :modal="true"
              :maximizable="true"
              :dismissableMask="true"
              v-on:close-dialog="closeDialog"
              class="picker-input-dialog"
      >
        <slot></slot>
      </Dialog>
    </portal>

  </div>
</template>
<style scoped lang="scss">
.picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.picker-icon {
  position: absolute;
  right: 1em;
  top: 50%;
  margin-top: -0.5em;
  z-index: 3;
  font-weight: bold;
  color: $primary;
}

.picker-clear-icon {
  position: absolute;
  right: 2.5em;
  top: 50%;
  margin-top: -0.5em;
  z-index: 3;
  color: white;
}

.picker-icon:hover, .picker-clear-icon:hover {
  cursor: pointer;
}


</style>
<script>
export default {
  name: 'PickerInput',
  data() {
    return {
      displayDialog: (this.showDialog)?this.showDialog:false,
    }
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    selected: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'placeholder',
    },
    showClear: {
      type: Boolean,
      default: true
    },
    enabled: {
      type: Boolean,
      default: true
    },
    externalLink: {
      type: String,
      default: null
    },
    showDialog:{
      type: Boolean,
      default: false,
    }
  },
  methods: {
    openDialog() {
      this.displayDialog = true;
      localStorage.setItem('expandedKeys', [])
      this.$emit('dialogOpened')
    },
    closeDialog() {
      this.displayDialog = false;
    },
  },
}
</script>
