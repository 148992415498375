<template>
  <div class="login">
    <div class="header row align-items-center mr-0 ml-0 mb-5">
      <div class="col-sm-12">
        <h1 class="logo text-white text-center text-uppercase font-weight-bold align-middle">
          Talent<span>eers</span>
        </h1>
         <div class="container text-center text-white">
          <h4 class="text-uppercase">{{ $t('password') }} <span class="font-weight-light">{{$t('Reset')}}</span></h4>
          <p>{{ $t('Enter your email address and a new password.') }}</p>
        </div>

      </div>
    </div>
    <div class="bottom container text-center pl-4 pr-4 mt-5 login-container">
      <form @submit.prevent="resetPassword">
        <div class="row form-group" :class="{ 'form-group--error': $v.email.$error }">
          <div class="center">
            <IconInput
                v-model="$v.email.$model"
                placeholder="email"
                type="email"
                icon="pi pi-user"
            />
          </div>
        </div>
        <div class="error" v-if="!$v.email.required">{{ $t('requiredField') }}</div>
        <div class="error" v-if="!$v.email.email">{{ $t('error.typeEmail') }}</div>
        <div class="row form-group" :class="{ 'form-group--error': $v.password.$error }">
          <div class="center">
            <IconInput
                v-model="$v.password.$model"
                :placeholder="$t('password')"
                type="password"
                icon="pi pi-lock"
            />
          </div>
        </div>
        <div class="error" v-if="!$v.password.required">{{ $t('requiredField') }}</div>
        <div class="error" v-if="!$v.password.minLength">
          {{ $t('error.minPWLengthMsg', {minLength: $v.password.$params.minLength.min}) }}
        </div>
        <div class="row mt-4 form-group" :class="{ 'form-group--error': $v.passwordRepeat.$error }">
          <div class="center">
            <IconInput
                v-model="$v.passwordRepeat.$model"
                :placeholder="$t('pwRepeat')"
                type="password"
                icon="pi pi-lock"
            />
          </div>
        </div>
        <div class="error" v-if="!$v.passwordRepeat.sameAsPassword">{{ $t('error.identicalPW') }}</div>
        <div class="row mt-4">
          <div class="center">
            <button class="btn btn-primary btn-block text-uppercase rounded-pill">{{ $t('Reset') }}</button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="center text-right">
            <small>
              <router-link to="/login" class="text-white">{{ $t('backToLogin') }}?</router-link>
            </small>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import IconInput from '../components/IconInput.vue';
import {email, minLength, required, sameAs} from 'vuelidate/lib/validators';

export default {
  name: 'reset-password',
  data() {
    return {
      email: '',
      password: '',
      passwordRepeat: '',
      submitStatus: null,
      token: this.$route.params.token,
    };
  },
  created() {
    localStorage.clear()
  },
  methods: {
    async resetPassword() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING';
        console.log('handling submit');
        this.$http
            .post('/reset-password', {
              'email': this.email,
              'token': this.token,
              'password': this.password,
              'password_confirmation': this.passwordRepeat
            })
            .then(() => {
              this.submitStatus = 'OK';
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Password reset'),
                detail:this.$t('The password was reset successfully'),
                life: 3000,
              });
              this.$router.push('/login');
            })
            .catch((error) => {
              this.submitStatus = 'OK';
              this.$helper.showErrorToast(error, this.$t('Error in resetting password'))
            });
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(4),
    },
    passwordRepeat: {
      sameAsPassword: sameAs('password'),
    },
  },
  components: {
    IconInput,
  },
};
</script>

<style lang="scss">
.header {
  min-height: 40vh;
}

.center {
  margin: 0 auto;
}
</style>
