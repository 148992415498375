import 'primevue/resources/themes/saga-orange/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import PrimeVue from 'primevue/config';

import FormActions from "@/components/FormActions";
import PrimaryButton from "@/components/PrimaryButton";
import DangerButton from "@/components/DangerButton";

/* PrimeFaces Components */
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Card from 'primevue/card';
import Accordion from 'primevue/accordion';
import TreeTable from 'primevue/treetable';
import AccordionTab from 'primevue/accordiontab';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import PickList from 'primevue/picklist';
import Column from 'primevue/column';
import Sidebar from 'primevue/sidebar';
import Editor from 'primevue/editor';
import Tooltip from 'primevue/tooltip';
import ColorPicker from 'primevue/colorpicker';
import Calendar from 'primevue/calendar';
import Badge from 'primevue/badge';
import MultiSelect from "primevue/multiselect/MultiSelect";
import Slider from "primevue/slider/Slider";

import store from './store';
import i18n from './i18n';
import helper from './helper';

/*DropDown Component */
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

/* Validation Lib */
import Vuelidate from 'vuelidate';

import vuetify from './plugins/vuetify';

/*Date Lib*/
import VueMoment from 'vue-moment';

import VClamp from 'vue-clamp';

/* Portal View for Layout */
import PortalVue from 'portal-vue'
import {Wormhole} from "portal-vue";
import SecondaryButton from "@/components/SecondaryButton";
import DisabledButton from "@/components/DisabledButton";
import WarningButton from "@/components/WarningButton";

import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';

import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;

const token = localStorage.getItem('token');

Vue.prototype.$http = axios.create({
    baseURL: '/api/v1',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + token,
    },
});

// if (token) {
//   Vue.prototype.$http.defaults.headers.common = {
//     'X-Requested-With': 'XMLHttpRequest',
//     'X-CSRF-TOKEN': token,
//   };
// }
Vue.prototype.$http.defaults.port = 80;


Vue.prototype.$http.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },

    (error) => {
        localStorage.clear()
        return Promise.reject(error);
    }
);

Vue.prototype.$competenceTitles = {
    0: "<div class='competence-title-container'><span class='competence-title'>Novize</span> <span class='competence-title-suffix'>Junior</span></div>",
    1: "<div class='competence-title-container'><span class='competence-title'>Novize</span> <span class='competence-title-suffix'>Senior</span></div>",
    2: "<div class='competence-title-container'><span class='competence-title'>Kenner</span> <span class='competence-title-suffix'>Junior</span></div>",
    3: "<div class='competence-title-container'><span class='competence-title'>Kenner</span> <span class='competence-title-suffix'>Senior</span></div>",
    4: "<div class='competence-title-container'><span class='competence-title'>Könner</span> <span class='competence-title-suffix'>Junior</span></div>",
    5: "<div class='competence-title-container'><span class='competence-title'>Könner</span> <span class='competence-title-suffix'>Senior</span></div>",
    6: "<div class='competence-title-container'><span class='competence-title'>Experte</span> <span class='competence-title-suffix'>Junior</span></div>",
    7: "<div class='competence-title-container'><span class='competence-title'>Experte</span> <span class='competence-title-suffix'>Senior</span></div>",
};

Vue.prototype.$roles = {
    ADMIN: "admin",
    HRADMIN: "hr_admin",
    HRMANAGER: "hr_manager",
    HREMPLOYEE: "hr_employee",
    EMPLOYEE: "employee",
    EXECUTIVE: "executive",
}

Vue.prototype.$helper = helper

Vue.use(PrimeVue);

Vue.component('InputText', InputText);
Vue.component('Textarea', Textarea);
Vue.component('Button', Button);
Vue.component('InputSwitch', InputSwitch);
Vue.component('ProgressBar', ProgressBar);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('Card', Card);
Vue.component('Accordion', Accordion);
Vue.component('TreeTable', TreeTable);
Vue.component('Sidebar', Sidebar);
Vue.component('Toast', Toast);
Vue.component('DataTable', DataTable);
Vue.component('Badge', Badge);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Slider', Slider);

//Bugfix Primevue Picklist add all to source - https://github.com/primefaces/primevue/issues/341
const BasePickList = Vue.extend(PickList);
const FixedPickList = BasePickList.extend({
    methods: {
        moveAllToSource(event) {
            if (this.value[1]) {
                let sourceList = [...this.value[0]];
                let targetList = [...this.value[1]];

                this.$emit('move-all-to-source', {
                    originalEvent: event,
                    items: targetList
                });

                sourceList = [...sourceList, ...targetList];
                targetList = [];

                let value = [...this.value];
                value[0] = sourceList;
                value[1] = targetList;
                this.$emit('input', value);

                this.d_selection[1] = [];
                this.$emit('update:selection', this.d_selection);
                this.$emit('selection-change', {
                    originalEvent: event,
                    value: this.d_selection
                });
            }
        },
    }
})
Vue.component('MyPickList', FixedPickList);


Vue.component('Column', Column);
Vue.component('Dialog', Dialog);
Vue.component('Editor', Editor);
Vue.component('ColorPicker', ColorPicker);
Vue.component('Calendar', Calendar);
Vue.component('VClamp', VClamp);
Vue.directive('tooltip', Tooltip);

Vue.component('FormActions', FormActions);
Vue.component('PrimaryButton', PrimaryButton);
Vue.component('DangerButton', DangerButton);
Vue.component('WarningButton', WarningButton);
Vue.component('SecondaryButton', SecondaryButton);
Vue.component('DisabledButton', DisabledButton);


Vue.use(ToastService);
Vue.use(Vuelidate);
Vue.use(VueMoment);
Vue.use(PortalVue);
Vue.use(Wormhole);
Vue.use(helper);

Vue.component('AccordionTab', AccordionTab);
Vue.component('v-select', vSelect);
Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_LARAVEL_DSN,
    environment: process.env.NODE_ENV,
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
});

new Vue({
    router,
    store,
    i18n,
    validations: {},
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
