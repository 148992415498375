<template>
  <RouterLink :to="{name:to, params}" class="back-link">
    <div class="d-flex align-items-start">
      <i class="pi pi-chevron-left back-navigation-icon"></i>
      <template v-if="label">
        <v-clamp :max-lines="1" class="font-weight-lighter d-block">{{ label}}</v-clamp>
      </template>
    </div>
  </RouterLink>
</template>

<script>
export default {
  name: "BackLink",
  props: {
    to: {
      type: String,
      default: null,
    },
    params: {
      type: Object,
    },
    label: {
      type: String,
      default: null,
    }
  }
}
</script>
<style lang="scss">
.back-link:hover{
  text-decoration:none;
}
.back-link:hover{
.back-navigation-icon{
  text-decoration: none !important;
  color: $primary !important;
}
}
</style>