<template>
  <PageLayout>
    <template #back-navigation><span></span></template>
    <template #content>
      <div class="home page-content d-flex flex-column flex-grow-1" v-if="user">
        <h4 class="logo text-white text-center text-uppercase font-weight-bold align-middle page-title-home">
          Talent<span>eers</span>
        </h4>
        <div class="container content text-center pl-4 pr-4">
          <div class="row">
            <div class="col-sm-12 text-white" v-if="hour < 12 && hour > 0">
              <h4>{{ $t('goodMorning') }}, {{ user.first_name }}!</h4>
            </div>
            <div class="col-sm-12 text-white" v-if="hour >= 12 && hour < 17">
              <h4>{{ $t('hello') }}, {{ user.first_name }}!</h4>
            </div>
            <div class="col-sm-12 text-white" v-if="hour >= 17 && hour <= 23">
              <h4>{{ $t('goodEvening') }}, {{ user.first_name }}!</h4>
            </div>
            <div class="col-sm-12 text-center mt-3">
              <UserImage link="profile" :src="user.user_image"></UserImage>
            </div>
            <div class="col-sm-12" v-if="user.competences.length > 0">
              <ActivityProgressBar class="mb-5" :progress="user.progress"/>
              <div class="mb-5">
                <p v-if="user.progress<1">
                  {{ $t('infoProgress.1') }}
                </p>
                <p v-if="1<=user.progress && user.progress<=25">
                  {{ $t('infoProgress.25') }}
                </p>
                <p v-if="25<user.progress && user.progress<=50">
                  {{ $t('infoProgress.50') }}
                </p>
                <p v-if="50<user.progress && user.progress<=75">
                  {{ $t('infoProgress.75') }}
                </p>
                <p v-if="75<user.progress && user.progress<=90">
                  {{ $t('infoProgress.90') }}
                </p>
                <p v-if="90<user.progress && user.progress<=99.99">
                  {{ $t('infoProgress.99') }}
                </p>
                <p v-if="99.9<user.progress && user.progress<=100">
                  {{ $t('infoProgress.100') }}
                </p>
              </div>

              <button
                  class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
                  @click="startClicked"
                  v-if="user.progress<1"
              >{{ $t('start') }}
              </button>
              <button
                  class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
                  @click="startClicked"
                  v-if="1<=user.progress && user.progress<100"
              >{{ $t('continue') }}
              </button>
              <router-link to="competences" v-if="user.progress>=100">{{ $t('To my competences') }}</router-link>
            </div>
            <div class="col-sm-12 text-center" v-if="user.competences.length <= 0">
              <p> {{ $t('Currently you have no assigned competences.') }}</p>
              <p>
                <router-link to="competences">{{ $t('Here') }}</router-link>
                {{ $t(' you can add competences') }}
              </p>
            </div>
          </div>

          <!--<div class="col-sm-12" v-if="this.messages.length > 0">{{$t('youHaveUnreadMessages')}}:</div>-->
        </div>
        <div class="container text-container">
          <div v-for="message in messages" :key="message.id" class="row">
            <router-link :to="'/messages/' + message.id" class="mt-3 message">
              <div class="notification-bullet"></div>
              <Card v-if="!message.read_date">
                <template slot="title">{{ message.title }}</template>
                <template slot="content">
                  <div class="message-content">{{ message.content }}</div>
                </template>
                <template slot="footer">
                  <small>{{ $moment(message.sent_date).format('DD.MM.YYYY h:mm') }}</small>

                </template>
              </Card>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </PageLayout>

</template>
<style lang="scss">
.p-card-body {
  position: relative;
}

.message {
  position: relative;

  .notification-bullet {
    right: -4px;
  }
}

.message:hover {
  text-decoration: none;
}

.message-content {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
</style>
<script>
import UserImage from "@/components/UserImage";
import ActivityProgressBar from "@/components/ActivityProgressBar";
import PageLayout from "../backend/PageLayout.vue";

export default {
  name: 'home',
  data() {
    return {
      user: null,
      messages: this.$store.getters.messages.filter(message => {
        if (!message.read_date) {
          return message;
        }
      }),
      hour: new Date().getHours(),
    };
  },
  components: {
    PageLayout,
    UserImage,
    ActivityProgressBar,
  },
  methods: {
    loadMessages: function () {
      this.$store.dispatch('getMessages').catch(err => console.log(err));
    },
    startClicked() {
      let lastStepId = this.$store.getters.user.competences.findIndex(competence => {
        return competence.updated_last == true
      })
      console.log('last', lastStepId)
      if (lastStepId < 0) {
        lastStepId = 0
      }
      this.$router.push({name: 'competence-process', params: {stepId: lastStepId}});
    },
  },
  created() {
    this.$store
        .dispatch('getUser', {})
        .then(() => {
          console.log(this.$store.getters.user)
          this.user = this.$store.getters.user;
          // this.loadMessages();
          this.$store.watch(
              state => state.status,
              newValue => {
                if (newValue === 'message-success') {
                  this.messages = this.$store.getters.messages.filter(message => {
                    if (!message.read) {
                      return message;
                    }
                  });
                }
              },
          );
        })
        .catch((err) => {
          let message = null;
          if (err.data) {
            if (err.data.message) {
              message = err.data.message;
            }
          }

          this.$toast.add({
            severity: 'error',
            summary: this.$t('loginFailed'),
            detail: this.$t(message),
            life: 5000,
          });
        });
    // setInterval(() => {
    //   this.loadMessages();
    // }, 3000);
  },
};
</script>
