<template>
  <div v-if="tmpSource">
    <MyPickList class="custom-picklist d-block d-md-flex" v-model="source" dataKey="id" v-bind:class="{'disabled':!enabled}"
                @move-to-target="moveToTarget"
                @move-to-source="moveToSource"
                @move-all-to-target="moveToTarget"
                @move-all-to-source="moveToSource"
    >
      <template #sourceheader>
          <span class="count-badge">{{ tmpSource[0].length }}</span> {{ $t('still available') }}
          <input class="form-control" type="text" v-model="searchSourceQuery" :placeholder="$t('Search')"/>
      </template>
      <template #targetheader>
        <span class="count-badge">{{ tmpSource[1].length }}</span> {{ $t('selected') }}
        <input class="form-control" type="text" v-model="searchTargetQuery" :placeholder="$t('Search')"/>
      </template>
      <template #item="slotProps">
        <div class="competence-row">
          <span
              v-if="colorIndicator"
              class="category-indicator"
              :style="'background:#'+slotProps.item[colorIndicator]"
          ></span>
          <div class="competence-row-content">
            <small v-if="prefixField" class="d-block">
              <span style="font-weight: 300"> {{ slotProps.item[prefixField] }}</span>
            </small>
            <span v-if="slotProps.item">{{ slotProps.item[columnField] }}</span>
            <small v-if="subTitleField" class="d-block">
              <span style="font-weight: 300"> {{ slotProps.item[subTitleField] }}</span>
            </small>
          </div>

        </div>

      </template>
    </MyPickList>
  </div>

</template>
<style lang="scss">
body .custom-picklist .p-picklist-caption {
  background-color: $dark;
  color: white;
}

body .custom-picklist .p-picklist-list {
  background-color: transparent;
}

body .custom-picklist .p-picklist-list .p-picklist-item {
  color: white;
}

body .custom-picklist .p-picklist-list .p-picklist-item.p-highlight {
  background-color: rgba($primary, 0.3) !important;
}

body .custom-picklist .p-picklist-list .p-picklist-item:nth-child(even) {
  background-color: $dark;
}

body .custom-picklist .p-picklist-list .p-picklist-item:nth-child(odd) {
  background-color: $secondary;
}

.custom-picklist {
  .competence-row {
    position: relative;
    padding: 0;

    .category-indicator + .competence-row-content {
      margin-left: 10px;
    }
  }
}
.custom-picklist.disabled  .p-picklist-source-wrapper, .custom-picklist.disabled  .p-picklist-transfer-buttons{
  display:none;
}
.disabled .p-picklist-buttons {
  display: none;
}

@media screen and (max-width: $break-small) {
  body .custom-picklist .p-picklist-list-wrapper {
    width: 100%;
  }
  body .custom-picklist .p-picklist-transfer-buttons{
    display:flex;
    flex-direction:row;
    gap:1em;
    button{
      transform: rotate(90deg);
    }
  }
}
</style>
<script>
export default {
  name: 'CustomPickList',
  props: {
    data: {
      type: Array,
      default: null
    },
    colorIndicator: {
      type: String,
      default: null
    },
    prefixField: {
      type: String,
      default: null
    },
    columnField: {
      type: String,
      default: "title"
    },
    subTitleField: {
      type: String,
      default: null
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      source: [],
      tmpSource: [],
      filteredSource: [],
      searchSourceQuery: null,
      searchTargetQuery: null
    }
  },
  created() {
    this.source = this.data
    this.tmpSource = this.data
  },
  methods: {
    refresh(updateData) {
      this.searchSourceQuery = null;
      this.searchTargetQuery = null;
      this.source = updateData;
      this.tmpSource = updateData;

    },
    moveToTarget(item) {
      this.$emit('move-to-target', item)
      for (let movedItem of item.items) {
        let index = this.tmpSource[0].findIndex((item) => {
          return item.id === movedItem.id
        })
        this.tmpSource[0].splice(index, 1)
        this.tmpSource[1].push(movedItem)
        console.log(this.tmpSource[1])
      }
    },
    moveToSource(item) {
      this.$emit('move-to-source', item)
      console.log(item)
      for (let movedItem of item.items) {
        let index = this.tmpSource[1].findIndex((item) => {
          return item.id === movedItem.id
        })
        this.tmpSource[1].splice(index, 1)
        this.tmpSource[0].push(movedItem)
      }
    },
    searchSource() {
      if (this.searchSourceQuery) {
        let filteredAvailable = this.tmpSource[0].filter((item) => {
          let subTitleSearchResult, prefixFieldSearchResult = null
          if (this.subTitleField) {
            subTitleSearchResult = this.searchSourceQuery.toLowerCase().split(' ').every(v => item[this.subTitleField].toLowerCase().includes(v))
          }
          if (this.prefixField) {
            prefixFieldSearchResult = this.searchSourceQuery.toLowerCase().split(' ').every(v => item[this.prefixField].toLowerCase().includes(v))
          }
          return this.searchSourceQuery.toLowerCase().split(' ').every(v => item[this.columnField].toLowerCase().includes(v)) || subTitleSearchResult || prefixFieldSearchResult
        })
        this.source = [filteredAvailable, this.source[1]]
      } else {
        this.source = this.tmpSource
      }
    },
    searchTarget() {
      let subTitleSearchResult, prefixFieldSearchResult = null
      if (this.searchTargetQuery) {
        let filteredAvailable = this.tmpSource[1].filter((item) => {
          if (this.subTitleField) {
            subTitleSearchResult = this.searchTargetQuery.toLowerCase().split(' ').every(v => item[this.subTitleField].toLowerCase().includes(v))
          }
          if (this.prefixField) {
            prefixFieldSearchResult = this.searchTargetQuery.toLowerCase().split(' ').every(v => item[this.prefixField].toLowerCase().includes(v))
          }
          return this.searchTargetQuery.toLowerCase().split(' ').every(v => item[this.columnField].toLowerCase().includes(v)) || subTitleSearchResult || prefixFieldSearchResult
        })
        this.source = [this.source[0], filteredAvailable]
      } else {
        this.source = this.tmpSource
      }
    }
  },
  watch: {
    searchSourceQuery() {
      this.searchSource()
    },
    searchTargetQuery() {
      this.searchTarget()
    }
  }
}
</script>