<template>
  <div class="w-100">
    <portal to="content-header-right">
      <span class="text-success" v-if="competence.publish && $route.params.id">{{ $t('published') }}</span>
      <span class="text-danger" v-if="!competence.publish && $route.params.id">{{ $t('unpublished') }}</span>
    </portal>
    <div ref="invalidFields">
      <form>
        <div class="row">
          <div class="col-12 form-group" :class="{ 'form-group--error': $v.competence.title.$error }">
            <label for="title">{{ $t('Title') }}</label>
            <InputText id="title" type="text" v-model="competence.title"/>
          </div>
          <div class="error" v-if="!$v.competence.title.required">Pflichtfeld</div>
          <div class="error" v-if="!$v.competence.title.maxLength">
            {{ this.$t('Only {maxLength} Characters allowed', {maxLength: 255}) }}
          </div>


          <div class="col-12 form-group" :class="{ 'form-group--error': $v.competence.description.$error }">
            <label for="title">{{ $t('Description') }}</label>
            <Textarea id="description" type="text" v-model="competence.description" :auto-resize="true"/>
          </div>
          <div class="error" v-if="!$v.competence.description.required">Pflichtfeld</div>

          <div class="col-12 form-group">
            <label for="selected_category">{{ $t('Assignment category') }}</label>
            <PickerInput id="selected_category" @clearClick="clearSelected()"
                         :title="$t('Select Category')"
                         :selected="competence.competence_category.title"
                         :placeholder="$t('Select Category')"
                         v-model="competence.competence_category.id"
                         ref="picker"
            >
              <TreeList :nodes="this.categories"
                        @nodeSelected="categorySelected"
              ></TreeList>
            </PickerInput>
          </div>
          <div class="col-12 form-group" v-if="competence.competence_levels.length > 0">
            <Accordion>
              <AccordionTab :header="$t('Level definition')">
                <div class="" v-for="(competenceLevel, index) in competence.competence_levels"
                     :key="competenceLevel.level">
                  <div class='competence-title-container text-left pt-3'>
                    <span class='competence-title'>{{
                        $t('competences.levelTitles.level' + (index + 1) + '.title')
                      }}</span>
                    <span
                        class='competence-title-suffix'> {{
                        $t('competences.levelTitles.level' + (index + 1) + '.suffix')
                      }}</span>
                  </div>
                  <div>
                    <Editor v-model="competenceLevel.description" editorStyle="height: 200px" :formats="editorFormats">
                      <template #toolbar>
                      <span class="ql-formats">
                          <button class="ql-bold"></button>
                          <button class="ql-italic"></button>
                          <button class="ql-underline"></button>
                          <button class="ql-list" value="bullet"></button>
                      </span>
                      </template>
                    </Editor>
                  </div>

                </div>
              </AccordionTab>
            </Accordion>

          </div>


          <portal to="footer-actions">
            <FormActions>
              <template #left>
                <DangerButton v-if="competence.id" @click="confirmDelete"> {{ $t('delete') }}</DangerButton>
              </template>
              <SecondaryButton v-if="competence.id" @click="confirmDuplicate">{{
                  $t('Duplicate')
                }}
              </SecondaryButton>
              <SecondaryButton v-if="!competence.publish && competence.id" @click="togglePublishCompetence">{{
                  $t('publish')
                }}
              </SecondaryButton>
              <SecondaryButton v-if="competence.publish  && competence.id" @click="togglePublishCompetence">{{
                  $t('unpublish')
                }}
              </SecondaryButton>
              <PrimaryButton @click="saveCompetence">{{ $t('save') }}</PrimaryButton>
            </FormActions>
          </portal>
        </div>

      </form>
    </div>

    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: competence.title}) }}
      </template>
      <div class="confirmation-content">
        {{ $t('Do you really want to delete competence {title}?', {title: competence.title}) }}
      </div>
      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDelete">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DangerButton @click="deleteCompetence">{{ $t('delete') }}</DangerButton>
        </FormActions>
      </template>
    </Dialog>
    <Dialog :visible.sync="showConfirmDuplicate" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Duplicate {title}', {title: competence.title}) }}
      </template>
      <div class="confirmation-content">
        <form>

          <div class="col-12 form-group" :class="{ 'form-group--error': $v.duplicateTitle.$error }">
            <p>{{ $t('Enter title of duplicated competence:') }}</p>
            <InputText id="duplicated_title" type="text" v-model="duplicateTitle"/>
          </div>
          <div class="error" v-if="!$v.duplicateTitle.required">{{ $t('Required field') }}</div>

        </form>

      </div>

      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDuplicate">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <PrimaryButton @click="duplicateCompetence">{{ $t('Duplicate') }}</PrimaryButton>
        </FormActions>
      </template>
    </Dialog>
  </div>

</template>

<style>
#category_picker {
  width: 500px;
  height: 500px;
  z-index: 4;
}
</style>

<script>
import PickerInput from "@/components/PickerInput";
import {maxLength, required} from 'vuelidate/lib/validators';
import TreeList from "@/components/TreeList";
import Dialog from 'primevue/dialog';


export default {
  name: 'competences',
  props: {
    categories: {
      type: Array,
      default: null
    },
  },
  data() {
    let competenceLevels = [];
    for (let i = 0; i < 8; i++) {
      competenceLevels[i] = {
        level: i + 1,
        description: null
      }
    }
    return {
      competence: {
        id: null,
        title: null,
        description: null,
        competence_category: {
          id: null,
          title: null,
        },
        competence_levels: competenceLevels,
        publish: false
      },
      expandedKeys: {},
      searchQuery: null,
      editorFormats: [
        'bold',
        'italic',
        'underline',
        'list',
        'indent',
      ],
      showConfirmDelete: false,
      duplicateTitle: null,
      showConfirmDuplicate: false,
    }
  },
  created() {
    if (this.$route.params.id) {
      this.$http.get('competences/' + this.$route.params.id)
          .then((res) => {
            this.competence = {...res.data}
            this.duplicateTitle = this.competence.title + ' ' + this.$t('Copy')
            this.competence.publish = this.competence.publish === 1
            this.$http.get('competence-categories/' + this.competence.competence_category.id).then((res) => {
              this.competence.competence_category = res.data;
            });
            this.$http.get('competences/' + this.competence.id).then((res) => {
              if (res.data.competence_levels.length > 0) {
                this.competence.competence_levels = res.data.competence_levels;
              }
            });
          }).catch(() => {
        this.$router.push({name: 'backend.competences.competence'})
      })
    }
  },
  methods: {
    saveCompetence() {
      this.$v.competence.$touch();
      if (this.$v.competence.$invalid) {
        console.log(this.$v)
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          console.log(this.competence.competence_category.id)
          this.$http.put('competences/' + this.competence.id, {
            'title': this.competence.title,
            'description': this.competence.description,
            'competence_levels': this.competence.competence_levels,
            'publish': this.competence.publish,
            ...(this.competence.competence_category.id && {'competence_category_id': this.competence.competence_category.id}),
          })
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Competence updated successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getCategories')
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in updating competence'),
                  detail: error.message,
                  life: 3000,
                });
              });
        } else {
          this.$http.post('competences', {
            'title': this.competence.title,
            'description': this.competence.description,
            'competence_levels': this.competence.competence_levels,
            'publish': this.competence.publish,
            ...(this.competence.competence_category.id && {'competence_category_id': this.competence.competence_category.id}),
          })
              .then((res) => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Competence added successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getCategories').then(() => {
                  this.$router.push({name: 'backend.competences.competence', params: {id: res.data.id}})
                })

              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in adding competence'),
                  detail: error.message,
                  life: 3000,
                });
              });
        }
      }
    },
    togglePublishCompetence() {
      this.competence.publish = !this.competence.publish
      this.$http.put('competences/' + this.competence.id, {
        'title': this.competence.title,
        'description': this.competence.description,
        'competence_levels': this.competence.competence_levels,
        'publish': this.competence.publish,
        ...(this.competence.competence_category.id && {'competence_category_id': this.competence.competence_category.id})
      })
          .then(() => {
            if (this.competence.publish) {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Competence published successfully'),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Competence unpublished successfully'),
                life: 3000,
              });
            }

            this.$store.dispatch('getCategories')
          })
          .catch((error) => {
            this.$toast.add({
              severity: 'error',
              summary: this.$t('Error in updating competence'),
              detail: error.message,
              life: 3000,
            });
          });
    },
    categorySelected(category) {
      this.competence.competence_category = category;
      this.$refs.picker.closeDialog()
    },
    clearSelected() {
      this.competence.competence_category = {id: null}
    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    deleteCompetence() {
      this.$http.delete('competences/' + this.competence.id)
          .then((res) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t(res.data.message),
              life: 3000,
            });
            this.$router.push('/backend/competences/competence')
          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    confirmDuplicate() {
      this.showConfirmDuplicate = !this.showConfirmDuplicate
    },
    duplicateCompetence() {
      this.$v.duplicateTitle.$touch();
      if (this.$v.duplicateTitle.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$http.post('competences/' + this.competence.id + '/duplicate', {
          'title': this.duplicateTitle
        })
            .then((res) => {
              this.showConfirmDuplicate = false;
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Competence duplicated successfully'),
                life: 3000,
              });
              this.$router.push('/backend/competences/competence/' + res.data.id)
            })
            .catch((error) => {
              this.showConfirmDuplicate = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      }
    },
  },
  components: {
    TreeList,
    PickerInput,
    Dialog
  },
  validations: {
    competence: {
      title: {
        required,
        maxLength: maxLength(255)
      },
      description: {
        required,
      },
    },
     duplicateTitle: {
      required
    },
  },
}
</script>