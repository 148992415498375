<template>

    <SideNavigationLayout>
      <template #sidenav-header>
        <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addJobRoleGroup">
          {{ $t('Job role group') }} <span class="font-weight-lighter">{{ $t('add') }}</span>
        </button>
        <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addJobRole">
          {{ $tc('Job role') }} <span class="font-weight-lighter">{{ $t('add') }}</span>
        </button>
      </template>
      <template #sidenav>
        <TreeList class="job-role-navigation"
                  :nodes="jobRoleGroups"
                  :column-field="'name'"
                  :show-sub-nodes="true"
                  :custom-node-key="'jobRoles'"
                  :custom-column-field="'title'"
                  @nodeSelected="jobRoleGroupSelected"
                  @customNodeSelected="jobRoleSelected"
                  routeName="backend.job-role-groups.job-role-group"
                  subRouteName="backend.job-role-groups.job-role"
        ></TreeList>
      </template>

      <template #content-header>
        <span v-if="$route.name === 'backend.job-role-groups.job-role-group'">
          {{ $t('Job role group') }}
        </span>
        <span v-if="$route.name === 'backend.job-role-groups.job-role'">
           {{ $tc('Job role') }}
        </span>
         <span v-if="$route.name === 'backend.job-role-groups.job-role.competence'">
           {{ $tc('Competence') }}
        </span>

      </template>
      <template #content-header-suffix>
        <span v-if="$route.params.id">
          {{ $t('edit') }}
        </span>
        <span v-if="!$route.params.id"
        >
          {{ $t('add') }}
        </span>
      </template>
      <template #content-header-right>
        <portal-target name="content-header-right"></portal-target>
      </template>
      <template #content>
        <router-view :key="$route.path"/>
      </template>
      <template #content-footer>
        <portal-target name="footer-actions"></portal-target>
      </template>
    </SideNavigationLayout>
</template>
<script>
import TreeList from "@/components/TreeList";
import SideNavigationLayout from "@/views/backend/SideNavigationLayout";

export default {
  name: 'job-roles',
  created() {
    this.getJobRoleGroups();
  },
  methods: {
    addJobRoleGroup() {
      this.$router.push({name: 'backend.job-role-groups.job-role-group'});
    },
    addJobRole() {
      this.$router.push({name: 'backend.job-role-groups.job-role'});
    },
    getJobRoleGroups() {
      this.loading = true;
      this.$store.dispatch('getJobRoleGroups')
    },
    jobRoleGroupSelected(jobRoleGroup) {
      this.$router.push({name: 'backend.job-role-groups.job-role-group', params: {id: jobRoleGroup.id}});
    },
    jobRoleSelected(jobRole) {
      this.$router.push({name: 'backend.job-role-groups.job-role', params: {id: jobRole.id}});
    },
  },
  computed: {
    jobRoleGroups() {
      console.log(this.$store.state.jobRoleGroups)
      return this.$store.state.jobRoleGroups
    }
  },
  components: {
    TreeList,
    SideNavigationLayout,
  },
}
</script>
