<template>
  <div class="messages page-content d-flex flex-column flex-grow-1" v-if="message">
       <h5 class="text-center mb-3 page-title-flex"><v-clamp autoresize :max-lines="2">{{ message.title }}</v-clamp></h5>
    <div class="container content text-center">
      <div class="row ">
        <div class="col-sm-12">
         
          <div class="text-left">
            {{ message.content }}
          </div>
          <div class="text-right">
            <small>{{ message.date }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'message-detail',
  data() {
    return {
      message: null,
    };
  },
  created() {
         this.$store.commit('setLoading', 'loading');
    this.$http.get('messages/' + Number(this.$route.params.messageId)).then(res => {
      this.message = res.data;
           this.$store.commit('setLoading', 'success');
    });
  },
};
</script>
