<template>
  <div class="form-actions d-flex flex-row justify-content-center form-actions flex-fill">
    <div class="d-none d-md-flex">
      <slot name="left"></slot>
    </div>
    <div class="d-flex flex-grow-1"></div>
    <div class="d-flex">
      <div class="d-flex d-md-none">
        <slot name="left"></slot>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<style lang="scss">

.form-actions {
  .btn {
    margin-right: 1em;
  }
}

@media screen and (max-width: 1450px) {
  .form-actions.d-flex.flex-row.justify-content-center.form-actions.flex-fill > * {
    flex-direction: column !important;

    .btn {
      margin: 0.5em 0;
    }
  }
}
</style>
<script>

export default {
  name: 'formActions',
}
</script>