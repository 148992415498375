<template>
    <div class="d-flex ml-0 pl-0 flex-row flex-grow-1 h-100 w-100">
      <div class="w-20 bg-dark overflow-auto d-flex flex-column">
        <div class="sidenav-header d-flex flex-column align-items-center">
          <slot name="sidenav-header"></slot>
        </div>
        <div class="sidenav-content d-flex p-4 pt-0 flex-fill overflow-auto">
          <slot name="sidenav"></slot>
        </div>
        <div class="sidenav-footer d-flex flex-column">
          <slot name="sidenav-footer"></slot>
        </div>
      </div>
      <div
          class="d-flex flex-column w-75 ml-0 pl-0">
        <div class="content-header-wrapper py-2 px-4 d-flex bg-light text-primary">
          <div class="d-flex flex-row justify-content-center flex-fill ">
             <div class="font-weight-bolder d-flex">
                <slot name="content-header"></slot>
               </div>
            <div class="font-weight-lighter ml-2 d-flex">
              <slot name="content-header-suffix"></slot>
          </div>
            <div class="d-flex flex-grow-1"></div>
            <div class="d-flex align-items-center small">
              <slot name="content-header-right"></slot>
            </div>

          </div>
        </div>
        <div class="container d-flex flex-fill overflow-auto">
          <slot name="content"></slot>
        </div>
        <div class="container content-footer d-flex p-4 ">
          <slot name="content-footer"></slot>
        </div>
      </div>
    </div>

</template>
<style lang="scss">


.content-header-wrapper {
  border-top-right-radius: 3em;
  border-bottom-right-radius: 3em;
  font-size: x-large;
}

.sidenav-header .btn-block {
  max-width: 250px;
}

.content-footer {
  .btn-danger {
    float: left;
  }

  .btn {
    float: right;
  }
}
</style>
<script>

export default {
  name: 'sideNavLayout',
}
</script>