<template>
  <div class="page-content-pb d-flex flex-column flex-grow-1 assessment-detail">
    <div class="UpperText container text-container">
      <h5 class="text-center page-title-flex text-white text-uppercase">{{ this.currentCompetence.title }}</h5>
        <div class="pt-0 competence-title-description">{{ this.currentCompetence.description }}</div>
    </div>
    <h5 class="text-center mb-5 text-uppercase">{{$t('commonAssessment.currentQuestion')}}</h5>
    <div class="Slider container pl-5 pr-5 mt-5 mb-5 sliders w-75">
       <div class="prepend-line single"></div>
      <v-slider
        class="custom-slider common-slider"
        v-model="commonValue"
        min="0"
        max="8"
        @start="commonDragStart"
        @end="commonDragEnd"
        steps="7"
        color="#dc8e00"
        track-color="#C3C8CC"
        thumb-size="64"
      ></v-slider>
      <v-slider
        :disabled="true"
        class="custom-slider self-slider inactive"
        v-model="selfAbilityValue"
        min="0"
        max="8"
        steps="7"
        color="#dc8e00"
        thumb-size="64"
        v-if="selfAbilityValue>0"
        :thumb-color="(abilityActive)?'#dc8e00':'#C3C8CC'"
      >
        <template v-slot:thumb-label>
          <img class="selfassessment-tooltip-icon" src="@/assets/icons/koennen-large.svg"  alt="koennen-large"/>
        </template>
        <template v-slot:prepend>
          <div class="prepend-indicator">
            <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50 0, 65 200, 35 200" />
            </svg>
            <span class="label">{{$t('novice')}}</span>
          </div>
        </template>
        <template v-slot:append>
          <div class="append-indicator">
            <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50 0, 65 200, 35 200" />
            </svg>
            <span class="label">{{$t('expert')}}</span>
          </div>
        </template>
      </v-slider>
      <v-slider
        :disabled="true"
        class="custom-slider public-slider inactive"
        v-model="publicAbilityValue"
        min="0"
        max="8"
        steps="7"
        color="#dc8e00"
        thumb-size="64"
        v-if="publicAbilityValue>0"
        :thumb-color="(claimActive)?'#dc8e00':'#C3C8CC'"
      ></v-slider>
    </div>
    <div class="mt-5 container" v-if="this.currentCompetence.competenceLevels">
     <div v-if="commonValue <= 0">
        <div class="competence-title-container">
          <span class="competence-title text-uppercase">{{$t('ability')}}</span>
        </div>
      </div>
      <div class='competence-title-container' v-if="commonValue>0">
          <span class='competence-title'>{{$t('competences.levelTitles.level'+(commonValue)+'.title')}}</span>
          <span class='competence-title-suffix'> {{$t('competences.levelTitles.level'+(commonValue)+'.suffix')}}</span>
        </div>
    </div>
      <div class="overflow-auto container  text-container" v-if="this.currentCompetence.competenceLevels">
        <span
          v-if="commonValue > 0" class="competence-level-description"
        >{{ this.currentCompetence.competenceLevels[commonValue].description }}</span>
         <span class="competence-level-description"
          v-if="commonValue <= 0"
        >{{ this.currentCompetence.competenceLevels[0].description }}</span>
      </div>
      <div class="bottom-container d-flex flex-column container">
        <button
          class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
          @click="backClicked"
        >{{$t('toSummary')}}</button>
        <!-- <small
        class="text-right"
        >Kompetenz {{ this.currentStep + 1 }} von {{ this.employee.competences.length }}</small>-->
      </div>
    </div>

</template>
<style lang="scss">
.assessment-detail .public-slider .v-slider__track-container, .assessment-detail .self-slider .v-slider__track-container{
  display:none !important;
}
.competence-title-description{
  color: $font-light;
}
.p-slider-horizontal,
.p-inputtext {
  width: 100%;
}

.p-slider-vertical {
  height: 14em;
}
.page-counter {
  position: absolute;
  right: 1em;
  bottom: 1em;
}
.custom-dot {
  width: 100%;
  height: 100%;
}
.custom-dot-abilityability {
  transition: translateY(-20%);
  position: relative;
  .indicator {
    color: $gray;
    position: absolute;
    bottom: -1em;
    font-weight: bold;
    left: 0;
    right: 0;
  }
}
.custom-mark-1 {
  position: absolute;
  top: 10px;
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}
.custom-mark-2 {
  position: absolute;
  top: 10px;
  transform: translateX(-100%);
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}
.description-content {
  max-height: 40vh;
  overflow: auto;
}
</style>
<script>
export default {
  name: 'assessment-detail',
  components: {},
  data() {
    return {
      employee: this.$store.getters.employee,
      currentCompetence: {},
      data: [],
      publicAbilityValue: 0,
      selfAbilityValue: 0,
      commonValue: 0,
      active: 'ability',
      claimActive: false,
      abilityActive: false,
    };
  },
  created: function() {
    console.log(this.currentCompetence.competenceLevels);
    this.getCompetence();
  },
  methods: {
    endClicked() {
      this.$router.go(-1);
    },
    getCompetence() {
      this.$http
        .get('/competences/' + this.$route.params.competenceId)
        .then(res => {
          this.currentCompetence = res.data;
          console.log("currente competence ", this.currentCompetence)
          this.data = this.currentCompetence.competenceLevels.map(res => {
            return res.level - 1;
          });
          this.data.push(this.data.length);
          this.currentCompetence.competenceLevels.unshift(
            {
              title: 'Können AB',
              description:
                'Wir haben euch Selbstbild (SB) und Fremdbild (FB) gegenüber gestellt. Bitte verschiebt nun den Regler um einen gemeinsamen Startpunkt (AB) festzulegen.',
            },
          );
          console.log("COMPETENCELEVELS ", this.currentCompetence.competenceLevels)
          this.publicAbilityValue = this.employee.competences.find(
            competence => {
              return competence.id === this.$route.params.competenceId;
            },
          ).publicAbility;
          this.selfAbilityValue = this.employee.competences.find(competence => {
            return competence.id === this.$route.params.competenceId;
          }).selfAbility;
          console.log('Ability Value ', this.employee.competences);
        });
    },
    commonDragStart() {
      this.claimActive = true;
      this.abilityActive = false;
      this.active = 'claim';
      console.log('claim value ', this.abilityActive);
    },
    commonDragEnd() {
      console.log(this.commonValue)
      this.$store
        .dispatch('postJobCompetence', {
          user: this.employee,
          competenceId: this.competenceId,
          commonValue: this.commonValue,
        })
        .then(() => {
          console.log('successfully posted claim');
        })
        .catch(err => {
          console.log('error posting claim ', err);
        });
    },
    backClicked(){
      this.$router.go(-1);
    }
  },
};
</script>
