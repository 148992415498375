<template>
  <div>
    <div class="activity-progress-bar-title row">
      <div class="col-12 text-center font-weight-light mb-3 text-white">
        {{$t('selfAssessment')}}  {{Math.round(progress)}}%  {{$t('finished')}}
      </div>
    </div>
    <div class="activity-progress-bar row">
      <div class="col-2 pl-1 pr-1 offset-1">
        <ProgressBar :value="(totalProgress>0)?totalProgress:0" :showValue="false" />
      </div>
      <div class="col-2 pl-1 pr-1">
        <ProgressBar :value="(totalProgress-100>0)?totalProgress-100:0" :showValue="false" />
      </div>
      <div class="col-2 pl-1 pr-1">
        <ProgressBar :value="(totalProgress-200>0)?totalProgress-200:0" :showValue="false" />
      </div>
      <div class="col-2 pl-1 pr-1">
        <ProgressBar :value="(totalProgress-300>0)?totalProgress-300:0" :showValue="false" />
      </div>
      <div class="col-2 pl-1 pr-1">
        <ProgressBar :value="(totalProgress-400>0)?totalProgress-400:0" :showValue="false" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'activityProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    language: {
      type: String,
      default: 'de',
    },
  },
  computed: {
    totalProgress(){
      return this.progress*5;
    }
  },
};
</script>
