<template>
  <div class="w-100">
    <form @submit.prevent="saveJobRole">
      <div class="row">
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.jobRole.title.$error }">
          <label for="title">{{ $t('Title') }}</label>
          <InputText id="title" type="text" v-model="jobRole.title"/>
        </div>
        <div class="error" v-if="!$v.jobRole.title.required">{{ $t('Required field') }}</div>

        <div class="col-12 form-group">
          <label for="hr-group">{{ $t('Job role group') }}</label>
          <PickerInput id="hr-group"
                       :title="$t('Job role group')"
                       :placeholder="$t('Select job role group')"
                       :selected="selectedJobRoleGroup.name"
                       :show-clear="false"
                       ref="job-role-group-picker">
            <TreeList :nodes="this.jobRoleGroups"
                      :column-field="'name'"
                      :show-sub-nodes="true"
                      @nodeSelected="jobRoleGroupSelected"
            ></TreeList>
          </PickerInput>
        </div>

        <div class="col-12 form-group" v-if="$route.params.id && tags">
          <label>{{ $t('Tags') }}</label>
          <CustomPickList :data="tags"
                          :column-field="'name'"
                          @move-to-target="addTag"
                          @move-to-source="removeTag"></CustomPickList>

        </div>

        <div class="col-12 form-group" v-if="$route.params.id && jobRole.competences.length > 0">
          <label for="job-role-competence">{{ $t('Comparison job role') }}</label>
          <PickerInput id="job-role-compare"
                       :title="$t('Select job role to compare')"
                       :placeholder="$t('Select job role')"
                       :selected="selectedCompareJobRole.title"
                       @clearClick="clearCompare"
                       @dialogOpened="clearCompare"
                       ref="compare-picker">
            <TreeList :nodes="this.jobRoles"
                      @nodeSelected="compareJobRoleSelected"
            ></TreeList>
          </PickerInput>
        </div>


        <div class="col-12 form-group" :class="{ 'form-group--error': $v.jobRole.title.$error }"
             v-if="$route.params.id">
          <div>
            <div class="d-flex align-items-center mb-2">
              <label class="flex-grow-1">{{ $t('Includes competences') }}</label>
              <small>{{ $t('Table input') }}</small>
              <InputSwitch v-model="tableInput" class="mx-2" @change="tableInputChanged"/>
            </div>
            <DataTable v-if="tableInput" :value="jobRole.competences" editMode="cell"
                       @cell-edit-complete="competenceCellChanged"
                       class="competences-table"
                       :autoLayout="true"
                       :filters="filters"
                       :paginator="true"
                       :rows="10"
            >
              <template #empty>
                {{ $t('No competences found') }}
              </template>
              <template #header>
                <div class="table-header d-flex align-items-center">
                  <span class="p-input-icon-left flex-grow-1">
                      <IconInput
                          v-model="filters['global'].value" :placeholder="$t('Search')"
                          type="text"
                          icon="pi pi-search"
                          :clear="true"
                      />
                  </span>
                </div>
              </template>
              <Column field="title" :header="$tc('Competence')" sortable :key="'1'">
                <template #body="slotProps">
                  <div class="competence-row" @click="showCompetenceDescriptionPopup(slotProps.data)">
                     <span
                         v-if="slotProps.data.category_color"
                         class="category-indicator"
                         :style="'background:#'+slotProps.data.category_color"
                     ></span>
                    <div class="competence-row-content">
                      <span class="d-block" style="font-weight: 300">{{ slotProps.data.category_title }}</span>
                      <strong>{{ slotProps.data.title }}</strong>
                    </div>

                  </div>

                </template>
              </Column>
              <Column field="job_ability" :header="$t('Level')" :styles="{width: '100px'}" :key="'2'">
                <template #editor="slotProps">
                  <InputText type="number" min="1" max="8" class="competence-input"
                             v-model="slotProps.data.job_ability"
                             />
                </template>
                <template #body="slotProps">
                <span style="pointer-events: none"
                      :class="{'invalid-competence':slotProps.data.job_ability > 8||slotProps.data.job_ability < 1}">
                  {{ slotProps.data.job_ability }}
                </span>
                </template>
              </Column>
              <Column headerStyle="width: 4em" v-if="!selectedCompareJobRole.competences" :key="'3'">
                <template #body="slotProps">
                  <div class="text-center">
                    <a @click="confirmRemove(slotProps.data, $event)"><i class="pi pi-trash"></i></a>
                  </div>
                </template>
              </Column>
              <Column field="comparison" :header="$t('Comparison')"
                      headerStyle="width: 4em"
                      bodyStyle="text-align:center"
                      v-if="selectedCompareJobRole.competences" :key="'4'"
              >
                <template #body="slotProps">
                  <span class="d-none">{{ slotProps.data.title }}</span>{{ slotProps.data.comparison }}
                </template>
              </Column>
              <Column headerStyle="width: 4em" v-if="selectedCompareJobRole.competences" :key="'5'">
                <template #body="slotProps">
                  <div class="text-center">
                    <a @click="confirmRemove(slotProps.data, $event)"><i class="pi pi-trash"></i></a>
                  </div>
                </template>
              </Column>
            </DataTable>
            <CompetenceGrid :competences="jobRole.competences" v-if="!tableInput"
                            :show-job-abilities="true"
                            :show-current-job="true"
                            @competenceSelected="competenceSelected"
                            :expanded="true"
            ></CompetenceGrid>
          </div>

        </div>

        <div class="col-12 form-group" v-if="$route.params.id">
          <label for="job-role-competence">{{ $t('Add Competence') }}</label>
          <PickerInput id="job-role-competence" @click="toggle()"
                       :title="$t('Select Competence')"
                       :placeholder="$t('Select Competence')"
                       ref="picker">
            <TreeList :nodes="this.remainingCompetences"
                      :show-sub-nodes="true"
                      :custom-node-key="'competences'"
                      :node-selectable="false"
                      @customNodeSelected="newCompetenceSelected"
            ></TreeList>
          </PickerInput>
        </div>
        <portal to="footer-actions">
          <FormActions>
            <template #left>
              <DangerButton v-if="jobRole.id" @click="confirmDelete"> {{ $t('delete') }}</DangerButton>
            </template>
            <SecondaryButton v-if="jobRole.id" @click="confirmDuplicate">{{
                $t('Duplicate')
              }}
            </SecondaryButton>
            <PrimaryButton @click="saveJobRole">{{ $t('save') }}</PrimaryButton>
          </FormActions>

        </portal>
      </div>
    </form>
    <Dialog :visible.sync="showConfirmRemove" :style="{width: '350px'}" :modal="true" v-if="selectedCompetence">
      <template #header>
        {{ $t('Confirm Remove {title}', {title: selectedCompetence.title}) }}
      </template>
      <div class="confirmation-content">
        <p>{{ $t('Do you really want to remove competence {title}?', {title: selectedCompetence.title}) }}</p>
      </div>
      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="showConfirmRemove=false"> {{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DangerButton @click="removeCompetence"> {{ $t('remove') }}</DangerButton>
        </FormActions>
      </template>
    </Dialog>
    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: jobRole.title}) }}
      </template>
      <div class="confirmation-content">
        <p>{{ $t('Do you really want to delete job role {title}?', {title: jobRole.title}) }}</p>
      </div>

      <div v-if="this.jobRole.jobPositions.length>0">
        <p class="mb-0" v-if="this.jobRole.jobPositions.length===1">{{
            $t('The job role can not be deleted, because it is linked to the following job position:')
          }}</p>
        <p class="mb-0" v-if="this.jobRole.jobPositions.length>1">{{
            $t('The job role can not be deleted, because it is linked to the following job positions:')
          }}</p>
        <div v-for="jobPosition in this.jobRole.jobPositions"
             :key="jobPosition.id">
          <a class="category-title" @click="jobPositionSelected(jobPosition)">{{ jobPosition.title }}</a>
        </div>
      </div>

      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDelete">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DangerButton v-if="jobRole.jobPositions.length<=0" @click="deleteJobRole">{{ $t('delete') }}</DangerButton>
        </FormActions>
      </template>
    </Dialog>
    <Dialog :visible.sync="showConfirmDuplicate" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Duplicate {title}', {title: jobRole.title}) }}
      </template>
      <div class="confirmation-content">
        <form>

          <div class="col-12 form-group" :class="{ 'form-group--error': $v.duplicateTitle.$error }">
            <p>{{ $t('Enter title of duplicated job role:') }}</p>
            <InputText id="duplicated_title" type="text" v-model="duplicateTitle"/>
          </div>
          <div class="error" v-if="!$v.duplicateTitle.required">{{ $t('Required field') }}</div>

        </form>

      </div>

      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDuplicate">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <PrimaryButton @click="duplicateJobRole">{{ $t('Duplicate') }}</PrimaryButton>
        </FormActions>
      </template>
    </Dialog>
    <Dialog :visible.sync="showCompetenceDescription" :style="{width: '350px'}" :modal="true" v-if="selectedCompetence">
      <template #header>
        {{ selectedCompetence.title }}
      </template>
      <div class="confirmation-content">
        {{ selectedCompetence.description }}
      </div>
    </Dialog>
  </div>
</template>

<style lang="scss">
.competence-input {
  max-width: 120px;
}

.competences-table tr {
  cursor: pointer;


  .competence-row {
    position: relative;
    padding: 0;

    .competence-row-content {
      margin-left: 10px;
    }
  }

  .invalid-competence {
    color: $error;
    font-weight: bold;
  }
}

body .p-datatable.competences-table tr:hover {
  background: rgba($primary, 0.4) !important;
}

</style>

<script>
import {required} from 'vuelidate/lib/validators';
import PickerInput from "@/components/PickerInput";
import TreeList from "@/components/TreeList";
import CustomPickList from "@/components/CustomPickList";
import CompetenceGrid from "@/components/CompetenceGrid";
import {FilterMatchMode} from "primevue/api";
import IconInput from "../../components/IconInput.vue";

export default {
  name: 'job-roles',
  data() {
    return {
      jobRole: {
        id: null,
        title: null,
        jobPositions: [],
        competences: [{
          comparison: null
        }]
      },
      selectedJobRoleGroup: {},
      remainingCompetences: [],
      showConfirmDelete: false,
      showConfirmRemove: false,
      showConfirmDuplicate: false,
      deleteConfirmation: null,
      selectedCompetence: null,
      selectedCompareJobRole: {
        title: null
      },
      tags: null,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      duplicateTitle: null,
      tableInput: true,
      showCompetenceDescription: false
    }
  },
  created() {
    this.getJobRole()
    this.getJobRoleGroups()
    this.getRemainingCompetences()
  },
  methods: {
    getJobRole() {
      if (this.$route.params.id) {
        this.$http.get('job-roles/' + this.$route.params.id)
            .then((res) => {
              this.jobRole = {...res.data}
              this.duplicateTitle = this.jobRole.title + ' ' + this.$t('Copy')
              this.$store.dispatch('getCompareJobRole').then(() => {
                if (this.$store.state.compareJobRole) {
                  this.selectedCompareJobRole = this.$store.state.compareJobRole
                  this.setCompareValues()
                }
              })
              this.selectedJobRoleGroup = {...res.data.jobRoleGroup}
              this.getTags()
            }).catch(() => {
          this.$router.push({name: 'backend.job-role-groups.job-role'})
        })
      }
    },
    getJobRoleGroups() {
      this.$store.dispatch('getJobRoleGroups')
    },
    getRemainingCompetences() {
      if (this.$route.params.id) {
        this.$http.get('job-roles/' + this.$route.params.id + '/remaining-competences')
            .then((res) => {
              this.remainingCompetences = res.data
            })
        this.loading = false
      }
    },
    getTags() {
      this.$store.dispatch('getTags').then(() => {
        this.tags = [this.$store.state.tags.filter((tag) => {
          return !this.jobRole.tags.some((jobRoleTag) => {
            return tag.id === jobRoleTag.id
          })
        }), this.jobRole.tags]
      })
    },
    competenceSelected(competence) {
      this.$refs.picker.closeDialog();
      localStorage.setItem('selectedJobRole', JSON.stringify(this.jobRole))
      localStorage.setItem('jobRoleAbility', JSON.stringify(competence.job_ability))
      this.$router.push({name: 'backend.job-role-groups.job-role.competence', params: {id: competence.id}});
    },
    newCompetenceSelected(competence) {
      this.$refs.picker.closeDialog();
      localStorage.setItem('selectedJobRole', JSON.stringify(this.jobRole))
      localStorage.setItem('jobRoleAbility', null)
      this.$router.push({
        name: 'backend.job-role-groups.job-role.competence',
        params: {id: competence.id, compareJobRole: true}
      });
    },
    saveJobRole() {
      this.$v.jobRole.$touch();
      if (this.$v.jobRole.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          this.jobRole.job_role_group_id = this.selectedJobRoleGroup.id
          this.$http.put('job-roles/' + this.$route.params.id, this.jobRole)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Job role updated successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getJobRoleGroups')
              })
              .catch((error) => {
                this.$helper.showErrorToast(error, this.$t('Error in updating'))
              });
        } else {
          this.jobRole.job_role_group_id = this.selectedJobRoleGroup.id
          this.$http.post('job-roles', this.jobRole)
              .then((res) => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('JobRole created successfully'),
                  life: 3000,
                });
                this.jobRole.title = ""
                this.$v.$reset();
                this.$store.dispatch('getJobRoleGroups')
                this.$router.push({name: 'backend.job-role-groups.job-role', params: {id: res.data.id}});
              })
              .catch((error) => {
                this.$helper.showErrorToast(error, this.$t('Error in adding'))
              });
        }

      }
    },
    competenceCellChanged(event){
      let { data, newValue, field } = event;
       if (newValue > 0 && newValue < 9) {
         data[field] = newValue;
        this.$http.put('job-role-competence', {
          'jobRoleId': this.jobRole.id,
          'competenceId': data.id,
          'jobAbility': newValue,
        }).then(() => {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Competence level successfully updated'),
            life: 3000,
          });
        })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      } else {
        this.$toast.add({
          severity: 'info',
          summary: this.$t('Competence level has to be between 1-8'),
          life: 3000,
        });
      }




    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    confirmRemove(competence, event) {
      event.preventDefault()
      this.selectedCompetence = competence;
      this.showConfirmRemove = !this.showConfirmRemove
    },
    confirmDuplicate() {
      this.showConfirmDuplicate = !this.showConfirmDuplicate
    },
    duplicateJobRole() {
      this.$v.duplicateTitle.$touch();
      if (this.$v.duplicateTitle.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$http.post('job-roles/' + this.jobRole.id + '/duplicate', {
          'title': this.duplicateTitle
        })
            .then((res) => {
              this.showConfirmDuplicate = false;
              this.$toast.add({
                severity: 'success',
                summary: this.$t('JobRole successfully duplicated'),
                life: 3000,
              });
              this.$router.push('/backend/job-role-groups/job-role/' + res.data.id)
            })
            .catch((error) => {
              this.showConfirmDuplicate = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.data.message),
                life: 3000,
              });
            });
      }
    },
    deleteJobRole() {
      this.$http.delete('job-roles/' + this.jobRole.id)
          .then(() => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$tc('JobRole successfully deleted',1),
              life: 3000,
            });
            this.$router.push('/backend/job-role-groups/job-role')
          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    removeCompetence() {
      this.$http.delete('job-role-competence/' + this.jobRole.id + '/' + this.selectedCompetence.id)
          .then(() => {
            this.showConfirmRemove = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$tc('Competence successfully removed', 1),
              life: 3000,
            });
            this.getJobRole()
            this.getRemainingCompetences()
          })
          .catch((error) => {
            this.showConfirmRemove = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.message),
              life: 3000,
            });
          });

    },
    compareJobRoleSelected(compareJobRole) {
      localStorage.setItem('compareJobRole', JSON.stringify(compareJobRole))
      this.selectedCompareJobRole = compareJobRole
      this.setCompareValues()
      this.$refs["compare-picker"].closeDialog()
    },
    setCompareValues() {
      if (this.selectedCompareJobRole) {
        for (let competence of this.jobRole.competences) {
          competence.comparison = null
          for (let compareCompetence of this.selectedCompareJobRole.competences) {
            if (competence.id === compareCompetence.id) {
              competence.comparison = compareCompetence.job_ability
            }
          }
        }
      }
    },
    clearCompare() {
      localStorage.removeItem('compareJobRole')
      this.selectedCompareJobRole = {}
      this.jobRole.competences.map((competence) => {
        competence.comparison = null
      })
    },
    addTag(tag) {
      for (let item of tag.items) {
        this.$http.post('job-role-tag', {'jobRoleId': this.jobRole.id, 'tagId': item.id})
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Tag successfully added'),
                life: 3000,
              });
              this.$store.dispatch('getJobRoles')
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
    },
    removeTag(tag) {
      console.log("remove")
      for (let item of tag.items) {
        this.$http.delete('job-role-tag/' + this.jobRole.id + '/' + item.id)
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Tag successfully removed'),
                life: 3000,
              });
              this.$store.dispatch('getJobRoles')
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
    },
    jobPositionSelected(jobPosition) {
      this.$router.push({name: 'backend.job-positions.job-position', params: {id: jobPosition.id}});
    },
    tableInputChanged() {
      if (!this.tableInput) {
        this.getJobRole()
      }
    },
    showCompetenceDescriptionPopup(competence) {
      this.selectedCompetence = competence
      this.showCompetenceDescription = true
    },
    jobRoleGroupSelected(jobRoleGroup) {
      this.selectedJobRoleGroup = jobRoleGroup
      this.$refs["job-role-group-picker"].closeDialog()
    },
  },
  validations: {
    jobRole: {
      title: {
        required,
      },
    },
    duplicateTitle: {
      required
    },

  },
  components: {
    IconInput,
    PickerInput,
    TreeList,
    CustomPickList,
    CompetenceGrid
  },
  computed: {
    jobRoles() {
      return this.$store.state.jobRoles
    },
    jobRoleGroups() {
      return this.$store.state.jobRoleGroups
    }
  },
}
</script>