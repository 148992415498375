<template>
  <div class="w-100">
    <form @submit.prevent="saveTag">
      <div class="row">
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.tag.name.$error }">
          <label for="title">{{ $t('Title') }}</label>
          <InputText id="title" type="text" v-model="tag.name"/>
        </div>
        <div class="error" v-if="!$v.tag.name.required">Pflichtfeld</div>
        <div class="error" v-if="!$v.tag.name.maxLength">
          {{ this.$t('Only {maxLength} Characters allowed', {maxLength: 255}) }}
        </div>
        <portal to="footer-actions">
          <FormActions>
            <template #left>
              <DangerButton v-if="tag.id" @click="confirmDelete">  {{ $t('delete') }}</DangerButton>
            </template>
            <PrimaryButton @click="saveTag">{{ $t('save') }}</PrimaryButton>
          </FormActions>
        </portal>
      </div>

    </form>
    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: tag.name}) }}
      </template>
      <div class="confirmation-content">
        {{
          $t('Do you really want to delete tag {title}? It will be removed in all related job roles.', {title: tag.name})
        }}
      </div>
      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDelete">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DangerButton @click="deleteTag"> {{ $t('delete') }}</DangerButton>
        </FormActions>
      </template>
    </Dialog>
  </div>

</template>
<script>
import {maxLength, required} from 'vuelidate/lib/validators';
import Dialog from 'primevue/dialog';


export default {
  name: 'tags',
  data() {
    return {
      tag: {
        id: null,
        name: null,
      },
      showConfirmDelete: false
    }
  },
  created() {
    if (localStorage.getItem('selectedTag') && this.$route.params.id) {
      this.getTag()
    }
  },
  methods: {
    getTag() {
      if (this.$route.params.id) {
        this.$http.get('tags/' + this.$route.params.id)
            .then((res) => {
              this.tag = {...res.data}
            })
            .catch(() => {
              this.$router.push({name: 'backend.tags.tag'})
            })
      }
    },
    saveTag() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          this.$http.put('tags/' + this.tag.id, this.tag)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Tag updated successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getTags')
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in updating tag'),
                  detail: error.message,
                  life: 3000,
                });
              });
        } else {
          this.$http.post('tags', this.tag)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Tag added successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getTags')
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in adding tag'),
                  detail: error.message,
                  life: 3000,
                });
              });
        }
      }
    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    deleteTag() {
      this.$http.delete('tags/' + this.tag.id)
          .then(() => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t('Tag deleted successfully'),
              life: 3000,
            });
            this.$router.push('/backend/tags/tag')
          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    }
  },
  components: {
    Dialog
  },
  validations: {
    tag: {
      name: {
        required,
        maxLength: maxLength(255)
      },
    }
  },
}
</script>