<template>
  <div>
    <button class="btn btn-danger text-uppercase rounded-pill" @click="$emit('click')">
      <slot></slot>
    </button>
  </div>
</template>
<script>

export default {
  name: 'dangerButton',
}
</script>