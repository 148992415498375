<template>
  <div class="mx-auto">
    <h1 class="logo text-white text-center text-uppercase font-weight-bold align-middle page-title-home dashboard-title">
      Talent<span>eers</span> Backend
    </h1>
    <div class="container">
      <div class="row" v-if="user">
        <div class="col-sm-12 text-white text-center">
          <h1 class="dashboard-title">{{ $t('hello') }}, {{ this.user.first_name }}!</h1>
          <p style="font-weight:300" class="mb-0">
            <span v-for="(role, index) of this.user.roles" :key="role.name">{{ $t(role.name) }}<span
                v-if="user.roles.length>0 && index<user.roles.length-1"> | </span> </span>
          </p>
        </div>
        <div class="col-sm-12 text-center mb-3">
          <UserImage link="profile" :src="user.user_image"></UserImage>
        </div>
      </div>
      <div class="row">
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/competences/competence/info"
                       :title="$t('Competences')" @click="resetExpandedKeys"
                       :subtitle="(this.competencesCount)?this.competencesCount + ' ' + this.$t('published competences'):''"
        ></DashboardLink>
        <DashboardLink
            v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE])"
            to="/backend/assessments/"
            :title="$t('Assessments')" @click="resetExpandedKeys"
            :subtitle="(this.assessmentCount)?this.assessmentCount + ' ' + this.$t('planned assessments'):''"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/job-positions/job-position"
                       :title="$t('Job Positions')" @click="resetExpandedKeys"
        :subtitle="(this.freeJobPositionsCount)?this.freeJobPositionsCount + ' ' + this.$tc('free job positions', this.freeJobPositionsCount):''"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/job-role-groups/job-role"
                       :title="$t('Job Roles')" @click="resetExpandedKeys"
        :subtitle="(this.jobRolesCount)?this.jobRolesCount + ' ' + this.$t('Job roles'):''"></DashboardLink>
        <DashboardLink
            v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE])"
            to="/backend/hr-groups/user"
            :title="$t('Users')" @click="resetExpandedKeys"
        :subtitle="(this.jobRolesCount)?this.jobRolesCount + ' ' + this.$t('Users'):''"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/tags/tag"
                       :title="$t('Tags')" @click="resetExpandedKeys"
         :subtitle="(this.tagsCount)?this.tagsCount + ' ' + this.$t('Tags'):''"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('Reporting')"></DashboardLink>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.dashboard-title {
  font-size: 1.5rem;
}
</style>
<script>
import DashboardLink from '@/components/DasboardLink';
import UserImage from "@/components/UserImage";

export default {
  name: 'dashboard',
  created() {
    this.$store.dispatch('getUser', {})
    this.$http.get('backend_dashboard_data')
        .then((res)=>{
          this.competencesCount = res.data.competences
          this.assessmentCount = res.data.assessments
          this.freeJobPositionsCount = res.data.freeJobPositions
          this.jobRolesCount = res.data.jobRoles
          this.usersCount = res.data.users
          this.tagsCount = res.data.tags
        })
  },
  data() {
    return {
      competencesCount: 0,
      assessmentCount: 0,
      freeJobPositionsCount: 0,
      jobRolesCount: 0,
      usersCount: 0,
      tagsCount: 0,
    }
  },
  components: {
    DashboardLink,
    UserImage
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    resetExpandedKeys() {
      localStorage.setItem('expandedKeys', [])
    },
  }
};
</script>
