<template>
  <PageLayout>
    <template #back-navigation><span></span></template>
    <template #content-header>
      {{ $t('myCompetences') }}
    </template>
    <template #content>
      <div class="container">
        <CompetenceGrid
            :competences="myCompetences"
            @competenceSelected="competenceClicked"
            :show-claim="true"
            :show-ability="true"
            v-if="myCompetences.length>0"
            :title="$t('Competences')"
            :subtitle="user.first_name"
            :activeKey="'self_ability'"
        ></CompetenceGrid>
      </div>


      <Dialog v-if="selectedCompetence" header="Kompetenz hinzugefügt" :visible.sync="displayModal"
              :modal="true"
              :dismissableMask="true"
              v-on:hide="closeModal"

      >
        <span v-html="$t('competences.successfullyAddedMsg', { title: selectedCompetence.title })"></span>
        <template #footer>
          <div class="d-flex justify-center">
            <button class="btn btn-primary btn-block text-uppercase rounded-pill"
                    @click="competenceClicked(selectedCompetence)">{{ $t('rate') }}
            </button>
          </div>

        </template>
      </Dialog>
    </template>
    <template #content-footer>
      <div class="flex-column w-100">
        <div class="d-flex flex-row justify-content-around">
          <div class="v-center justify-content-center py-0 mb-4">
              <span>
                <img class src="@/assets/icons/polygon.svg"/>
              </span>
            <small class="mb-0 ml-2 text-uppercase">{{ $t('abilitySelf') }}</small>
          </div>
          <div class="v-center justify-content-center py-0 mb-4">
              <span>
                <img class src="@/assets/icons/circle-claim.svg"/>
              </span>
            <small class="mb-0 ml-2 text-uppercase">{{ $t('desireSelf') }}</small>
          </div>
        </div>
        <div class="mt-2" v-if="competences.length > 0">
          <label for="selected_category">{{ $t('additionalCompetences') }}</label>
          <PickerInput id="selected_category"
                       :title="$t('Select additional competence')"
                       :selected="selectedCompetence.title"
                       :placeholder="$t('Select additional competence')"
                       v-model="selectedCompetence.id"
                       ref="picker"
                       :showDialog="($route.params.showAddCompetence)"
          >
            <TreeList :nodes="competences"
                      :show-sub-nodes="true"
                      :custom-node-key="'competences'"
                      @customNodeSelected="addCompetenceClicked"
                      :node-selectable="false"
            ></TreeList>
          </PickerInput>
        </div>
      </div>

    </template>
  </PageLayout>

</template>
<style lang="scss">
tr > .disable-row {
  display: none;
}


.competence:not(:last-child) {
  border-bottom: 1px solid $text-primary;
}

.competence {
  position: relative;
  padding: 0.3em 0;
  color: $text-primary;
}

.info-button {
  position: absolute;
  right: 0;
}

.competence-description {
  z-index: 5;
  top: 1.3em !important;
  left: 0 !important;
}

.p-overlaypanel .p-component .competence-description {
  top: 2em !important;
}

.add-competence-container {
  position: relative;

  .vs__selected {
    word-break: break-all !important;
  }

  .vs__search {
    font-size: 0.9em;

  }

  .add-competence-icon {
    position: absolute;
    top: 1.2em;
    right: 1.3em;
  }
}

.page-content-assessment {
  max-height: 70vh !important;
}

@media screen and (max-height: $break-large) {
  .page-content-assessment {
    max-height: 65vh !important;
  }

}
</style>
<script>
import CompetenceGrid from '@/components/CompetenceGrid.vue';
import PickerInput from "@/components/PickerInput";
import TreeList from "@/components/TreeList";
import PageLayout from "../backend/PageLayout.vue";

export default {
  name: 'home',
  data() {
    return {
      user: null,
      competences: [],
      myCompetences: [],
      selectedCompetence: {
        id: null,
        title: null
      },
      expanded: false,
      expandedInner: true,
      displayModal: false,
    };
  },
  created() {
    this.$store.dispatch('getUser', {}).then((res) => {
      this.user = res.data;
      this.getCompetences();
    })
  },
  methods: {
    getCompetences() {
      this.$http.get('/remaining-competences', {}).then((res) => {
        this.competences = res.data;
      })
      this.$http.get('/user-competences/' + this.user.id, {}).then((res) => {
        this.myCompetences = res.data;
      });
    },
    changeCompetence(competence) {
      if (competence) {
        this.selectedCompetence = competence;
      }
    },
    addCompetenceClicked(competence) {
      this.selectedCompetence = competence
      this.$store.dispatch('addCompetence', competence.id).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Kompetenz hinzugefügt',
          detail: 'Kompetenz erfolgreich hinzugefügt',
          life: 3000,
        });
        this.$store.dispatch('getUser', {}).then((res) => {
          this.user = res.data;
          this.getCompetences();
        })
        this.$refs.picker.closeDialog()
        this.openModal();
      });
    },
    competenceClicked(competence) {
      let stepId = this.myCompetences.findIndex(userCompetence => {
        return userCompetence.id === competence.id;
      });
      if (stepId >= 0) {
        this.$router.push({name: 'competence-process', params: {stepId: stepId}});
      }
    },
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.selectedCompetence = {};
      this.competences = [];
      this.displayModal = false;
      this.getCompetences();
      location.reload();
    },
  },
  components: {
    PageLayout,
    CompetenceGrid,
    PickerInput,
    TreeList
  },
};
</script>
