<template>
  <SideNavigationLayout>
    <template #sidenav-header>
      <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addFunctionalGroup">
        {{ $t('Functional group') }} <span class="font-weight-lighter">{{ $t('add') }}</span>
      </button>
      <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addJobPosition">
        {{ $t('Job position') }} <span class="font-weight-lighter">{{ $t('add') }}</span>
      </button>
    </template>
    <template #sidenav>
      <TreeList class="job-position-navigation"
                :nodes="functionalGroups"
                :column-field="'name'"
                :custom-node-key="'jobPositions'"
                :custom-sub-title-field="'user_name'"
                :show-sub-nodes="true"
                @nodeSelected="functionalGroupSelected"
                @customNodeSelected="jobPositionSelected"
                routeName="backend.functional-groups.functional-group"
                subRouteName="backend.job-positions.job-position"
      ></TreeList>
    </template>
    <template #content-header>
        <span v-if="$route.name === 'backend.job-positions.job-position'">
          {{ $t('Job position') }}
        </span>
      <span v-if="$route.name === 'backend.functional-groups.functional-group'">
           {{ $t('Functional group') }}
        </span>

    </template>
    <template #content-header-suffix>
        <span v-if="$route.params.id">
          {{ $t('edit') }}
        </span>
      <span v-if="!$route.params.id"
      >
          {{ $t('add') }}
        </span>
    </template>
    <template #content>
      <router-view :key="$route.path"/>
    </template>
    <template #content-footer>
      <portal-target name="footer-actions"></portal-target>
    </template>

  </SideNavigationLayout>

</template>
<script>
import TreeList from "@/components/TreeList";
import SideNavigationLayout from "@/views/backend/SideNavigationLayout";

export default {
  name: 'job-positions-layout',
  created() {
    this.getFunctionalGroups();
  },
  methods: {
    addFunctionalGroup() {
      this.$router.push({name: 'backend.functional-groups.functional-group'});
    },
    addJobPosition() {
      this.$router.push({name: 'backend.job-positions.job-position'});
    },
    getFunctionalGroups() {
      this.loading = true;
      this.$store.dispatch('getFunctionalGroups')
    },
    functionalGroupSelected(functionalGroup) {
      this.$router.push({name: 'backend.functional-groups.functional-group', params: {id: functionalGroup.id}});
    },
    jobPositionSelected(jobPosition) {
      this.$router.push({name: 'backend.job-positions.job-position', params: {id: jobPosition.id}});
    },
  },
  computed: {
    functionalGroups() {
      return this.$store.state.functionalGroups
    }
  },
  components: {
    TreeList,
    SideNavigationLayout,
  },
}
</script>
