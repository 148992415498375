<template>
  <PageLayout>
    <template #back-navigation>
      <BackLink to="completed-assessment-list" :label="$t('Assessments')"></BackLink>
    </template>
    <template #content-header>
      {{ assessment.title }}
    </template>
    <template #content-header-suffix>
      {{ $moment(assessment.dueDate).format('D.M.Y') }}
    </template>
    <template #content>
      <div class="container" v-if="assessmentCompetences">
        <CompetenceGrid
            :title="$t('Competences')"
            :subtitle="assessee.first_name"
            :competences="assessmentCompetences"
            @competenceSelected="competenceClicked"
            @commentSelected="commentSelected"
            @competenceFlagged="competenceFlagged"
            :show-claim="true"
            :show-public-ability="true"
            :show-common-ability="true"
            :show-ability="true"
            :show-current-job="currentJobData"
            :show-future-job="futureJobData"
            :show-job-abilities="true"
            :showActionRequired="true"
            :stateKey="'assessment-summary-'+assessment.id"
            :showComment="true"
        ></CompetenceGrid>
      </div>
      <portal to="dialog">
        <Dialog :visible.sync="showAssessmentComment" :modal="true" :maximizable="true" :dismissableMask="true">
          <template #header>
            <div class="mx-auto">
              <span class="font-weight-bolder">{{ $t('Comment') }} </span>
              <span class="font-weight-lighter">{{ commentCompetence.title }}</span>
            </div>
          </template>
          <div v-if="commentCompetence">
            {{ commentCompetence.comment }}
          </div>
        </Dialog>
      </portal>

    </template>
    <template #content-footer>
      <div class="flex-row w-100">
        <div class="d-flex w-100 justify-center">
          <div class="col-4 d-flex align-items-center justify-end" v-if="assessment.summary">
            <SecondaryButton @click="()=>{showSummary=true}">{{ $t('Summary') }}</SecondaryButton>
          </div>

          <div class="col-4 job-switcher align-items-center justify-center d-flex">
            <div class="v-center current">
              <InputSwitch v-model="currentJobData"/>
              <div class="ml-5 text-left">
                <div class="pt-0 mt-0">
                  <strong class="d-block">{{ $t('jobCurrent') }}</strong><small>{{ currentJobTitle }}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 job-switcher">
            <div class="v-center future">
              <InputSwitch v-model="futureJobData"/>
              <div class="ml-5 text-left flex-grow-1">
                <div class="pt-0 mt-0 flex-grow-1">
                  <strong class="d-block">{{ $t('jobFuture') }}</strong>
                  <PickerInput id="selected_category" @clearClick="clearSelected()"
                               :title="$t('Select JobPosition')"
                               :selected="selectedJobPosition.title"
                               :placeholder="$t('Select future job')"
                               v-model="selectedJobPosition.id"
                               :show-clear="false"
                               ref="future-job-position-picker">
                    <TreeList :nodes="jobPositions"
                              @nodeSelected="jobPositionChanged"
                    ></TreeList>
                  </PickerInput>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex w-100">
          <div class="col-3 d-flex justify-content-center v-center">
            <span>  <img class="" src="@/assets/icons/circle.svg" alt="circle"/></span><small
              class="mb-0 ml-2 text-usmallpercase">{{ $t('abilityCoordinated') }}</small>
          </div>
          <div class="col-3 d-flex justify-content-center v-center">
            <span>  <img class="" src="@/assets/icons/diamound-green.svg" alt="diamound-green"/></span><small
              class="mb-0 ml-2 text-uppercase">{{ $t('requirementCurrent') }}</small>
          </div>
          <div class="col-3 d-flex justify-content-center v-center">
            <span>  <img class="" src="@/assets/icons/diamound-blue.svg" alt="diamound-blue"/></span><small
              class="mb-0 ml-2 text-uppercase">{{ $t('requirementFuture') }}</small>
          </div>
          <div class="col-3 d-flex justify-content-center v-center">
            <span>  <img class="" src="@/assets/icons/circle-claim.svg" alt="claim-circle"/></span><small
              class="mb-0 ml-2 text-uppercase">{{ $t('desireSelf') }}</small>
          </div>
        </div>
      </div>

      <Dialog :visible.sync="showSummary" :modal="true" v-if="assessment">
        <template #header>
          {{ $t('Summary') }}
        </template>
        <div>
          <p style="white-space: pre">{{ assessment.summary }}</p>
        </div>
      </Dialog>

    </template>
  </PageLayout>
</template>
<style>
.assessment-summary .vs__open-indicator, .assessment-summary .vs__actions {
  display: none;
}

.assessment-summary .vs--single.vs--searchable {
  width: 100%;
  margin-top: 10px;
}

.assessment-summary .vs__dropdown-toggle, .assessment-summary .vs__search {
  text-transform: initial !important;
}

.assessment-summary .vs__search {
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
  display: block !important;
}

.assessment-summary .vs--open .vs__search {
  position: relative !important;

}

.assessment-summary .vs__selected {
  font-size: 0.75em !important;
}

.assessment-summary .vs__dropdown-menu {
  padding-left: 0 !important;
  font-size: 0.75em !important;
  width: 130%;
}

.assessment-summary .finish-btn {
  margin-left: 0 !important;
}
</style>
<script>
import CompetenceGrid from "@/components/CompetenceGrid";
import PickerInput from "@/components/PickerInput";
import TreeList from "@/components/TreeList";
import PageLayout from "../backend/PageLayout";
import BackLink from "../../components/BackLink.vue";

export default {
  name: 'assessment-home',
  data() {
    return {
      assessment: null,
      assessee: null,
      competences: [],
      assesseeCompetences: [],
      assessmentCompetences: null,
      selectedCompetence: null,
      expanded: true,
      expandedInner: true,
      currentJobData: true,
      currentJobTitle: null,
      futureJobData: false,
      futureJobTitle: null,
      showSummary: false,
      expandedKeys: {},
      jobPositions: [],
      selectedJobPosition: {
        id: null,
        title: null
      },
      showAssessmentComment: false,
      commentCompetence: null,
    };
  },
  created() {
    this.getAssessment();
    this.getCompetences();

  },
  methods: {
    getAssessment() {
      this.$http.get('/assessments/' + this.$route.params.assessmentId)
          .then((res) => {
            this.assessment = res.data
          })
    },
    getCompetences() {
      this.$store
          .dispatch('getEmployee', {id: this.$route.params.assesseeId})
          .then(res => {
            this.$store.commit('setLoading', 'loading');
            this.assessee = res.data;
            this.$http
                .get('job-positions', {})
                .then(res => {
                  this.jobPositions = res.data;
                  if (!this.selectedJobPosition && this.assessee.future_job_position) {
                    this.selectedJobPosition = this.jobPositions.find(position => {
                      return position.id === this.assessee.future_job_position.id;
                    });
                  }
                })
                .then(() => {
                  this.assessmentCompetences = null
                  let assessmentTreeSummaryUrl = '/assessment-competences-summary/' + this.$route.params.assessmentId
                  if (this.selectedJobPosition.id) {
                    assessmentTreeSummaryUrl += '/' + this.selectedJobPosition.id
                  }
                  this.$http
                      .get(
                          assessmentTreeSummaryUrl
                      )
                      .then(res => {
                        this.currentJobTitle = this.assessee.job_position.title;
                        this.futureJobTitle = (this.assessee.future_job_position) ? this.assessee.future_job_position.title : null;
                        console.log(res)
                        this.assessmentCompetences = null;
                        this.assessmentCompetences = res.data;
                        this.$store.commit('setLoading', 'success');
                      })
                      .catch(err => {
                        this.$toast.add({
                          severity: 'error',
                          summary: 'Kompetenzen nicht geladen',
                          detail: err,
                          life: 3000,
                        });
                        this.$store.commit('setLoading', 'success');
                      });
                });
          });
    },
    competenceClicked(competence) {

      let stepId = this.assessment.competences.findIndex(assessmentCompetence => {
        return assessmentCompetence.id === competence.id;
      });
      if (stepId >= 0) {
        this.$router.push({
          name: 'assessment-competence',
          params: {
            stepId,
            assessmentId: this.$route.params.assessmentId,
            assesseeId: this.$route.params.assesseeId,
            disabled: true
          },
        });
      }
    },
    commentSelected(competence) {
      if (competence.comment) {
        this.showAssessmentComment = true
        this.commentCompetence = competence
      }
    },
    competenceFlagged(competence) {
      let actionRequired = !competence.action_required
      this.$store
          .dispatch('postAssessmentCompetence', {
            assessmentId: this.$route.params.assessmentId,
            competenceId: competence.id,
            actionRequired: actionRequired
          },)
          .then(() => {
            let flaggedCompetenceIndex = this.assessmentCompetences.findIndex((assessmentCompetence) => {
              return assessmentCompetence.id === competence.id
            })
            this.assessmentCompetences[flaggedCompetenceIndex].action_required = actionRequired
            this.$toast.add({
              severity: 'success',
              summary: (actionRequired) ? this.$t('Flag successfully added') : this.$t('Flag successfully removed'),
              life: 3000,
            });
          })
    },
    jobPositionChanged(futureJobPosition) {
      this.selectedJobPosition = futureJobPosition
      this.getCompetences();
      this.$refs["future-job-position-picker"].closeDialog()
    },
    toggle(event, competenceId) {
      this.$refs['op-' + competenceId][0].toggle(event);
    },
  },
  components: {
    BackLink,
    PageLayout,
    CompetenceGrid,
    PickerInput,
    TreeList
  },
};
</script>