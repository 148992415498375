<template>
  <div class="page-content-pb d-flex flex-column flex-grow-1">
    <div class="UpperText container text-container pt-0">
      <h5 class="text-center page-title-flex text-uppercase">{{ this.currentCompetence.title }}</h5>
      <div
          class="pt-2 competence-title-description container"
      >= {{ this.currentCompetence.description }}
      </div>
    </div>
     <p
        class="question-title text-center mb-5 mt-3"
        v-if="abilityActive"
    >{{ $t('ability') }}</p>
    <p
        class="question-title text-center mb-5 mt-3"
        v-if="claimActive"
    >{{ $t('desire') }}</p>
    <div class="Slider container mt-4 pl-5 pr-5 mt-5 mb-5 sliders w-75">
      <div class="prepend-line double"></div>
      <v-slider
          class="custom-slider ability-slider-fill"
          v-model="abilityFillValue"
          min="1"
          max="8"
          steps="6"
          color="#dc8e00"
          track-color="#C3C8CC"
      ></v-slider>
      <v-slider
          class="custom-slider ability-slider"
          :class="{ 'inactive': !abilityActive }"
          v-model="abilityValue"
          min="0"
          max="8"
          @start="abilityDragStart"
          @click="abilityDragEnd"
          @end="abilityDragEnd"
          steps="7"
          :ticks="(user.show_ticks)?'always':false"
          color="transparent"
          track-color="transparent"
          thumb-size="60"
          thumb-label="always"
          thumb-color="transparent"
      >
        <template v-slot:thumb-label>
          <img v-if="abilityActive" class="selfassessment-tooltip-icon" src="@/assets/icons/k.svg"/>
          <img v-if="!abilityActive" class="selfassessment-tooltip-icon" src="@/assets/icons/k-gray.svg"/>
        </template>
        <template v-slot:prepend>
          <div class="prepend-indicator">
            <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50 0, 65 200, 35 200"/>
            </svg>
            <span class="label">{{ $t('novice') }}</span>
          </div>
        </template>
        <template v-slot:append>
          <div class="append-indicator">
            <svg fill="#C3C8CC" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50 0, 65 200, 35 200"/>
            </svg>
            <span class="label">{{ $t('expert') }}</span>
          </div>
        </template>
      </v-slider>

      <v-slider
          class="custom-slider claim-slider-fill"
          v-model="claimFillValue"
          min="1"
          max="8"
          steps="6"
          color="#dc8e00"
          track-color="#C3C8CC"
      ></v-slider>
      <v-slider
          class="custom-slider claim-slider"
          :class="{ 'inactive': !claimActive }"
          v-model="claimValue"
          min="0"
          max="8"
          @start="claimDragStart"
          @end="claimDragEnd"
          @click="claimDragEnd"
          steps="7"
          :ticks="(user.show_ticks)?'always':false"
          color="transparent"
          track-color="transparent"
          thumb-size="60"
          thumb-label="always"
          thumb-color="transparent"
      >
        <template v-slot:thumb-label>
          <img v-if="claimActive" class="selfassessment-tooltip-icon" src="@/assets/icons/w.svg"/>
          <img v-if="!claimActive" class="selfassessment-tooltip-icon" src="@/assets/icons/w-gray.svg"/>
        </template>
        -gray
      </v-slider>
    </div>
    <div class="container text-container mt-5" v-if="this.currentCompetence.competence_levels">
      <div v-if="active == 'ability'">
        <span v-if="abilityValue <= 0">
          <div class="competence-title-container">
            <span class="competence-title text-uppercase">{{ $t('ability') }}</span>
             <span
            class='competence-title-suffix'> {{ $t('Rating') }}</span>
          </div>
        </span>
        <div class='competence-title-container' v-if="abilityValue>0">
          <span class='competence-title'>{{$t('competences.levelTitles.level'+(abilityValue)+'.title')}}</span>
          <span class='competence-title-suffix'> {{$t('competences.levelTitles.level'+(abilityValue)+'.suffix')}}</span>
        </div>
      </div>
      <div v-if="active == 'claim'">
        <span v-if="claimValue <= 0">
          <div class="competence-title-container">
            <span class="competence-title text-uppercase">{{ $t('desire') }}</span>
             <span
            class='competence-title-suffix'> {{ $t('Rating') }}</span>
          </div>
        </span>
        <div class='competence-title-container' v-if="claimValue>0">
          <span class='competence-title'>{{$t('competences.levelTitles.level'+(claimValue)+'.title')}}</span>
          <span class='competence-title-suffix'> {{$t('competences.levelTitles.level'+(claimValue)+'.suffix')}}</span>
        </div>
      </div>
    </div>
    <div
        class="overflow-auto container text-container competence-level-description pt-0"
        v-if="this.currentCompetence.competence_levels"
    >
     <span
         v-if="abilityValue > 0 && active == 'ability' && this.currentCompetence.competence_levels.length > 2" v-html="this.getCompetenceLevelDescription(abilityValue)"
     ></span>
      <span
          v-if="abilityValue <= 0 && active == 'ability'"
      >{{ this.currentCompetence.competence_levels[0].description }}</span>
      <span
          v-if="claimValue > 0 && active == 'claim' && this.currentCompetence.competence_levels.length > 2" v-html="this.getCompetenceLevelDescription(claimValue)"
      ></span>
      <span
          v-if="claimValue <= 0 && active == 'claim'"
      >{{ this.currentCompetence.competence_levels[1].description }}</span>
    </div>
    <div class="bottom-container d-flex flex-column container">
      <button
          class="btn btn-primary btn-block text-uppercase rounded-pill center-button"
          @click="endClicked"
      >{{ $t('rate') }}
      </button>
    </div>
  </div>
</template>
<style lang="scss">
.p-slider-horizontal,
.p-inputtext {
  width: 100%;
}

.p-slider-vertical {
  height: 14em;
}

.page-counter {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.custom-dot {
  width: 100%;
  height: 100%;
}

.custom-dot-ability {
  transition: translateY(-20%);
  position: relative;

  .indicator {
    color: $gray;
    position: absolute;
    bottom: -1em;
    font-weight: bold;
    left: 0;
    right: 0;
  }
}

.custom-mark-1 {
  position: absolute;
  top: 10px;
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}

.custom-mark-2 {
  position: absolute;
  top: 10px;
  transform: translateX(-100%);
  white-space: nowrap;
  color: $gray;
  text-transform: uppercase;
}

.description-content {
  max-height: 40vh;
  overflow: auto;
}
</style>
<script>
export default {
  name: 'competence-detail',
  components: {},
  data() {
    return {
      user: null,
      currentCompetence: {},
      data: [],
      abilityValue: 0,
      abilityFillValue: 1,
      claimValue: 0,
      claimFillValue: 1,
      active: 'ability',
      claimActive: false,
      abilityActive: true,
    };
  },
  created: function () {
    this.$store
        .dispatch('getUser', {})
        .then(() => {
          this.user = this.$store.getters.user;
          this.getCompetence();
        })

  },
  methods: {
    endClicked() {
      this.$router.go(-1);
    },
    getCompetence() {
      this.$http
          .get('/competences/' + this.$route.params.competenceId)
          .then(res => {
            this.currentCompetence = res.data;
            this.data = this.currentCompetence.competence_levels.map(res => {
              return res.level - 1;
            });
            console.log(this.data)
            this.data.push(this.data.length);
            this.currentCompetence.competence_levels.unshift(
                {
                  title: 'Können',
                  description:
                      'Bitte verschiebe jetzt den Regler (K) um dein momentanes Kompetenzlevel einzuschätzen',
                },
                {
                  title: 'Wollen',
                  description:
                      'Bitte verschiebe jetzt den Regler (W) um den gewünschten Kompetenzlevel einzuschätzen',
                },
            );

            this.abilityValue = this.user.competences.find(competence => {
              return competence.id == this.$route.params.competenceId;
            }).self_ability;
            console.log('Ability Value ', this.abilityValue);
            this.claimValue = this.user.competences.find(competence => {
              return competence.id == this.$route.params.competenceId;
            }).self_claim;
          });
    },
    abilityDragStart() {
      this.claimActive = false;
      this.abilityActive = true;
      this.active = 'ability';
    },
    abilityDragEnd() {

      this.$store
          .dispatch('postUserCompetence',
              {id: this.user.id, competenceId: this.$route.params.competenceId, selfAbility: this.abilityValue},
          )
          .then(() => {
            console.log('successfully posted ability', this.abilityValue);
          })
          .catch(err => {
            console.log('error posting ability ', err);
          });
    },
    claimDragStart() {
      this.claimActive = true;
      this.abilityActive = false;
      this.active = 'claim';
      console.log('claim value ', this.abilityActive);
    },
    claimDragEnd() {
      this.$store
          .dispatch('postUserCompetence',
              {id: this.user.id, competenceId: this.$route.params.competenceId, selfClaim: this.claimValue},
          )
          .then(() => {
            console.log('successfully posted claim', this.abilityValue);
          })
          .catch(err => {
            console.log('error posting claim ', err);
          });
    },
    abilityChanged() {
      console.log('ability changed ');
    },
    getCompetenceLevelDescription(value) {
      let compLevel = this.currentCompetence.competence_levels.find((competenceLevel) => {
        return competenceLevel.level == value
      });
      return compLevel.description;
    }
  },
  watch: {
    abilityValue: function () {
      this.abilityFillValue = this.abilityValue
    },
    claimValue: function () {
      this.claimFillValue = this.claimValue
    },
  }
};
</script>
