<template>
  <div class="w-100">
    <form @submit.prevent="saveFunctionalGroup">
      <div class="row">
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.functionalGroup.name.$error }">
          <label for="name">{{ $t('Name') }}</label>
          <InputText id="name" type="text" v-model="functionalGroup.name"/>
        </div>
        <div class="error" v-if="!$v.functionalGroup.name.required">Pflichtfeld</div>

        <div class="col-12 form-group">
          <label>{{ $t('Parent functional group') }}</label>
          <PickerInput id="parent_functional_group" @click="toggle()" @clearClick="clearSelected()"
                       :title="$t('Select Parent Functional group')"
                       :selected="selectedParentFunctionalGroup"
                       :placeholder="$t('Select parent functional group')"
                       ref="picker">
            <TreeList :nodes="this.functionalGroups"
                      @nodeSelected="parentFunctionalGroupSelected"
                      :column-field="'name'"
            ></TreeList>
          </PickerInput>
        </div>

        <div class="col-12 form-group" v-if="$route.params.id && executives">
          <label>{{ $t('Managers') }}</label>

          <CustomPickList :data="executives"
                          :column-field="'name'"
                          :sub-title-field="'email'"
                          @move-to-target="addManager"
                          @move-to-source="removeManager"></CustomPickList>

        </div>
        <portal to="footer-actions">
          <FormActions>
            <template #left>
              <DangerButton v-if="functionalGroup.id" @click="confirmDelete">{{ $t('delete') }}</DangerButton>
            </template>
            <PrimaryButton @click="saveFunctionalGroup">{{ $t('save') }}</PrimaryButton>
          </FormActions>
        </portal>
      </div>
    </form>
    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: functionalGroup.name}) }}
      </template>
      <div class="confirmation-content">
        {{ $t('Do you really want to delete functional group {title}?', {title: functionalGroup.name}) }}
      </div>

      <div v-if="this.functionalGroup.jobPositions.length>0">
        <p class="mb-0" v-if="this.functionalGroup.jobPositions.length===1">{{
            $t('The functional group can not be deleted, because it is linked to the following job position:')
          }}</p>
        <p class="mb-0" v-if="this.functionalGroup.jobPositions.length>1">{{
            $t('The functional group can not be deleted, because it is linked to the following job positions:')
          }}</p>
        <div v-for="jobPosition in this.functionalGroup.jobPositions"
             :key="jobPosition.id">
          <a class="category-title" @click="jobPositionSelected(jobPosition)">{{ jobPosition.title }}</a>
        </div>
      </div>

      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDelete">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DangerButton @click="deleteFunctionalGroup"
                v-if="functionalGroup.jobPositions.length<=0">{{ $t('delete') }}</DangerButton>
        </FormActions>
      </template>
    </Dialog>
  </div>

</template>

<script>
import {required} from 'vuelidate/lib/validators';
import Dialog from 'primevue/dialog';
import TreeList from "@/components/TreeList";
import PickerInput from "@/components/PickerInput";
import CustomPickList from "@/components/CustomPickList";


export default {
  name: 'functionalGroups',
  data() {
    return {
      functionalGroup: {
        name: null,
        parent_id: null,
        managers: [],
        jobPositions: []
      },
      selectedParentFunctionalGroup: null,
      showConfirmDelete: false,
      executives: null
    }
  },
  created() {
    this.getFunctionalGroup()
    this.getFunctionalGroups()
  },
  methods: {
    getFunctionalGroup() {
      if (this.$route.params.id) {
        this.$http.get('functional-groups/' + this.$route.params.id)
            .then((res) => {
              this.functionalGroup = {...res.data}
              this.functionalGroup.managers = res.data.managers
              if (this.functionalGroup.parent_id) {
                this.$http.get('functional-groups/' + this.functionalGroup.parent_id).then(res => {
                  this.selectedParentFunctionalGroup = res.data.name
                })
              }
              this.getExecutives()
            }).catch(() => {
          this.$router.push({name: 'backend.functional-groups.functional-groups'})
        })
      }
    },
    getFunctionalGroups() {
      this.$store.dispatch('getFunctionalGroups');
    },
    getExecutives() {
      this.$store.dispatch('getExecutives')
          .then(() => {
            this.executives = [this.$store.state.executives.filter((executive) => {
              return !this.functionalGroup.managers.some((manager) => {
                return executive.id === manager.id
              })
            }), this.functionalGroup.managers]
          });
    },
    saveFunctionalGroup() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          this.$http.put('functional-groups/' + this.functionalGroup.id, this.functionalGroup)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Functional group updated successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getFunctionalGroups')
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in updating functional groups'),
                  detail: error.message,
                  life: 3000,
                });
              });
        } else {
          this.$http.post('functional-groups', this.functionalGroup)
              .then((res) => {
                console.log(res)
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Functional group added successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getFunctionalGroups')
                this.$router.push({
                  name: 'backend.functional-groups.functional-group',
                  params: {id: res.data.id}
                });


              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in adding functionalGroup'),
                  detail: error.message,
                  life: 3000,
                });
              });
        }
      }
    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    deleteFunctionalGroup() {
      this.$http.delete('functional-groups/' + this.functionalGroup.id)
          .then((res) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t(res.data.message),
              life: 3000,
            });
            this.$router.push('/backend/functional-groups/functional-group')
          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    functionalGroupSelected(functionalGroup) {
      this.functionalGroup.functional_group = functionalGroup
      this.$refs["functional-group-picker"].closeDialog()
    },
    parentFunctionalGroupSelected(functionalGroup) {
      console.log("selected ", functionalGroup)
      this.functionalGroup.parent_id = functionalGroup.id;
      this.selectedParentFunctionalGroup = functionalGroup.name;
      this.$refs.picker.closeDialog()
    },
    clearSelected() {
      this.selectedParentFunctionalGroup = null
      this.functionalGroup.parent_id = null
    },
    addManager(manager) {
      for (let item of manager.items) {
        this.$http.post('functional-group-manager', {
          'functionalGroupId': this.functionalGroup.id,
          'managerId': item.id
        })
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Manager successfully added'),
                life: 3000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
    },
    removeManager(manager) {
      console.log("remove", manager)
      for (let item of manager.items) {
        this.$http.delete('functional-group-manager/' + this.functionalGroup.id + '/' + item.id)
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Manager successfully removed'),
                life: 3000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
    },
    jobPositionSelected(jobPosition) {
      this.$router.push({name: 'backend.job-positions.job-position', params: {id: jobPosition.id}});
    },
  },
  components: {
    Dialog,
    PickerInput,
    TreeList,
    CustomPickList
  },
  validations: {
    functionalGroup: {
      name: {
        required,
      },
    }
  },
  computed: {
    functionalGroups() {
      return this.$store.state.functionalGroups.filter((functionalGroup) => {
        return this.functionalGroup.id !== functionalGroup.id
      })
    }
  }

}
</script>