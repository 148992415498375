<template>
  <div class="app-container d-flex flex-column h-100 w-100">
    <Toast position="bottom-left" :breakpoints="{'920px': {width: '90%', right: '10', left: '10'}}"/>
     <portal-target name="dialog"></portal-target>
    <div id="nav" class="p-4">
      <div class="d-flex align-items-center">
        <div class="d-block back-navigation px-2" :style="{'width': '15vw'}">

            <portal-target name="backNavigation"></portal-target>

          <i v-if="!Wormhole.hasContentFor('backNavigation') && this.$router.currentRoute.name !== 'backend.home'"
              class="pi pi-chevron-left back-navigation-icon"
              @click="navigateBack"
          ></i>
        </div>
        <div class="d-flex flex-grow-1 text-center align-items-center justify-content-center">
          <portal-target name="appPageTitle"></portal-target>
        </div>
        <div class="d-flex menu align-items-center"  :style="{'width': '15vw'}">
          <Slide :closeOnNavigation="true" right class="slide-menu" @openMenu="handleOpenMenu">
            <small class="position-absolute p-0 font-weight-light" style="top:0;right:0">
              <div class="px-4 cursor-pointer logout-link" @click="logout">
                <span class="d-block w-100 py-2">{{ $t('Logout') }}</span>
              </div>
            </small>
            <div class="d-flex flex-column h-100 px-0">


              <div v-if="!this.$helper.hasAnyRole([this.$roles.ADMIN])" class="d-flex h-100 flex-column">
                <SideMenuLink to="/" :notification-count="newMessages">{{ $t('Home') }}</SideMenuLink>
                <SideMenuLink to="/messages" :notification-count="newMessages">{{ $t('messages') }}</SideMenuLink>
                <SideMenuLink to="/competences">{{ $t('myCompetences') }}</SideMenuLink>

                <SideMenuLink to="/assessment-list">{{ $t('plannedAssessments') }}</SideMenuLink>
                <SideMenuLink to="/completed-assessment-list">{{ $t('completedAssessments') }}</SideMenuLink>

                <SideMenuLink to="/profile">{{ $t('Settings') }}</SideMenuLink>
                <div class="flex-grow-1"></div>

              </div>

              <div v-if="!this.$helper.hasAnyRole([this.$roles.ADMIN])" class="flex-grow-1 "></div>
              <div class="border-bottom w-100 d-block mr-4"
                   v-if="this.$helper.hasAnyRole([this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE, this.$roles.EXECUTIVE])">
                <small class="d-block text-right text-white px-4 font-weight-light">{{ $t('Administration') }}</small>
              </div>
              <div>
                <SideMenuLink to="/administration"
                              :for-roles="[this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE]">
                  {{ $t('Dashboard administration') }}
                </SideMenuLink>
                <SideMenuLink to="/my-team-list" :for-roles="[this.$roles.EXECUTIVE]">{{ $t('myTeam') }}</SideMenuLink>
                <SideMenuLink to="/backend/assessments" :for-roles="[this.$roles.EXECUTIVE]">
                  {{ $t('Assessments administration') }}
                </SideMenuLink>
                <SideMenuLink to="/backend/competences/competence/info"
                              :for-roles="[this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER]">
                  {{ $t('Administration competences') }}
                </SideMenuLink>
                <SideMenuLink to="/backend/job-positions/job-position"
                              :for-roles="[this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER]">
                  {{ $t('Job Positions') }}
                </SideMenuLink>
                <SideMenuLink to="/backend/job-role-groups/job-role"
                              :for-roles="[this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER]">
                  {{ $t('Job Roles') }}
                </SideMenuLink>
                <SideMenuLink to="/backend/hr-groups/user"
                              :for-roles="[this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE]">
                  {{ $t('Users') }}
                </SideMenuLink>
                <SideMenuLink to="/backend/assessments/"
                              :for-roles="[this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER, this.$roles.HREMPLOYEE]">
                  {{ $t('Assessments') }}
                </SideMenuLink>
                <SideMenuLink to="/backend/tags/tag"
                              :for-roles="[this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER]">
                  {{ $t('Tags') }}
                </SideMenuLink>
                <SideMenuLink to="/backend/reporting/"
                              :for-roles="[this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER]">
                  {{ $t('Reporting') }}
                </SideMenuLink>
                <template v-if="this.$helper.hasAnyRole([this.$roles.ADMIN])">
                  <SideMenuLink to="/profile">{{ $t('Settings') }}</SideMenuLink>
                </template>

              </div>

            </div>

          </Slide>
        </div>
      </div>
    </div>
    <div id="page-wrap" class="app-content flex-grow-1 overflow-hidden d-flex flex-row">
      <div class="flex-grow-1 d-flex">
        <div class="w-100">
          <router-view :key="this.$route.path"/>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss">
.logout-link :hover {
  text-decoration: underline;
  color: $primary;
}
</style>

<script>
import {Slide} from 'vue-burger-menu';
import SideMenuLink from "../components/SideMenuLink";
import {Wormhole} from "portal-vue";

export default {
  computed: {
    Wormhole() {
      return Wormhole
    }
  },
  components: {
    SideMenuLink,
    Slide,
  },
  data() {
    return {
      newMessages: this.$store.getters.messages ? this.getMessageCount() : null,
    };
  },
  methods: {
    handleOpenMenu(){
      this.$store.dispatch('getUser', {})
    },
    getMessageCount() {
      return this.$store.getters.messages.filter(message => {
        return message.read === false;
      }).length
    },
    resetExpandedKeys() {
      localStorage.setItem('expandedKeys', [])
    },
    logout: function () {
      this.$http.post('logout').then(() => {
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/login');
        })
            .catch(() => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t('loginFailed'),
                life: 3000,
              });
            });
      });
    },
    navigateBack() {
      this.resetExpandedKeys()
      this.$router.back();
    },
  }
}
</script>