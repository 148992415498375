<template>
  <div class="w-100">
    <form @submit.prevent="saveJobRoleGroup">
      <div class="row">
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.jobRoleGroup.name.$error }">
          <label for="name">{{ $t('Name') }}</label>
          <InputText id="name" type="text" v-model="jobRoleGroup.name"/>
        </div>
        <div class="error" v-if="!$v.jobRoleGroup.name.required">Pflichtfeld</div>

        <div class="col-12 form-group">
          <label>{{ $t('Parent job role group') }}</label>
          <PickerInput id="parent_job role_group" @click="toggle()" @clearClick="clearSelected()"
                       :title="$t('Select parent job role group')"
                       :selected="selectedParentJobRoleGroup"
                       :placeholder="$t('Select parent job role group')"
                       ref="picker">
            <TreeList :nodes="this.jobRoleGroups"
                      @nodeSelected="parentJobRoleGroupSelected"
                      :column-field="'name'"
            ></TreeList>
          </PickerInput>
        </div>
        <div class="col-12 form-group" v-if="jobRoleGroup.children.length > 0">
            <Accordion>
              <AccordionTab :header="jobRoleGroup.children.length + ' ' + this.$tc('Sub job role groups', jobRoleGroup.children.length)">
                <div v-for="subJobRoleGroup in jobRoleGroup.children" :key="subJobRoleGroup.id">
                  <a class="job-role-name"
                     @click="jobRoleGroupSelected(subJobRoleGroup)">
                   <span
                       v-if="subJobRoleGroup.name"
                   ></span>
                    {{ subJobRoleGroup.name }}
                  </a>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
          <div class="col-12 form-group" v-if="jobRoleGroup.jobRoles.length > 0">
            <Accordion>
              <AccordionTab :header="jobRoleGroup.jobRoles.length + ' ' + this.$tc('Job role', jobRoleGroup.jobRoles.length)">
                <div v-for="jobRole in jobRoleGroup.jobRoles" :key="jobRole.id">
                  <a class="job-role-title"
                     @click="jobRoleSelected(jobRole)">
                    {{ jobRole.title }}
                  </a>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        <portal to="footer-actions">
          <FormActions>
            <template #left>
              <DangerButton v-if="jobRoleGroup.id && jobRoleGroup.id !== $store.state.settings.fallback_job_role_group_id" @click="confirmDelete"> {{ $t('delete') }}</DangerButton>
            </template>
            <PrimaryButton @click="saveJobRoleGroup">{{ $t('save') }}</PrimaryButton>
          </FormActions>
        </portal>
      </div>

    </form>
    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: jobRoleGroup.name}) }}
      </template>
      <div class="confirmation-content">
        {{
          $t('Do you really want to delete job role group {title}? All job roles will be moved to the fallback job role group', {title: jobRoleGroup.name})
        }}
      </div>
      <template #footer>
          <FormActions>
          <template #left>
              <SecondaryButton @click="confirmDelete">{{ $t('Cancel') }}</SecondaryButton>
          </template>
        <DangerButton @click="deleteJobRoleGroup">{{ $t('delete') }}</DangerButton>
        </FormActions>
      </template>
    </Dialog>
  </div>

</template>

<script>
import {required} from 'vuelidate/lib/validators';
import Dialog from 'primevue/dialog';
import PickerInput from "@/components/PickerInput";
import TreeList from "@/components/TreeList";

export default {
  name: 'jobRoleGroups',
  data() {
    return {
      jobRoleGroup: {
        name: null,
        parent_id: null,
        children:[],
        jobRoles:[],
      },
      selectedParentJobRoleGroup: null,
      showConfirmDelete: false,
    }
  },
  created() {
    this.getJobRoleGroup()
    this.getJobRoleGroups()
  },
  methods: {
    getJobRoleGroup() {
      if (this.$route.params.id) {
        this.getSettings()
        this.$http.get('job-role-groups/' + this.$route.params.id)
            .then((res) => {
              console.log("RES", res)
              this.jobRoleGroup = {...res.data}
              if (this.jobRoleGroup.parent_id) {
                this.$http.get('job-role-groups/' + this.jobRoleGroup.parent_id).then(res => {
                  this.selectedParentJobRoleGroup = res.data.name
                })
              }
            }).catch(() => {
          this.$router.push({name: 'backend.job-role-groups.job-role-group'})
        })
      }
    },
    getSettings() {
      console.log("getting settings")
      this.$store.dispatch('getSettings');
    },
    getJobRoleGroups() {
      this.$store.dispatch('getJobRoleGroups');
    },
    saveJobRoleGroup() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          this.$http.put('job-role-groups/' + this.jobRoleGroup.id, this.jobRoleGroup)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Job role group updated successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getJobRoleGroups')
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in updating job-role groups'),
                  detail: error.message,
                  life: 3000,
                });
              });
        } else {
          this.$http.post('job-role-groups', this.jobRoleGroup)
              .then((res) => {
                console.log(res)
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('Job role group added successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getJobRoleGroups')
                this.$router.push({
                  name: 'backend.job-role-groups.job-role-group',
                  params: {id: res.data.id}
                });


              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in adding jobRoleGroup'),
                  detail: error.message,
                  life: 3000,
                });
              });
        }
      }
    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    deleteJobRoleGroup() {
      this.$http.delete('job-role-groups/' + this.jobRoleGroup.id)
          .then(() => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t('Job role group deleted successfully'),
              life: 3000,
            });
            this.$router.push('/backend/job-role-groups/job-role-group')
          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    jobRoleGroupSelected(jobRoleGroup) {
      this.$router.push({name: 'backend.job-role-groups.job-role-group', params: {id: jobRoleGroup.id}});
    },
    jobRoleSelected(jobRole) {
      this.$router.push({name: 'backend.job-role-groups.job-role', params: {id: jobRole.id}});
    },
    parentJobRoleGroupSelected(jobRoleGroup) {
      console.log("selected ", jobRoleGroup)
      this.jobRoleGroup.parent_id = jobRoleGroup.id;
      this.selectedParentJobRoleGroup = jobRoleGroup.name;
      this.$refs.picker.closeDialog()
    },
    clearSelected() {
      this.selectedParentJobRoleGroup = null
      this.jobRoleGroup.parent_id = null
    },
  },
  components: {
    Dialog,
    PickerInput,
    TreeList,
  },
  validations: {
    jobRoleGroup: {
      name: {
        required,
      },
    }
  },
  computed: {
    jobRoleGroups() {
      return this.$store.state.jobRoleGroups.filter((jobRoleGroup) => {
        return this.jobRoleGroup.id !== jobRoleGroup.id
      })
    }
  }

}
</script>