<template>

    <SideNavigationLayout>
      <template #sidenav-header>
        <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addHrGroup">
          {{ $t('HR Group') }} <span class="font-weight-lighter">{{ $t('add') }}</span>
        </button>
        <button class="btn btn-primary btn-block text-uppercase rounded-pill" @click="addUser">
          {{ $t('User') }} <span class="font-weight-lighter">{{ $t('add') }}</span>
        </button>
      </template>
      <template #sidenav>
        <TreeList class="hr-group-navigation"
                  :nodes="hrGroups"
                  :column-field="'name'"
                  :show-sub-nodes="true"
                  :custom-node-key="'members'"
                  :custom-column-field="'name'"
                  @nodeSelected="hrGroupSelected"
                  @customNodeSelected="userSelected"
                  routeName="backend.hr-groups.hr-group"
                  subRouteName="backend.hr-groups.user"
        ></TreeList>
      </template>

      <template #content-header>
        <span v-if="$route.name === 'backend.hr-groups.hr-group'">
          {{ $t('HR Group') }}
        </span>
        <span v-if="$route.name === 'backend.hr-groups.user'">
           {{ $t('User') }}
        </span>

      </template>
      <template #content-header-suffix>
        <span v-if="$route.params.id">
          {{ $t('edit') }}
        </span>
        <span v-if="!$route.params.id"
        >
          {{ $t('add') }}
        </span>
      </template>
      <template #content-header-right>
        <portal-target name="content-header-right"></portal-target>
      </template>
      <template #content>
        <router-view :key="$route.path"/>
      </template>
      <template #content-footer>
        <portal-target name="footer-actions"></portal-target>
      </template>
    </SideNavigationLayout>
</template>
<script>
import TreeList from "@/components/TreeList";
import SideNavigationLayout from "@/views/backend/SideNavigationLayout";

export default {
  name: 'hr-groups',
  created() {
    this.getHrGroups();
  },
  methods: {
    addHrGroup() {
      this.$router.push({name: 'backend.hr-groups.hr-group'});
    },
    addUser() {
      this.$router.push({name: 'backend.hr-groups.user'});
    },
    addJobPosition() {
      this.$router.push({name: 'backend.hr-groups.hr-group'});
    },
    getHrGroups() {
      this.loading = true;
      this.$store.dispatch('getHrGroups')
    },
    hrGroupSelected(hrGroup) {
      this.$router.push({name: 'backend.hr-groups.hr-group', params: {id: hrGroup.id}});
    },
    userSelected(user) {
      this.$router.push({name: 'backend.hr-groups.user', params: {id: user.id}});
    },
  },
  computed: {
    hrGroups() {
      return this.$store.state.hrGroups
    }
  },
  components: {
    TreeList,
    SideNavigationLayout,
  },
}
</script>
