<template>
  <div class="w-100">
    <form @submit.prevent="saveHrGroup">
      <div class="row">
        <div class="col-12 form-group" :class="{ 'form-group--error': $v.hrGroup.name.$error }">
          <label for="name">{{ $t('Name') }}</label>
          <InputText id="name" type="text" v-model="hrGroup.name"/>
        </div>
        <div class="error" v-if="!$v.hrGroup.name.required">Pflichtfeld</div>

        <div class="col-12 form-group">
          <label>{{ $t('Parent hr group') }}</label>
          <PickerInput id="parent_hr_group" @click="toggle()" @clearClick="clearSelected()"
                       :title="$t('Select parent hr group')"
                       :selected="selectedParentHrGroup"
                       :placeholder="$t('Select parent hr group')"
                       :enabled="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN])"
                       ref="picker">
            <TreeList :nodes="this.hrGroups"
                      @nodeSelected="parentHrGroupSelected"
                      :column-field="'name'"
            ></TreeList>
          </PickerInput>
        </div>

        <div class="col-12 form-group" v-if="$route.params.id && hrManagers">
          <label>{{ $t('Managers') }}</label>

          <CustomPickList :data="hrManagers"
                          :sub-title-field="'email'"
                          :column-field="'name'"
                          @move-to-target="addManager"
                          @move-to-source="removeManager"
                          :enabled="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN])"
          ></CustomPickList>
        </div>
        <portal to="footer-actions">
          <template v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN])">
            <FormActions>
              <template #left>
                <DangerButton v-if="hrGroup.id && hrGroup.id !== $store.state.settings.fallback_hr_group_id"
                              @click="confirmDelete"> {{ $t('delete') }}
                </DangerButton>
              </template>
              <PrimaryButton @click="saveHrGroup">{{ $t('save') }}</PrimaryButton>
            </FormActions>
          </template>
        </portal>
      </div>

    </form>
    <Dialog :visible.sync="showConfirmDelete" :style="{width: '350px'}" :modal="true">
      <template #header>
        {{ $t('Confirm Delete {title}', {title: hrGroup.name}) }}
      </template>
      <div class="confirmation-content">
        {{
          $t('Do you really want to delete hr group {title}? All users will be moved to the fallback hr group', {title: hrGroup.name})
        }}
      </div>
      <template #footer>
        <FormActions>
          <template #left>
            <SecondaryButton @click="confirmDelete">{{ $t('Cancel') }}</SecondaryButton>
          </template>
          <DangerButton @click="deleteHrGroup">{{ $t('delete') }}</DangerButton>
        </FormActions>
      </template>
    </Dialog>
  </div>

</template>

<script>
import {required} from 'vuelidate/lib/validators';
import Dialog from 'primevue/dialog';
import PickerInput from "@/components/PickerInput";
import TreeList from "@/components/TreeList";
import CustomPickList from "@/components/CustomPickList";

export default {
  name: 'hrGroups',
  data() {
    return {
      hrGroup: {
        name: null,
        parent_id: null,
        managers: []
      },
      selectedParentHrGroup: null,
      showConfirmDelete: false,
      hrManagers: null
    }
  },
  created() {
    this.getHrGroup()
    this.getHrGroups()
  },
  methods: {
    getHrGroup() {
      if (this.$route.params.id) {
        this.getSettings()
        this.$http.get('hr-groups/' + this.$route.params.id)
            .then((res) => {
              console.log("RES", res)
              this.hrGroup = {...res.data}
              this.hrGroup.managers = res.data.managers
              if (this.hrGroup.parent_id) {
                this.$http.get('hr-groups/' + this.hrGroup.parent_id).then(res => {
                  this.selectedParentHrGroup = res.data.name
                })
              }
              this.getHrManagers()
            }).catch(() => {
          this.$router.push({name: 'backend.hr-groups.hr-groups'})
        })
      }
    },
    getSettings() {
      console.log("getting settings")
      this.$store.dispatch('getSettings');
    },
    getHrGroups() {
      this.$store.dispatch('getHrGroups');
    },
    getHrManagers() {
      this.$store.dispatch('getHrManagers')
          .then(() => {
            this.hrManagers = [this.$store.state.hrManagers.filter((user) => {
              return !this.hrGroup.managers.some((manager) => {
                return user.id === manager.id
              })
            }), this.hrGroup.managers]
          });
    },
    saveHrGroup() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$toast.add({
          severity: 'error',
          summary: this.$t('The form has errors'),
          detail: this.$t('Please check the form again'),
          life: 3000,
        });
      } else {
        if (this.$route.params.id) {
          this.$http.put('hr-groups/' + this.hrGroup.id, this.hrGroup)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('HR group updated successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getHrGroups')
              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in updating hr groups'),
                  detail: error.message,
                  life: 3000,
                });
              });
        } else {
          this.$http.post('hr-groups', this.hrGroup)
              .then((res) => {
                console.log(res)
                this.$toast.add({
                  severity: 'success',
                  summary: this.$t('HR group added successfully'),
                  life: 3000,
                });
                this.$store.dispatch('getHrGroups')
                this.$router.push({
                  name: 'backend.hr-groups.hr-group',
                  params: {id: res.data.id}
                });


              })
              .catch((error) => {
                this.$toast.add({
                  severity: 'error',
                  summary: this.$t('Error in adding hrGroup'),
                  detail: error.message,
                  life: 3000,
                });
              });
        }
      }
    },
    confirmDelete() {
      this.showConfirmDelete = !this.showConfirmDelete
    },
    deleteHrGroup() {
      this.$http.delete('hr-groups/' + this.hrGroup.id)
          .then(() => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'success',
              summary: this.$t('HR group deleted successfully'),
              life: 3000,
            });
            this.$router.push('/backend/hr-groups/hr-group')
          })
          .catch((error) => {
            this.showConfirmDelete = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t(error.data.message),
              life: 3000,
            });
          });
    },
    hrGroupSelected(hrGroup) {
      this.hrGroup.hr_group = hrGroup
      this.$refs["hr-group-picker"].closeDialog()
    },
    parentHrGroupSelected(hrGroup) {
      console.log("selected ", hrGroup)
      this.hrGroup.parent_id = hrGroup.id;
      this.selectedParentHrGroup = hrGroup.name;
      this.$refs.picker.closeDialog()
    },
    clearSelected() {
      this.selectedParentHrGroup = null
      this.hrGroup.parent_id = null
    },
    addManager(manager) {
      for (let item of manager.items) {
        this.$http.post('hr-group-manager', {
          'hrGroupId': this.hrGroup.id,
          'managerId': item.id
        })
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Manager successfully added'),
                life: 3000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
    },
    removeManager(manager) {
      console.log("remove")
      for (let item of manager.items) {
        this.$http.delete('hr-group-manager/' + this.hrGroup.id + '/' + item.id)
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: this.$t('Manager successfully removed'),
                life: 3000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: 'error',
                summary: this.$t(error.message),
                life: 3000,
              });
            });
      }
    },
  },
  components: {
    Dialog,
    PickerInput,
    TreeList,
    CustomPickList
  },
  validations: {
    hrGroup: {
      name: {
        required,
      },
    }
  },
  computed: {
    hrGroups() {
      return this.$store.state.hrGroups.filter((hrGroup) => {
        return this.hrGroup.id !== hrGroup.id
      })
    }
  }

}
</script>