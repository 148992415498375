import AppLayout from "../views/AppLayout";
import backendRoutes from "./backendRoutes";
import frontendRoutes from "./frontendRoutes";

export default [
    {
        path: '/',
        component: AppLayout,
        name: '',
        meta: {
            requiresAuth: true,
        },
        children: [
            ...frontendRoutes,
            ...backendRoutes
        ]
    },

]