<template>
  <PageLayout>
    <template #back-navigation><span></span></template>
    <template #content-header>
      {{ $t('Assessments') }}
    </template>
     <template #content-header-suffix>{{$t('completed')}}</template>
    <template #content>
      <div class="container table-container pt-0 pb-0">
        <div class="p-card-body" style="padding:0">
          <DataTable
               :value="assessments"
                  data-key="id"
                  :rowHover="true"
                  :filters.sync="filters"
                  :loading="loading"
                  v-if="!loading"
                  :expandedRows.sync="expandedRows"
                  :paginator="true"
                  :rows="10"
                  dataKey="id"
          >
            <template #empty>{{ $t('noAssessmentsFound') }}.</template>
            <template #header>
                  <div class="table-header d-flex align-items-center">
            <span class="p-input-icon-left flex-grow-1">
                 <IconInput
                  v-model="filters['global'].value" :placeholder="$t('Search')"
                  type="text"
                  icon="pi pi-search"
                  :clear="true"
              />
            </span>
                  </div>
                </template>
            <Column field="name" :header="$t('name')" sortable>
              <template #body="slotProps">

                <div @click="assesseeClicked(slotProps.data)" class="user-wrapper">
                  <UserImage :src="slotProps.data.assessee_image" class="user-img-list"></UserImage>
                  <div class="user-name">{{ slotProps.data.name }}</div>
                </div>


              </template>
            </Column>
            <Column field="assessor">
                  <template #header>
                    <div>
                      <span>{{ $t('assessor') }}</span>
                    </div>
                  </template>
                  <template #body="slotProps">
                    <div class="user-wrapper" @click="assesseeClicked(slotProps.data)">
                      <UserImage :src="slotProps.data.assessor_image" class="user-img-list"></UserImage>
                      <div class="user-name">{{ slotProps.data.assessor }}</div>
                    </div>
                  </template>
                </Column>
            <Column field="completed_date" :header="$t('completedAt')" headerStyle="width: 10em; text-align: center"
                    bodyStyle="text-align: center; overflow: visible" sortable>
              <template #body="slotProps">
                <div>{{ slotProps.data.completed_date }}</div>


              </template>
            </Column>
            <Column
                headerStyle="width: 10em; text-align: center"
                :header="$t('assessment')"
                bodyStyle="text-align: center; overflow: visible"
                sortable
            >
              <template #body="slotProps">
                <div>{{ slotProps.data.title }}</div>
              </template>
            </Column>
            <Column
                headerStyle="width: 10em; text-align: center"
                :header="$t('viewResult')"
                bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <div class="start-assessment-wrapper">
                  <button
                      class="start-assessment-button"
                      @click="startAssessmentClicked(slotProps.data.id, slotProps.data.assessee_id)"
                  ><i class="pi pi-chevron-right"></i>
                  </button>
                </div>

              </template>
            </Column>
            <template #expansion="slotProps">
              <div class="user-details my-2">
                <div>
                  <div class="row">
                    <div class="col-sm-6 pb-0 pt-0">
                      {{ $t('firstName') }}:
                      <b>{{ slotProps.data.first_name }}</b>
                    </div>
                    <div class="col-sm-6 pb-0 pt-0">
                      {{ $t('lastName') }}:
                      <b>{{ slotProps.data.last_name }}</b>
                    </div>
                    <div class="col-sm-6 pb-0 pt-0">
                      {{ $t('email') }}:
                      <b>{{ slotProps.data.email }}</b>
                    </div>
                    <div class="col-sm-6 pb-0 pt-0">
                      {{ $t('dateOfBirth') }}:
                      <b>{{ $moment(slotProps.data.birth_date).format('DD.MM.YYYY') }}</b>
                    </div>
                    <div class="col-sm-6 pb-0 pt-0">
                      {{ $t('employeeNumber') }}:
                      <b>{{ slotProps.data.employee_number }}</b>
                    </div>
                    <div class="col-sm-6 pb-0 pt-0" v-if="slotProps.data.job_position">
                      {{ $t('currentJob') }}:
                      <b>{{ slotProps.data.job_position.title }}</b>
                    </div>
                    <div class="col-sm-6 pb-0 pt-0" v-if="slotProps.data.future_job_position">
                      {{ $t('Future job position') }}:
                      <b>{{ slotProps.data.future_job_position.title }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </DataTable>
        </div>
      </div>

    </template>
    <template #content-footer>
      <div class="d-flex justify-center">
        <router-link v-if="!loading" to="assessment-list">{{ $t('toPlannedAssessments') }}</router-link>
      </div>

    </template>
  </PageLayout>
</template>

<style lang="scss">
.list-table {
  background: $darker
}
</style>

<script>

import UserImage from "@/components/UserImage";
import PageLayout from "../backend/PageLayout.vue";
import {FilterMatchMode} from "primevue/api";
import IconInput from "../../components/IconInput.vue";

export default {
  name: 'completed-assessment-list',
  data() {
    return {
      assessments: null,
      filters: {
        'global': {value: this.$route.params.searchString, matchMode: FilterMatchMode.CONTAINS}
      },
      expandedRows: [],
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.getMyAssessments();

  },
  mounted() {
    if (this.filters.global) {
      console.log("emmiting ", this.$route.params)
      this.$emit('input', this.filters.global);
    }
  },
  methods: {
    getMyAssessments() {
      this.$store
          .dispatch('getMyCompletedAssessments')
          .then(res => {
            this.assessments = res.data;
            this.filters.global.value = this.$route.params.searchString
            this.loading = false;
          })
          .catch(err => {
            this.$toast.add({
              severity: 'error',
              summary: 'Assessments nicht geladen',
              detail: err,
              life: 3000,
            });
          });
    },
    assesseeClicked(assessee) {
      if (!this.expandedRows.includes(assessee)) {
        this.expandedRows = [];
        this.expandedRows.push(assessee);
      } else {
        this.expandedRows = [];
      }
    },
    publicAssessmentClicked(assessment) {
      let lastStepId = assessment.competences.findIndex(competence => {
        return competence.pivot.updated_at == null
      })
      console.log("last Step ID ", assessment.competences)
      if (lastStepId >= 0) {
        this.$router.push({
          name: 'publicassessment-process',
          params: {assessmentId: assessment.id, stepId: lastStepId},
        });
      } else {
        this.$router.push({
          name: 'publicassessment-process',
          params: {assessmentId: assessment.id, stepId: 0},
        });
      }
    },
    startAssessmentClicked(assessmentId, assesseeId) {
      this.$router.push({
        name: 'assessment-summary',
        params: {assessmentId, assesseeId},
      });
    },
  },
  components: {
    IconInput,
    PageLayout,
    UserImage,

  },
};
</script>
