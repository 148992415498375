<template>
  <div class="mx-auto">
    <h1 class="logo text-white text-center text-uppercase font-weight-bold align-middle page-title-home dashboard-title">
      Talent<span>eers</span> Backend
    </h1>
    <div class="container">
      <div class="col-sm-12 text-white text-center">
        <h1 class="dashboard-title">Reporting</h1>
      </div>
      <div class="col-sm-12 text-white text-center" v-if="exporting">
        {{ $t('Creating report') }}
        <div class="text-center">
          <ProgressSpinner class="progress-indicator" strokeWidth="4"/>
        </div>
      </div>
      <div class="row" v-else>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('General report')" @click="getGeneralReport('')"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('Target profile report')"
                       @click="getGeneralReport('job_role_competences')"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('Actual profile report ability')" @click="getGeneralReport('user_competences_ability')"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('Actual profile report claim')" @click="getGeneralReport('user_competences_claim')"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('Association job positions job roles')" @click="getGeneralReport('job_positions_job_roles')"></DashboardLink>
        <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('Association job positions users')" @click="getGeneralReport('user_job_positions')"></DashboardLink>
         <DashboardLink v-if="this.$helper.hasAnyRole([this.$roles.ADMIN, this.$roles.HRADMIN, this.$roles.HRMANAGER])"
                       to="/backend/reporting"
                       :title="$t('Job position competences report')" @click="getGeneralReport('job_position_competences')"></DashboardLink>

      </div>
    </div>
  </div>
</template>
<style lang="scss">
.dashboard-title {
  font-size: 1.5rem;
}
</style>
<script>
import DashboardLink from '@/components/DasboardLink';
import axios from 'axios';
import Vue from 'vue'

export default {
  name: 'dashboard',
  created() {
    this.$store.dispatch('getUser', {})
  },
  components: {
    DashboardLink,
  },
  data() {
    return {
      exporting: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    getGeneralReport(report) {
      Vue.prototype.$toast.add({
        severity: 'info',
        summary: this.$t('Creating report'),
        detail: '',
      });
      this.exporting = true
      axios({
        url: 'reporting/' + report,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        let filename = response.headers['content-disposition'].split(';')[1].split('=')[1].replaceAll("\"", "")
        const url = window.URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }));
        const link = document.createElement('a');
        link.href = url;
        console.log(filename)
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        Vue.prototype.$toast.removeAllGroups();
        this.exporting = false
        Vue.prototype.$toast.add({
          severity: 'success',
          summary: this.$t('Successfully created report'),
          detail: '',
          life: 3000,
        });
      }).catch(() => {
        this.exporting = false
        Vue.prototype.$toast.removeAllGroups();
        Vue.prototype.$toast.add({
          severity: 'error',
          summary: this.$t('Error in creating report'),
          detail: '',
          life: 3000,
        });
      });
    }
  }
};
</script>
