import Dashboard from "@/views/backend/Dashboard";
import CompetencesLayout from "@/views/backend/CompetencesLayout";
import Competences from "@/views/backend/Competences";
import Categories from "@/views/backend/Categories";
import JobRoles from "@/views/backend/JobRoles";
import JobRolesCompetence from "@/views/backend/JobRolesCompetence";
import TagsLayout from "@/views/backend/TagsLayout";
import Tags from "@/views/backend/Tags";
import JobPositionsLayout from "@/views/backend/JobPositionsLayout";
import JobPositions from "@/views/backend/JobPositions";
import FunctionalGroups from "@/views/backend/FunctionalGroups";
import Assessments from "@/views/backend/Assessments";
import Profile from "@/views/frontend/Profile";
import Users from "@/views/backend/Users";
import HrGroups from "@/views/backend/HrGroups";
import HrGroupsLayout from "@/views/backend/HrGroupsLayout";
import AssessmentsEditList from "@/views/backend/AssessmentsEditList";


import i18n from '../i18n';
import CompetencesInfo from "@/views/backend/CompetencesInfo";
import ReportingDashboard from "@/views/backend/Reporting/ReportingDashboard";
import JobRoleGroupsLayout from "@/views/backend/JobRoleGroupsLayout";
import JobRoleGroups from "@/views/backend/JobRoleGroups";

export default [
    {
        path: '/administration',
        name: 'administration.home',
        component: Dashboard,
    },
    {
        path: '/backend/competences',
        name: 'backend.competences',
        component: CompetencesLayout,
        children: [
            {
                path: 'competence/info',
                name: 'backend.competences.competence.info',
                meta: {
                    title: i18n.t('competences info'),
                },
                component: CompetencesInfo,
            },
            {
                path: 'competence/:id?',
                name: 'backend.competences.competence',
                meta: {
                    title: i18n.t('Competences'),
                },
                component: Competences,
            },
            {
                path: 'category/:id?',
                name: 'backend.competences.category',
                meta: {
                    title: 'Kategorie verwalten',
                },
                component: Categories,
            }
        ]
    },
    {
        path: '/backend/job-role-groups',
        name: 'backend.job-role-groups',
        component: JobRoleGroupsLayout,
        children: [
            {
                path: 'job-role-group/:id?',
                name: 'backend.job-role-groups.job-role-group',
                meta: {
                    title: i18n.t('Edit job role groups'),
                },
                component: JobRoleGroups,
            },
            {
                path: 'job-role/:id?',
                name: 'backend.job-role-groups.job-role',
                meta: {
                    title: i18n.t('Edit job roles'),
                },
                component: JobRoles,
            },
            {
                path: '/backend/job-role-groups/job-role/competence/:id',
                name: 'backend.job-role-groups.job-role.competence',
                component: JobRolesCompetence,
            },
        ]
    },
    {
        path: '/backend/tags',
        name: 'backend.tags',
        component: TagsLayout,
        children: [
            {
                path: 'tag/:id?',
                name: 'backend.tags.tag',
                meta: {
                    title: i18n.t('Edit tags'),
                },
                component: Tags,
            },
        ]
    },
    {
        path: '/backend/job-positions',
        name: 'backend.job-positions',
        component: JobPositionsLayout,
        children: [
            {
                path: 'job-position/:id?',
                name: 'backend.job-positions.job-position',
                meta: {
                    title: i18n.t('Edit job positions'),
                },
                component: JobPositions,
            },
        ]
    },
    {
        path: '/backend/functional-groups',
        name: 'backend.functional-groups',
        component: JobPositionsLayout,
        children: [
            {
                path: 'functional-group/:id?',
                name: 'backend.functional-groups.functional-group',
                meta: {
                    title: i18n.t('Edit functional groups'),
                },
                component: FunctionalGroups,
            },
        ]
    },
    {
        path: '/backend/hr-groups',
        name: 'backend.hr-groups',
        component: HrGroupsLayout,
        children: [
            {
                path: 'hr-group/:id?',
                name: 'backend.hr-groups.hr-group',
                meta: {
                    title: i18n.t('Edit hr groups'),
                },
                component: HrGroups,
            },
            {
                path: 'user/:id?',
                name: 'backend.hr-groups.user',
                meta: {
                    title: i18n.t('Edit users'),
                },
                component: Users,
            },
        ]
    },
    {
        path: '/backend/assessments',

                name: 'backend.assessments.list',
                meta: {
                    title: i18n.t('Assessments'),
                },
                component: AssessmentsEditList,
    },
    {
        path: 'backend/assessments/assessment/:id?',
        name: 'backend.assessments.assessment',
        meta: {
            title: i18n.t('Edit assessments'),
        },
        component: Assessments,
    },
    {
        path: '/backend/reporting',
        name: 'backend.reporting',
        component: ReportingDashboard,
        meta: {
            title: i18n.t('Reporting'),
        },
    },
    {
        path: '/backend/profile',
        name: 'backend.profile',
        component: Profile,
        meta: {
            title: i18n.t('Profile'),
        },
    },
]